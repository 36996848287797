import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, notification, Icon } from 'antd';
import { t } from 'utils/i18n';
import { onChangeInput, onBlurInput, beforSubmit } from 'utils/Logics';
import { updateTeam } from 'apis/teams';

const onOpenModal = setVisible => () => setVisible(true)

export default (props) => {
  const [visible, setVisible] = useState(false)
  const [name, setName] = useState(props.team.name);
  const [message, setMessage] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    if (visible) {
      setName(props.team.name)
    }
  }, [visible])

  const onOk = async () => {
    const body = beforSubmit({
      name: {
        value: name,
        setMessage,
        title: "Team name"
      }
    })
    if (!body) {
      return
    }
    setConfirmLoading(true)
    const response = await updateTeam(body, props.team.id)
    setConfirmLoading(false)
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      if (props.onReload) {
        props.onReload()
      }
      setName("")
      setVisible(false)
    }
    else {
      notification.error({
        message: t("Error"),
        description: response.data.message
      })
    }
  }

  const onCancel = () => {
    setName("")
    setConfirmLoading(false)
    setMessage("")
    setVisible(false)
  }

  return (
    <Fragment>
      <Button
        type="primary"
        onClick={onOpenModal(setVisible)}
        style={{ marginLeft: 10 }}
      >
        <Icon type="edit" />
      </Button>
      <Modal
        cancelText={t("Cancel")}
        title={t("Update team")}
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        confirmLoading={confirmLoading}
      >
        <Form>
          <Form.Item required label={t("Team name")} help={message}>
            <Input
              value={name}
              onChange={onChangeInput(setName, setMessage)}
              onBlur={onBlurInput(name, setMessage, "Team name")}
              maxLength={100}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}