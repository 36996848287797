import { get, post, destroy, patch } from "utils/FetchAPI";

export const getListDatas = (query) => get("/datas", { ...query, team_id: localStorage.getItem("currentTeamId") })
export const getListHomeDatas = (query) => get("/home", {
  ...query,
  team_id: localStorage.getItem("currentTeamId") || query.teamId
})
export const getDataDetail = (query) => get("/datas/view", { ...query, team_id: localStorage.getItem("currentTeamId") })
export const getDataCorrelationTab = (data) => post("/datas/view_corr", {
  ...data,
  team_id: localStorage.getItem("currentTeamId")
})
export const getDataColumn = (data) => post("/datas/select_columns", {
  ...data,
  team_id: localStorage.getItem("currentTeamId")
})
export const splitData = (data) => post("/datas/split", { ...data, team_id: localStorage.getItem("currentTeamId") })
export const calculationData = (data) => post("/datas/calculate", {
  ...data,
  team_id: localStorage.getItem("currentTeamId")
})

export const uploadDatas = (data) => {
  // console.log("MERGEEEEEEE", data.get('file'))

  if (!data.get("team_id")) {
    data.append('team_id', localStorage.getItem("currentTeamId"))
  }
  return post('/datas', data, { 'Content-type': "multipart/form-data" })
}

export const searchDatas = (query) => get('/datas/search', { ...query, team_id: localStorage.getItem("currentTeamId") })

export const viewData = (query) => get("/datas/csv", { ...query, team_id: localStorage.getItem("currentTeamId") })

export const deleteData = (data) => {
  return destroy("/datas", { ...data, team_id: localStorage.getItem("currentTeamId") })
}

export const updateData = (data) => patch('/datas', { ...data, team_id: localStorage.getItem("currentTeamId") })

export const weather = (data) => post("/datas/weather", { ...data, team_id: localStorage.getItem("currentTeamId") })
export const weatherForeCast = (data) => post("/datas/weather_forecast", { ...data, team_id: localStorage.getItem("currentTeamId") })
// export const merge = (data) => post("/datas/merge", { ...data, team_id: localStorage.getItem("currentTeamId") })
export const merge = (formData) => {
  // console.log("MERGEEEEEEE", formData.get('file'))
  if (!formData.get("team_id")) {
    formData.append('team_id', localStorage.getItem("currentTeamId"))
  }
  return post("/datas/merge", formData, { 'Content-type': "multipart/form-data" })
}

export const timeSeries = (data) => post("/datas/time_series", {
  ...data,
  team_id: localStorage.getItem("currentTeamId")
})

export const updateDataFile = (form) => {
  const formData = new FormData();
  Object.keys(form).forEach(key => {
    if (key === "file") {
      formData.set(key, form[key], "update.csv");
    }
    else {
      formData.set(key, form[key]);
    }
  });
  formData.set("team_id", localStorage.getItem("currentTeamId"))
  return post("/datas/update_content", formData);
}

export const getDataInfo = (data_id) => get(`/datas/${data_id}`, { team_id: localStorage.getItem("currentTeamId") })