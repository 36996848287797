import React, {} from 'react';
import {Form, Input, Select, Row, Col} from 'antd';
import {t} from 'utils/i18n';
import {onChangeInput, onBlurInput} from 'utils/Logics';
import {debound} from 'utils';
import {LABELS} from 'consts';

const {Option} = Select;
const ClusteringTab = (props) => {
  
  
  return (
    <>
      <Form>
        <Form.Item label={t("Project")} required>
          <Select
            value={props.project}
            onChange={(value) => props.setProject(value)}
            className="fullsize_selectbox"
            showSearch
            placeholder={t("Search project by name...")}
            onSearch={debound(props._onSearchProject, 500)}
            defaultActiveFirstOption={false}
            filterOption={false}
            notFoundContent={null}
            onPopupScroll={props.handleScroll}
            loading={props.loading}
          >
            {
              props.projects.map(p => (
                <Select.Option value={p.project_id} key={p.project_id}>{`${p.name}`}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item label={t("Model Name")} required help={props.message.name}>
          <Input
            value={props.models.name}
            onChange={onChangeInput(props._setFieldModels("name"), props._setMessage("name"))}
            onBlur={onBlurInput(props.models.name, props._setMessage("name"), "Model name")}
            maxLength={100}
          />
        </Form.Item>
        <Form.Item label={t("Report Name")} required help={props.message.ReportName}>
          <Input
            value={props.models.report_name}
            onChange={onChangeInput(props._setFieldModels("report_name"), props._setMessage("ReportName"))}
            onBlur={onBlurInput(props.models.report_name, props._setMessage("ReportName"), "Report Name")}
            maxLength={100}
          />
        </Form.Item>
      </Form>
      <strong>{t("Training data")}</strong>
      <Row gutter={[8, 0]}>
        <Col span={6}>
          <Form>
            <Form.Item label={t("Label")}>
              <Select
                value={props.label}
                onChange={(value) => props.setLabel(value)}
                className="fullsize_selectbox"
              >
                {
                  Object.values(LABELS).map(label => (
                    <Select.Option value={label} key={label}>{`${t(label)}`}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Form>
        </Col>
        <Col span={18}>
          <Form>
            <Form.Item label={t("Data name")} required help={props.message.data_ids}>
              <Select
                // mode="multiple"
                placeholder={t("Search data by name...")}
                showSearch
                value={props.models.data_ids && props.models.data_ids[0]}
                onSearch={debound(props._onSearch, 500)}
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={null}
                onChange={props._onChangeDataName}
              >
                {
                  props.datas.map(d => (
                    <Select.Option value={d.data_id} key={d.data_id}>{`${d.name}`}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        <Form>
          <Form.Item label={t("The Number Cluster")} required help={props.message.data_ids}>
            <Select style={{width: "100px"}} onChange={(value) => props.setCluster(value)} defaultValue={0}>
              <Option key={0} value={0}>{t('Auto')}</Option>
              <Option key={1} value={1}>1</Option>
              <Option key={2} value={2}>2</Option>
              <Option key={3} value={3}>3</Option>
              <Option key={4} value={4}>4</Option>
              <Option key={5} value={5}>5</Option>
              <Option key={6} value={6}>6</Option>
              <Option key={7} value={7}>7</Option>
              <Option key={9} value={8}>8</Option>
              <Option key={9} value={9}>9</Option>
            </Select>
          </Form.Item>
        </Form>
      </Row>
    </>
  )
}

export default ClusteringTab