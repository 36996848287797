import React, {useEffect, useState, Fragment, useContext} from 'react';
import {
  Checkbox,
  Table,
  InputNumber,
  Tag,
  Spin,
  Select,
  Button,
  notification,
  Icon,
  Row,
  Col,
  Pagination,
  Modal,
  Tooltip
} from 'antd';
import {checkColor, convertDataViewNumber, convertDataViewString} from 'utils';
// import moment from 'moment';
// import TitleTable from 'components/TitleTable';
// import SelectLabel from 'components/SelectLabel';
// import {getListDatas, deleteData, updateData} from 'apis/datas';
// import {ROUTES, LABELS} from 'consts';
// import {accessDownload} from 'apis/auth';
// import Configs from 'components/ConfigProviders';
// import {useHistory} from 'react-router-dom';
// import SpanEditable from 'components/SpanEditable';
import {getDataDetail, getDataCorrelationTab} from 'apis/datas';

import Plot from 'react-plotly.js';
import {t} from "utils/i18n"
import {createProject} from "apis/projects";
import TableCorrelation from "./TableCorrelation";
// const {Column, ColumnGroup} = Table;

/**
 *
 * @param {Object} props
 * @param {String} props.label
 */
const CorrelationTab = (props) => {
  const [listColumn, setListColumn] = useState([]);
  const [listColumnChecked, setListColumnChecked] = useState([]);
  const [dataNumber, setDataNumber] = useState();
  const [isDisabled, setIsDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(100);
  
  useEffect(() => {
    if (props.dataTabView.Number && handleListColumn(props.dataTabView.Number).length > 0) {
      handleDraw(handleListColumn(props.dataTabView.Number));
      setListColumn(handleListColumn(props.dataTabView.Number))
    }
  }, [props.dataTabView])
  
  let handleListColumn = (data) => {
    let column = data.map((value, index) => {
      return {
        value: value.column,
        checked: true
      };
    })
    return column;
  }
  let onChangeTickCheckBox = (e) => {
    let newListColumn = listColumn.map((data, index) => {
      if (e.target.value.value === data.value) {
        return {
          value: data.value,
          checked: e.target.checked
        }
      } else return data
    })
    setListColumn(newListColumn)
    setWidth(newListColumn.length * 160)
  }
  
  let handleDraw = async (listColumnState) => {
    let listColumnChecked = listColumnState.map((data, index) => {
      if (data.checked) {
        return data.value
      }
    })
    let body = {
      data_id: props.fileId,
      columns: listColumnChecked.filter(function (el) {
        return el != null;
      })
    }
    setLoading(true);
    const response = await getDataCorrelationTab(body);
    if (response.status === 200) {
      setDataNumber(response.data)
      setLoading(false)
    }
    
    
  }
  let handleSelectAll = async () => {
    let newListColumn = listColumn.map((data, index) => {
      data.checked = true
      return data
    })
    setListColumn(newListColumn)
  }
  let handleClearAll = async () => {
    let newListColumn = listColumn.map((data, index) => {
      data.checked = false
      return data
    })
    setListColumn(newListColumn)
  }
  
  
  return (
    <div className="correlation_tab">
      {/*{listColumn && listColumn.includes("target") ?*/}
      {/*  <div className="checkbox"><span>Target:</span><Checkbox defaultChecked={true} value="target"/>*/}
      {/*    <span style={{marginRight:'30px'}}><Checkbox checked={data.checked}*/}
      {/*                                                 onChange={onChangeTickCheckBox}*/}
      {/*                                                 value={data}*/}
      {/*                                                 style={{marginLeft: '5px'}}>Target</Checkbox></span>*/}
      {/*  </div> : ''}*/}
      
      <Row>
        <Col>
          <Row className="checkbox">
            
            {listColumn.map((data, index) => {
              return <span style={{marginRight: '30px'}}><Checkbox checked={data.checked}
                                                                   onChange={onChangeTickCheckBox}
                                                                   value={data}
                                                                   style={{marginLeft: '5px'}}>{data.value}</Checkbox></span>
            })}
          </Row>
        
        </Col>
        <div>
          <Button onClick={handleSelectAll} style={{paddingLeft: 0, marginTop: '23px', width: '80px'}} type="link">Select
            All</Button> |
          <Button style={{marginTop: '23px', width: '70px'}} type="link" onClick={handleClearAll}>Clear all</Button>
        </div>
        <div style={{textAlign: 'right'}}><Button style={{marginLeft: '62%', marginTop: '23px', width: '130px'}}
                                                  type="primary"
                                                  onClick={() => handleDraw(listColumn)}
                                                  disabled={isDisabled}>Draw</Button></div>
        <Row style={{marginTop: '20px'}}>
          <Col span={12}>
            <Row style={{fontSize: '18px', fontWeight: 'bold'}}>{t('Positive')}</Row>
            <Row type="flex" justify="space-between" style={{width: '70%'}}>
              <div style={{width: '130px'}}>
                <Row type="flex" justify={'space-between'}>
                  <Col>
                    <div style={{fontSize: '17px'}}>{t('Strong')}</div>
                    <div style={{fontSize: '13px'}}>0.7 ~ 1</div>
                  
                  </Col>
                  <Col>
                    <div style={{
                      display: 'inline-block',
                      height: '40px',
                      backgroundColor: 'rgb(51, 204, 51)',
                      width: '40px'
                    }}/>
                  </Col>
                </Row>
              </div>
              <div style={{width: '130px'}}>
                <Row type="flex" justify={'space-between'}>
                  <Col>
                    <div style={{fontSize: '17px'}}>{t('Medium')}</div>
                    <div style={{fontSize: '13px'}}>0.4 ~ 0.7</div>
                  
                  </Col>
                  <Col>
                    <div style={{
                      display: 'inline-block',
                      height: '40px',
                      backgroundColor: 'rgb(152, 230, 152)',
                      width: '40px'
                    }}/>
                  </Col>
                </Row>
              </div>
              <div style={{width: '130px'}}>
                <Row type="flex" justify={'space-between'}>
                  <Col>
                    <div style={{fontSize: '17px'}}>{t('Weak')}</div>
                    <div style={{fontSize: '13px'}}>0.2 ~ 0.4</div>
                  </Col>
                  <Col>
                    <div style={{
                      display: 'inline-block',
                      height: '40px',
                      backgroundColor: 'rgb(214, 245, 214)',
                      width: '40px'
                    }}/>
                  </Col>
                </Row>
              </div>
            
            </Row>
          
          </Col>
          
          <Col span={12}>
            <Row style={{fontSize: '18px', fontWeight: 'bold'}}>{t('Negative')}</Row>
            <Row type="flex" justify="space-between" style={{width: '70%'}}>
              <div style={{width: '130px'}}>
                <Row type="flex" justify={'space-between'}>
                  <Col>
                    <div style={{fontSize: '17px'}}>{t('Strong')}</div>
                    <div style={{fontSize: '13px'}}>-1 ~ -0.7</div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: 'inline-block',
                        height: '40px',
                        backgroundColor: 'rgb(255, 153, 51)',
                        width: '40px'
                      }}/>
                  </Col>
                </Row>
              </div>
              <div style={{width: '130px'}}>
                <Row type="flex" justify={'space-between'}>
                  <Col>
                    <div style={{fontSize: '17px'}}>{t('Medium')}</div>
                    <div style={{fontSize: '13px'}}>-0.7 ~ -0.4</div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: 'inline-block',
                        height: '40px',
                        backgroundColor: 'rgb(255, 191, 128)',
                        width: '40px'
                      }}/>
                  </Col>
                </Row>
              </div>
              <div style={{width: '130px'}}>
                <Row type="flex" justify={'space-between'}>
                  <Col>
                    <div style={{fontSize: '17px'}}>{t('Weak')}</div>
                    <div style={{fontSize: '13px'}}>-0.4 ~ -0.2</div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: 'inline-block',
                        height: '40px',
                        backgroundColor: 'rgb(255, 230, 204)',
                        width: '40px'
                      }}/>
                  </Col>
                </Row>
              </div>
            
            </Row>
          
          </Col>
        </Row>
      
      </Row>
      
      <Spin spinning={loading}>
        
        {dataNumber ? <TableCorrelation dataTable={dataNumber}/> : <div style={{height: '400px'}}/>}
        
      </Spin>
    
    </div>
  )
}

export default CorrelationTab