import React, {Fragment, useState} from 'react'
import {Button, Modal, Form, Input, Select, notification, Tabs, DatePicker} from 'antd'
import {t} from 'utils/i18n'
import {onBlurInput, onChangeInput, beforSubmit} from 'utils/Logics'
import {debound} from 'utils'
import {searchUsers} from 'apis/users'
import {createTeam, updateTeam} from 'apis/teams'
import {getInformation, createInformation, updateInformation, deleteInformation} from 'apis/informations'
import Register from '../Register'

const {TextArea} = Input;
const ModalCreateInformation = (props) => {
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState('');
  const [jpDescription, setJpDescription] = useState('');
  const [jpLink, setJpLink] = useState('');
  const [enLink, setEnLink] = useState('');
  const [enDescription, setEnDescription] = useState('');
  const [dateError, setDateError] = useState('');
  const [jpDescriptionError, setJpDescriptionError] = useState('');
  const [jpLinkError, setJpLinkError] = useState('');
  const [enLinkError, setEnLinkError] = useState('');
  const [enDescriptionError, setEnDescriptionError] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const _onOpenModal = () => setVisible(true);
  const _onHideModal = () => {
    setVisible(false)
    setDate('')
    setEnDescription('')
    setEnLink('')
    setJpDescription('')
    setJpLink('')
  };
  const checkRequireField = (value, setValueError) => {
    if (!value) {
      setValueError('This field is require!')
    } else {
      setValueError('')
    }
  }
  const onChangeDate = (date, dateString) => {
    setDate(date)
    checkRequireField(date, setDateError)
  }
  const onChangeEnDescription = (e) => {
    setEnDescription(e.target.value)
    checkRequireField(e.target.value, setEnDescriptionError)
  }
  const onChangeEnLink = (e) => {
    setEnLink(e.target.value)
  }
  const onChangeJpDescription = (e) => {
    setJpDescription(e.target.value)
    checkRequireField(e.target.value, setJpDescriptionError)
  }
  const onChangeJpLink = (e) => {
    setJpLink(e.target.value)
  }
  
  
  const _onOk = async () => {
    setConfirmLoading(true)
    const body = {
      date,
      jpDescription,
      jpLink,
      enLink,
      enDescription,
    }
    
    checkRequireField(date, setDateError)
    checkRequireField(enDescription, setEnDescriptionError)
    checkRequireField(jpDescription, setJpDescriptionError)
    const response = await createInformation(body)
    setConfirmLoading(false)
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      if (props.onReload) {
        props.onReload()
      }
      setVisible(false)
    } else {
      notification.error({
        message: t("Error"),
        description: response.data.message
      })
    }
  }
  
  return (
    <Fragment>
      <Button type="primary" onClick={_onOpenModal}>
        {t("Create information")}
      </Button>
      <Modal
        confirmLoading={confirmLoading}
        cancelText={t("Cancel")}
        visible={visible}
        onCancel={_onHideModal}
        onOk={_onOk}
        title={t("Create Information")}
      >
        <Form>
          <Form.Item label={<strong>{t("Date")}</strong>} required help={dateError}>
            <DatePicker value={date} onChange={onChangeDate}/>
          </Form.Item>
          <Form.Item label={<strong>{t("Japanese")}</strong>} required help={jpDescriptionError}>
            <TextArea value={jpDescription} onChange={onChangeJpDescription} rows={4}
                      autoSize={{minRows: 3, maxRows: 6}}/>
          </Form.Item>
          <Form.Item label={<strong>{t("Link")}</strong>} help={jpLinkError}>
            <Input value={jpLink} onChange={onChangeJpLink}/>
          </Form.Item>
          
          <Form.Item label={<strong>{t("English")}</strong>} required help={enDescriptionError}>
            <TextArea value={enDescription} onChange={onChangeEnDescription} rows={4}
                      autoSize={{minRows: 3, maxRows: 6}}/>
          </Form.Item>
          
          <Form.Item label={<strong>{t("Link")}</strong>} help={enLinkError}>
            <Input onChange={onChangeEnLink} value={enLink}/>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}

export default ModalCreateInformation