import React, {useEffect, useState, Fragment, useContext} from 'react';
import {Table, InputNumber, Tag, Select, Button, notification, Icon, Row, Pagination, Modal, Tooltip, Spin} from 'antd';
// import {t} from 'utils/i18n';
// import moment from 'moment';
// import TitleTable from 'components/TitleTable';
// import SelectLabel from 'components/SelectLabel';
// import {getListDatas, deleteData, updateData} from 'apis/datas';
// import {ROUTES, LABELS} from 'consts';
// import {accessDownload} from 'apis/auth';
// import Configs from 'components/ConfigProviders';
// import {useHistory} from 'react-router-dom';
// import SpanEditable from 'components/SpanEditable';
import Plot from 'react-plotly.js';
import {useLocation} from 'react-router-dom'
import {getDataDetail} from "apis/datas";
import {t} from "utils/i18n";
import {convertDataStatistic, convertDataMissingValue} from "utils";
// const {Column, ColumnGroup} = Table;

/**
 *
 * @param {Object} props
 * @param {String} props.label
 */
const StatisticsTabDetail = (props) => {
  const columnsNumber = [
    {
      title: "",
      dataIndex: "column",
      key: "column",
      render: text => {
        if (text) {
          // return <a>{text}</a>
          return text
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("Count"),
      dataIndex: "countlv1",
      key: "countlv1",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      },
    },
    {
      title: t("Missing Value"),
      children: [
        {
          title: t("Count"),
          dataIndex: "countlv2",
          key: "countlv2",
          render: text => {
            return <span>{text}</span>
          }
          
        }, {
          title: t("MissingRate"), dataIndex: "ratelv2", key: "ratelv2",
          render: text => {
            return <span>{text}</span>
          }
        }]
    },
    {
      title: t("Unique Count"),
      dataIndex: "unique",
      key: "unique",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("Mean"),
      dataIndex: "mean",
      key: "mean",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("Median"),
      dataIndex: "median",
      key: "median",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("Mode"),
      dataIndex: "mode",
      key: "mode",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("SD"),
      dataIndex: "sd",
      key: "sd",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: "-2D",
      dataIndex: "minus2d",
      key: "minus2d",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: "+2D",
      dataIndex: "plus2d",
      key: "plus2d",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: "25%",
      dataIndex: "25",
      key: "25",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: "50%",
      dataIndex: "50",
      key: "50",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: "75%",
      dataIndex: "75",
      key: "75",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("MAX"),
      dataIndex: "max",
      key: "max",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("MIN"),
      dataIndex: "min",
      key: "min",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    }
  
  ];
  const columnsNumberMissingValue = [
    {
      title: "",
      dataIndex: "column",
      key: "column",
      // render: text => <a>{text}</a>,
    },
    {
      title: t("Count"),
      dataIndex: "countlv1",
      key: "countlv1",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("Missing Value"),
      children: [
        {
          title: t("Count"),
          dataIndex: "countlv2",
          key: "countlv2",
          render: text => {
            return <span>{text}</span>
          }
          
        }, {
          title: t("MissingRate"), dataIndex: "rate", key: "rate",
          render: text => {
            return <span>{text}</span>
          }
        }]
    },
    {
      title: t('"0" count'),
      dataIndex: "count0",
      key: "count0",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t('"1" count'),
      dataIndex: "count1",
      key: "count1",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("Minus Number count"),
      dataIndex: "minuscount",
      key: "minuscount",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("999.. check"),
      dataIndex: "check999",
      key: "check999",
      render: text => {
        if (text) {
          return <span>{text}</span>
        } else return <span style={{fontSize: 22}}>-</span>
      }
    },
    {
      title: t("1st Rank"),
      children: [
        {
          title: t("Value"),
          dataIndex: "rank1value",
          key: "rank1value",
          render: text => {
            return <span>{text}</span>
          }
          
        }, {
          title: t("Count"), dataIndex: "rank1count", key: "rank1count",
          render: text => {
            return <span>{text}</span>
          }
        }]
    },
    {
      title: t("2nd Rank"),
      children: [
        {
          title: t("Value"),
          dataIndex: "rank2value",
          key: "rank2value",
          render: text => {
            return <span>{text}</span>
          }
          
        }, {
          title: t("Count"), dataIndex: "rank2count", key: "rank2count",
          render: text => {
            return <span>{text}</span>
          }
        }]
      
    },
    {
      title: t("3rd Rank"),
      children: [
        {
          title: t("Value"),
          dataIndex: "rank3value",
          key: "rank3value",
          render: text => {
            return <span>{text}</span>
          }
          
        }, {
          title: t("Count"),
          dataIndex: "rank3count",
          key: "rank3count",
          render: text => {
            return <span>{text}</span>
          }
        }]
      
    },
    {
      title: t("4th Rank"),
      children: [
        {
          title: t(t("Value")),
          dataIndex: "rank4value",
          key: "rank4value",
          render: text => {
            return <span>{text}</span>
          }
          
        }, {
          title: t("Count"), dataIndex: "rank4count", key: "rank4count",
          render: text => {
            return <span>{text}</span>
          }
        }]
      
    },
    {
      title: t("5th Rank"),
      children: [
        {
          title: t(t("Value")),
          dataIndex: "rank5value",
          key: "rank5value",
          render: text => {
            return <span>{text}</span>
          }
          
        }, {
          title: t("Count"), dataIndex: "rank5count", key: "rank5count",
          render: text => {
            return <span>{text}</span>
          }
        }]
      
    },
  ];
  // const columnsStringDetail = [
  //   {
  //     title: "",
  //     dataIndex: "column",
  //     key: "column",
  //     render: text => <a onClick={() => onClickTitleData(text)}>{text}</a>,
  //   },
  //   {
  //     title: t("Count"),
  //     dataIndex: "countlv1",
  //     key: "countlv1"
  //   },
  //   {
  //     title: t("MissingValue"),
  //     children: [
  //       {
  //         title: t("Count"),
  //         dataIndex: "countlv2",
  //         key: "countlv2",
  //
  //       }, {
  //         title: t("MissingRate"), dataIndex: "rate", key: "rate"
  //       }]
  //   },
  //   {
  //     title: t("Histogram"),
  //     dataIndex: 'histogram',
  //     render: data => <Plot
  //       zoomEnabled={false}
  //       data={data.data}
  //       layout={Object.assign({}, data.layout, {
  //         yaxis: { fixedrange: true },
  //         xaxis: { fixedrange: true },
  //         showlegend: false,
  //         paper_bgcolor: 'rgba(0,0,0,0)',
  //         plot_bgcolor: 'rgba(0,0,0,0)',
  //         width: 150, height: 90, margin: {
  //           l: 0,
  //           r: 20,
  //           b: 0,
  //           t: 0
  //         }
  //       })}
  //     />
  //   },
  //   {
  //     title: t('Distinct Count'),
  //     dataIndex: "unique",
  //     key: "unique",
  //   },
  //   {
  //     title: `1st ${t('Ranks')}`,
  //     children: [
  //       {
  //         title: t(t("Value")),
  //         dataIndex: "rank1value",
  //         key: "rank1value",
  //
  //       }, {
  //         title: t("Count"), dataIndex: "rank1count", key: "rank1count"
  //       }]
  //   },
  //   {
  //     title: `2nd ${t('Ranks')}`,
  //     children: [
  //       {
  //         title: t("Value"),
  //         dataIndex: "rank2value",
  //         key: "rank2value",
  //
  //       }, {
  //         title: t("Count"), dataIndex: "rank2count", key: "rank2count"
  //       }]
  //
  //   },
  //   {
  //     title: `3rd ${t('Ranks')}`,
  //     children: [
  //       {
  //         title: t(t("Value")),
  //         dataIndex: "rank3value",
  //         key: "rank3value",
  //
  //       }, {
  //         title: t("Count"), dataIndex: "rank3count", key: "rank3count"
  //       }]
  //   },
  //   {
  //     title: `4th ${t('Ranks')}`,
  //     children: [
  //       {
  //         title: t(t("Value")),
  //         dataIndex: "rank4value",
  //         key: "rank4value",
  //
  //       }, {
  //         title: t("Count"), dataIndex: "rank4count", key: "rank4count"
  //       }]
  //
  //   },
  //   {
  //     title: `5th ${t('Ranks')}`,
  //     children: [
  //       {
  //         title: t(t("Value")),
  //         dataIndex: "rank5value",
  //         key: "rank5value",
  //
  //       }, {
  //         title: t("Count"), dataIndex: "rank5count", key: "rank5count"
  //       }]
  //   }
  // ];
  
  const {state: {data}} = useLocation()
  const [bins, setBins] = useState(10)
  const [dataStatistic, setDataStatistic] = useState({})
  const [dataNumberMissingValue, setDataNumberMissingValue] = useState({})
  const [dataString, setDataString] = useState({})
  const [dataHistogram, setDataHistogram] = useState({})
  const [isHideTable, setHideTable] = useState(false)
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    _getDataDetail({
      data_id: data.data_id,
      column: props.title,
      bins: bins
    })
  }, [props.title])
  
  const onClickBackListData = () => {
    props.backListData(true)
  }
  
  let onClickDraw = async () => {
    _getDataDetail({
      data_id: data.data_id,
      column: props.title,
      bins: bins,
      hist: 1
    })
  }
  
  let onChangeColumnNumber = (value) => {
    setBins(value)
  }
  
  const _getDataDetail = async (_query) => {
    setLoading(true)
    const response = await getDataDetail(_query);
    setLoading(false)
    if (response.status === 200) {
      //setLoading(false);
      if (response.data.Static) {
        setDataStatistic(convertDataStatistic(response.data.Static))
      }
      if (response.data["Missing Value Analysis"]) {
        setDataNumberMissingValue(convertDataMissingValue(response.data["Missing Value Analysis"]))
      }
      if (response.data["Histogram"]) {
        setDataHistogram(JSON.parse(response.data["Histogram"]))
      }
      return
    }
    
    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
  }
  
  
  return (
    <div className="statistic_tab">
      <Spin spinning={loading}>
        <div>
          <Button type="primary" onClick={onClickBackListData}>
            <i style={{marginRight: 5}} className="fas fa-chevron-left"/> {t('Back')}
          </Button>
          <div className="title">{props.title}
          </div>
          <div style={{textAlign: "right"}}><InputNumber onChange={onChangeColumnNumber} min={1} defaultValue={10}
                                                         max={100} style={{marginRight: '5px'}}/><Button
            onClick={onClickDraw}
            type="primary">Draw</Button>
          </div>
          <div className="big_chart">
            <Plot
              data={dataHistogram.data}
              layout={dataHistogram.layout}
            />
          </div>
          <div className="title">{t('Statistics')}</div>
          
          {isHideTable ? "" : <Table dataSource={[dataStatistic]} pagination={false} columns={columnsNumber}/>}
        </div>
        <div>
          <div className="title">{t('Missing Value Analysis')}</div>
          <Table dataSource={[dataNumberMissingValue]} pagination={false} columns={columnsNumberMissingValue}/>
        </div>
      </Spin>
    </div>
  )
}

export default StatisticsTabDetail