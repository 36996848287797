import React, { Fragment } from 'react';
import { List, Avatar, Row, Tag, Col, Modal, Button, Icon, notification } from 'antd';
import { Link } from 'react-router-dom';

import './style.less'
import { STATUS_USER, ROUTES } from 'consts';
import { t } from 'utils/i18n';
import { updateTeam } from 'apis/teams';

const ListUsers = (props) => {
  const _getAvatar = (user) => {
    if (!user.avatar) {
      return {
        icon: <Icon type="user" />
      }
    }
    if (user.avatar === 'loading') {
      return {
        icon: <Icon type="loading" spin />
      }
    }
    return {
      src: process.env[`REACT_APP_BACKEND_${(process.env.NODE_ENV !== 'production' ? "DEV" : "PROD")}`] + `/users/avatar/${user.avatar || ""}`
    }
  }
  const setManager = (item) => async () => {
    if (item.usersTeams.status === 'PENDING') {
      notification.warning({
        message: t("User hasn't joined yet")
      })
      return
    }
    Modal.confirm({
      title: t("Are you sure to grant user %1 to %2?", item.fullname, "MANAGER"),
      onCancel: () => { },
      onOk: async () => {
        const response = await updateTeam({
          manageBy: item.id
        }, localStorage.getItem("currentTeamId"))
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          if (props.onReload) {
            props.onReload()
          }
          return
        }
        else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
      }
    })
  }

  const deleteUser = (item) => () => {
    Modal.confirm({
      title: t("Are you sure to remove user %1 from team?", item.fullname || item.email),
      onCancel: () => { },
      onOk: async () => {
        const response = await updateTeam({
          removeUser: item.id
        }, localStorage.getItem("currentTeamId"))
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          if (props.onReload) {
            props.onReload()
          }
          return
        }
        else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
      }
    })
  }

  const demoteManager = (item) => async () => {
    Modal.confirm({
      title: t("Are you sure to grant user %1 to %2?", item.fullname, "USER"),
      onCancel: () => { },
      onOk: async () => {
        const response = await updateTeam({
          demotes: item.id
        }, localStorage.getItem("currentTeamId"))
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          if (props.onReload) {
            props.onReload()
          }
          return
        }
        else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
      }
    })
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={props.dataSource}
      className="list_users"
      loading={props.loading || false}
      renderItem={(item, index) => {
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  {..._getAvatar(item)}
                  size={80}
                  shape="square"
                />
              }
              title={<Row style={{marginBottom: 4}}>
                <Col span={12}>
                  <Link to={item.id === props.actorId ? ROUTES.USER_SETTING : `/team/${item.usersTeams.teamId}/user/${item.id}`} className="title">
                    <div className="ellipsis_text">
                      {item.fullname}
                    </div>
                  </Link>
                </Col>
                <Col span={12}>
                  <Row type="flex" justify="end">
                    <Tag color={STATUS_USER[item.usersTeams.status]}>{t(item.usersTeams.status || "")}</Tag>
                  </Row>
                </Col>
              </Row>}
              description={
                <Fragment>
                  <Row type="flex" justify="space-between" align="middle">
                    <span>
                      {item.email}
                    </span>
                    <span span={12}>
                      {
                        props.isManager ?
                          !item.usersTeams.isManager ?
                            <Button onClick={setManager(item)} type="primary">{t("Grant to manager")}</Button> :
                            item.id === props.actorId ?
                              null :
                              <Button onClick={demoteManager(item)} type="primary">{t("Grant to user")}</Button> :
                          null
                      }
                      <span style={{ padding: 4 }} />
                      {props.isManager && item.id !== props.actorId ? <Button onClick={deleteUser(item)} type="danger"><Icon type="delete" /></Button> : null}
                      <span style={{ padding: 4 }} />
                    </span>
                  </Row>
                  <Row type="flex" justify="space-between" align="middle">
                    <span>
                      {item.usersTeams.isManager ? t("MANAGER") : t("USER")}
                    </span>
                    <span span={12}>
                    </span>
                  </Row>
                </Fragment>
              }
            />
          </List.Item>
        )
      }}
    />
  )
}

ListUsers.defaultProps = {
  dataSource: []
}

export default ListUsers