import React, { Fragment, useState } from 'react';
import { Modal, Button } from 'antd';
import { t } from 'utils/i18n';
import Timelines from '../Timelines';

const onHideModal = (setVisible) => () => {
  setVisible(false)
}

const onOpenModal = (setVisible) => () => {
  setVisible(true)
}

/**
 * @param {Object} props
 * @param {Array} props.dataSource
 * 
 */
export default (props) => {
  const [visible, setVisible] = useState(false)

  return (
    <Fragment>
      <Button
        type="primary"
        className="button_show_activities"
        onClick={onOpenModal(setVisible)}
      >
        {t("Show all activities")}
      </Button>
      <Modal
        cancelText={t("Cancel")}
        title={t("Activities")}
        visible={visible}
        onCancel={onHideModal(setVisible)}
        footer={null}
        width="60%"
      >
        <div style={{
          maxHeight: "60vh",
          overflowY: "auto"
        }}>
          <Timelines dataSource={props.dataSource} />
        </div>
      </Modal>
    </Fragment>
  )
}