import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

moment.locale("ja")

class DateRange extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: null,
      endDate: null,
      endOpen: false,
    };
  }

  disabledStartDate = startDate => {
    const { endDate } = this.state;
    if (!startDate || !endDate) {
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  };

  disabledEndDate = endDate => {
    const { startDate } = this.state;
    if (!endDate || !startDate) {
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  };

  onChange = field => value => {
    this.setState({
      [field]: value,
    });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(field, value)
    }
  }

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  render() {
    const { startDate, endDate, endOpen } = this.state;
    return (
      <div>
        <DatePicker
          disabledDate={this.disabledStartDate}
          showTime={{
            defaultValue: moment('00:00:00', 'HH:mm:ss')
          }}
          value={startDate}
          placeholder="開始日"
          onChange={this.onChange("startDate")}
          onOpenChange={this.handleStartOpenChange}
          style={{
            paddingRight: 5
          }}
        />
        <span>~</span>
        <DatePicker
          disabledDate={this.disabledEndDate}
          showTime={{
            defaultValue: moment('23:59:59', 'HH:mm:ss')
          }}
          value={endDate}
          placeholder="終了日"
          onChange={this.onChange("endDate")}
          open={endOpen}
          onOpenChange={this.handleEndOpenChange}
          style={{
            paddingLeft: 5
          }}
        />
      </div>
    );
  }
}

export default DateRange