import React, { forwardRef, useEffect, useState } from 'react';
import { Button, notification, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom'
import { viewData } from 'apis/datas';

import { t } from "utils/i18n"
import DataSheet from "components/DataSheet";
import { accessDownload } from "apis/auth";

/**
 * @param props
 * @param ref
 * @return {*}
 * @constructor
 */
const DataViewTab = (props, ref) => {
  const [dataCsv, setDataCsv] = useState();
  const [spinning, setSpinning] = useState(true)
  const { state: { data } } = useLocation()
  useEffect(
    () => {
      _getCsv()
    }, [])

  const _onDownloadFile = async () => {
    console.log("AAAAA")
    const response = await accessDownload();
    if (response.status === 200) {
      window.open(`/api/v1/datas/download?file_id=${data.data_id}&project_id=${data.project_id}&team_id=${localStorage.getItem("currentTeamId")}&token=${response.data.token}`, "_blank")
      return
    }
    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
  }
  const _getCsv = async () => {
    const response = await viewData({
      file_id: data.data_id,
      project_id: data.project_id
    })
    setSpinning(false)
    if (response.status === 200) {
      setDataCsv(response.data)
    }
  }

  return (
    <div className="select_column_tab">
      <Spin tip={t("Loading..")} spinning={spinning}>
        <DataSheet csv={dataCsv} editable={props.editable} ref={ref}/>
        <Button type="primary" onClick={_onDownloadFile} size='large'>
          <DownloadOutlined/>
          {t('Download File')}
        </Button>
      </Spin>
    </div>
  )
}

export default forwardRef(DataViewTab)
