import React, {Fragment, useState, useEffect} from 'react';
import {Button, Modal, Upload, Icon, Form, Input, Select, notification, Row, Col, Checkbox, Spin} from 'antd';
import {t} from 'utils/i18n';
import {LABELS} from 'consts';
import SelectLabel from 'components/SelectLabel';
import {onChangeInput, onBlurInput, beforSubmit} from 'utils/Logics';
import {uploadDatas} from 'apis/datas';
import {getListProjects, searchProjects} from 'apis/projects';
import {debound} from "utils";


/**
 *
 * @param {Object} props
 * @param {Function} props.function
 */
const ModalRegisterData = (props) => {
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState(new FormData());
  const [dataName, setDataName] = useState("")
  const [dataModel, setDataModel] = useState("")
  const [isCheckedModelName, setIsCheckedModelName] = useState(false)
  const [projectId, setProjectId] = useState('')
  const [label, setLabel] = useState(LABELS.TRAINING_DATA);
  // const [featureLabel, setFeatureLabel] = useState("")
  const [message, setMessage] = useState({});
  const [fileList, setFileList] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [listProject, setListProject] = useState([])
  const [numOfScroll, setNumOfScroll] = useState(1)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setProjectId(props.projects[0] && props.projects[0].project_id)
    setListProject(props.projects)
  }, [props.projects])
  useEffect(() => {
    _getListProjects(numOfScroll)
  }, [numOfScroll])
  
  const _getListProjects = async (page) => {
    setLoading(true)
    const response = await getListProjects({
      page,
      filter:'data'
      
    });
    if (response.status === 200) {
      setLoading(false)
      setListProject([...listProject, ...response.data.data])
    } else {
      setLoading(false)
    }
  }
  
  const _onHideModal = () => {
    setVisible(false)
    setFormData(new FormData())
    setFileList([])
    setDataName("")
    // setFeatureLabel("")
    setDataModel("")
    setIsCheckedModelName(false)
  }
  const _onOpenModal = () => setVisible(true)
  const _onOk = async () => {
    let body = {};
    if (isCheckedModelName) {
      body = beforSubmit({
        file: {
          value: fileList[0],
          setMessage: _setMessage("file"),
          title: "File"
        },
        dataName: {
          value: dataName,
          setMessage: _setMessage("dataName"),
          title: "Data name"
        },
        label: {
          value: label,
          setMessage: _setMessage("label"),
          title: "Label"
        },
        // featureLabel: {
        //   value: featureLabel,
        //   setMessage: _setMessage("featureLabel")
        // },
        projectId: {
          value: projectId,
          setMessage: _setMessage("projectId"),
          title: "Project"
        },
        dataModel: {
          value: dataModel,
          setMessage: _setMessage("dataModel"),
          title: "Data model upload"
        }
      })
    } else {
      body = beforSubmit({
        file: {
          value: fileList[0],
          setMessage: _setMessage("file"),
          title: "File"
        },
        dataName: {
          value: dataName,
          setMessage: _setMessage("dataName"),
          title: "Data name"
        },
        label: {
          value: label,
          setMessage: _setMessage("label"),
          title: "Label"
        },
        // featureLabel: {
        //   value: featureLabel,
        //   setMessage: _setMessage("featureLabel")
        // },
        projectId: {
          value: projectId,
          setMessage: _setMessage("projectId"),
          title: "Project"
        }
      })
    }
    
    
    if (!body) {
      return
    }
    setConfirmLoading(true)
    if (formData.get("name")) {
      formData.delete('name')
    }
    if (formData.get("label")) {
      formData.delete('label')
    }
    
    if (formData.get("project_id")) {
      formData.delete('project_id')
    }
    
    if (formData.get("model_name") && isCheckedModelName) {
      formData.delete('model_name')
    }
    formData.append('project_id', body.projectId)
    formData.append('label', body.label)
    formData.append('name', body.dataName)
    if (isCheckedModelName) {
      formData.append('model_name', body.dataModel)
    }
    const response = await uploadDatas(formData)
    _onHideModal()
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      if (props.onReloadDatas) {
        props.onReloadDatas()
      }
      if (props.reloadList) {
        props.reloadList()
      }
    } else {
      if (props.onReloadDatas) {
        props.onReloadDatas()
      }
      if (props.reloadList) {
        props.reloadList()
      }
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    setConfirmLoading(false)
    
  }
  let getNameFile = (name) => {
    let newName = name.split('.')
    newName.pop()
    return newName.join('.')
  }
  const _setMessage = field => value => setMessage({...message, [field]: value})
  const _customRequest = (option) => {
    formData.set("file", option.file);
    setFormData(formData);
  }
  const onChangeCheckbox = (e) => {
    setIsCheckedModelName(e.target.checked)
  }
  const _onSearchProject = async (value) => {
    if (value === undefined || value.trim() !== "") {
      const response = await searchProjects({name: value});
      if (response.status === 200) {
        setListProject(response.data)
      } else notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }
  const _onChangeFileList = (info) => {
    if (info.fileList.length === 0) {
      return setFileList([])
    }
    setDataName(getNameFile(info.file.name))
    setDataModel(`${getNameFile(info.file.name)}${t('_model')}`)
    const {file} = info
    file.status = 'done'
    if (!file.name || !['csv', 'xlsx', 'xls'].includes(file.name.split(".").pop())) {
      notification.error({
        message: t("Error"),
        description: t("Only accept .csv, .xls or .xlsx file.")
      })
    }
    if (file.size / 1048576 /* 1MB */ > 30) {
      notification.warning({
        message: t("File size exceeded limit; please resize your avatar under 30MB.")
      })
    }
    setFileList([file])
  }
  const _onRemoveItem = () => true
  const handleScroll = (e) => {
    
    const isEndOfList = e.target.scrollTop + e.target.clientHeight;
    
    if (isEndOfList === e.target.scrollHeight) {
      
      setNumOfScroll(numOfScroll + 1)
    }
  }
  return (
    <Fragment>
      <Button
        type="primary"
        onClick={_onOpenModal}
        disabled={localStorage.getItem("currentTeamId") ? false : true}
      >
        {t("Register new data")}
      </Button>
      <Modal
        cancelText={t("Cancel")}
        visible={visible}
        title={t("Register new data")}
        onCancel={_onHideModal}
        onOk={_onOk}
        confirmLoading={confirmLoading}
        style={{top: "5vh"}}
        width="600px"
      >
        <Form.Item help={message.file}>
          <Upload.Dragger
            accept=".csv,.xlsx,.xls"
            name="file"
            multiple={false}
            fileList={fileList}
            onChange={_onChangeFileList}
            customRequest={_customRequest}
            showUploadList={{
              showDownloadIcon: false
            }}
            onRemove={_onRemoveItem}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox"/>
            </p>
            <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
          </Upload.Dragger>
        </Form.Item>
        <Form layout="horizontal" labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left">
          <Form.Item label={t("Project")} required help={message.projectId}>
            <Select
              loading={loading}
              value={projectId}
              onChange={(value) => {
                setProjectId(value)
              }}
              className="fullsize_selectbox"
              onSearch={debound(_onSearchProject, 500)}
              showSearch
              defaultActiveFirstOption={false}
              filterOption={false}
              notFoundContent={null}
              onPopupScroll={handleScroll}
            >
              {
                listProject.map(p => (
                  
                  <Select.Option value={p.project_id} key={p.project_id}>{`${p.name}`}</Select.Option>
                ))
              }
              <Spin key={1} value={1} loading={true}/>
            </Select>
          </Form.Item>
          <Form.Item label={t("Label")} required>
            <SelectLabel value={LABELS.DATA_LIST} onChange={setLabel} value={label}/>
          </Form.Item>
          {/* <Form.Item label={t("Feature label")} required help={message.featureLabel}>
            <Input
              value={featureLabel}
              onChange={onChangeInput(setFeatureLabel, _setMessage("featureLabel"))}
              onBlur={onBlurInput(featureLabel, _setMessage("featureLabel"), "Feature label")}
            />
          </Form.Item> */}
          <Form.Item label={t("Data name upload")} required help={message.dataName}>
            <Input
              value={dataName}
              onChange={onChangeInput(setDataName, _setMessage("dataName"))}
              onBlur={onBlurInput(dataName, _setMessage("dataName"), "Data name")}
              maxLength={100}
            />
          </Form.Item>
          <Row>
            <div>
              <span><strong>{t("Format:")}</strong> {t("csv, Excel (xls, xlsx)")}</span>
            </div>
            <div>
              <span><strong>{t("Item Name:")}</strong> {t("Item name should list on the first row.")}</span>
            </div>
            <div>
              <span><strong>{t("Required Item Name:")}</strong> {t("Id is need to identify each low. Please set the left. Target is need to define forecasting target. Please set the right. Id and Traget can’t be used for other purpose.")}</span>
            </div>
            <div style={{marginTop: 10}}><Checkbox checked={isCheckedModelName}
                                                   onChange={onChangeCheckbox}>{t('Create a model')}</Checkbox></div>
          </Row>
          
          {isCheckedModelName ? <Form.Item label={t("Model Name")} required help={message.dataModel}>
            <Input
              value={dataModel}
              onChange={onChangeInput(setDataModel, _setMessage("dataModel"))}
              onBlur={onBlurInput(dataModel, _setMessage("dataModel"), "Data model upload")}
              maxLength={100}
            />
          </Form.Item> : ""}
        </Form>
      </Modal>
    </Fragment>
  )
}

export default ModalRegisterData