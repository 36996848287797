import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES, ROLES } from 'consts';
import { PublicRoute, PrivateRoute } from 'components/Routes';

import ListTeams from 'views/Team/ListTeams';
import Information from 'views/Information';
import Home from 'views/Home';
import Login from 'views/Login';
import UserSetting from 'views/UserSetting';
import DataManagement from 'views/DataManagement';
import ModelManagement from 'views/ModelManagement';
import { newMessage } from 'utils/i18n';
import ForgotPassword from 'views/ForgotPassword';
import ResetPassword from 'views/ResetPassword';
import DetailTeam from 'views/Team/DetailTeam';
import InviteUser from 'views/InviteUser';
import UserProfile from 'views/UserProfile';
import DetailData from 'views/DetailData';
import Projects from 'views/Projects';

const defineRoutes = [
  {
    Component: Login,
    exact: true,
    protect: false,
    path: ROUTES.LOGIN
  },
  {
    Component: Home,
    exact: true,
    protect: {
      must: ROLES.USER,
    },
    path: ROUTES.HOME
  },
  {
    Component: DataManagement,
    exact: true,
    protect: {
      must: ROLES.USER,
    },
    path: ROUTES.DATA_MANAGEMENT
  },
  {
    Component: ModelManagement,
    exact: true,
    protect: {
      must: ROLES.USER,
    },
    path: ROUTES.MODEL_MANAGEMENT
  },
  {
    Component: ListTeams,
    exact: true,
    protect: {
      must: ROLES.ADMIN,
    },
    path: ROUTES.MASTER
  },
  {
    Component: Information,
    exact: true,
    protect: {
      must: ROLES.ADMIN,
    },
    path: ROUTES.INFORMATION
  },
  {
    Component: DetailTeam,
    protect: {
      must: ROLES.USER,
    },
    path: ROUTES.DETAIL_TEAM
  },
  {
    Component: UserSetting,
    exact: true,
    protect: {
      role: ROLES.USER,
    },
    path: ROUTES.USER_SETTING
  },
  {
    Component: ForgotPassword,
    exact: true,
    protect: false,
    path: ROUTES.FORGOT_PASSWORD
  },
  {
    Component: ResetPassword,
    exact: true,
    protect: false,
    path: ROUTES.RESET_PASSWORD
  },
  {
    Component: InviteUser,
    exact: true,
    protect: false,
    path: ROUTES.REGISTER
  },
  {
    Component: UserProfile,
    exact: true,
    protect: {
      role: ROLES.USER
    },
    path: ROUTES.OTHER_USER
  },,
  {
    Component: Projects,
    exact: true,
    protect: {
      role: ROLES.USER
    },
    path: ROUTES.PROJECT
  },
  {
    Component: DetailData,
    exact: true,
    protect: {
      role: ROLES.USER
    },
    path: ROUTES.DETAIL_DATA
  },
]

export default (props) => {
  return (
    <BrowserRouter>
      <Switch>
        {
          defineRoutes.map((route, index) => (
            route.protect ?
              <PrivateRoute {...route} key={index} /> :
              <PublicRoute {...route} key={index} />
          ))
        }
        {
          process.env.NODE_ENV === 'development' ?
            <Route path="/new-message-i18n" render={props => <div>{JSON.stringify(newMessage)}</div>} /> :
            null
        }
        <Route path="/" render={(props => <Redirect to={ROUTES.HOME} />)} />
      </Switch>
    </BrowserRouter>
  )
}