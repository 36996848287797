import React, { useEffect, useState } from 'react'
import BaseLayout from 'components/BaseLayout'
import { Typography, Row, Table, Button, Icon, notification, Pagination, Tooltip } from 'antd'
import { getListProjects, deleteProject, updateProject } from 'apis/projects'
import { t } from 'utils/i18n'
import ModalCreateProject from './ModelCreateProject'
import TitleTable from 'components/TitleTable'
import SpanEditable from 'components/SpanEditable'
import moment from 'moment'
import numeral from "numeral";

export default (props) => {
  const [projects, setProjects] = useState({});
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState({
    sort_by: 'updated_at',
    order: "DESC"
  })
  
  useEffect(() => {
    _getListProjects()
  }, [query, localStorage.getItem("currentTeamId")])

  const _getListProjects = async () => {
    setLoading(true)
    const response = await getListProjects(query);
    if (response.status === 200) {
      setProjects(response.data)
    }
    else {
      notification.error({
        message: t(response.data.message)
      })
    }
    setLoading(false)
  }

  const _onSort = (field) => order => {
    setQuery({
      ...query,
      sort_by: field,
      order
    })
  }

  const _deleteProject = id => async () => {
    const response = await deleteProject(id);
    if (response.status === 200) {
      notification.success({
        message: t("Success"),
      })
      _getListProjects()
    }
    else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  const _saveProject = id => async (value) => {
    const response = await updateProject({
      project_id: id,
      params: [
        {
          field: 'name',
          value
        }
      ]
    });
    if (response.status === 200) {
      notification.success({
        message: t("Success"),
      })
      _getListProjects()
      return true 
    }
    else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
      return false
    }
  }

  const _onChangePage = (page) => {
    setQuery({
      ...query,
      page
    })
  }

  return (
    <BaseLayout>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Typography.Title level={4}>
          {t("Project")}
        </Typography.Title>
        <span>
          <ModalCreateProject onReloadProjects={_getListProjects} />
        </span>
      </Row>
      <Table
        dataSource={projects.data}
        loading={loading}
        size="small"
        pagination={false}
      >
        <Table.Column
          title={<TitleTable onSort={_onSort("name")}>{t("Project")}</TitleTable>}
          key="name"
          dataIndex="name"
          render={(text, record) => (
            <SpanEditable
              style={{
                color: "rgba(0, 0, 0, 0.85)"
              }}
              isName="project"
              onSave={_saveProject(record.project_id)}
            >
              {text}
            </SpanEditable>
          )}
          width={300}
        />
        <Table.Column
          title={<TitleTable showSort={false} onSort={_onSort("data_count")}>{t("Data")}</TitleTable>}
          key="data_count"
          dataIndex="data_count"
          render={text => (
            <span>{numeral(text).format('0,0')}</span>
          )}
        />
        <Table.Column
          title={<TitleTable showSort={false} onSort={_onSort("model_count")}>{t("Model")}</TitleTable>}
          key="model_count"
          dataIndex="model_count"
          render={text => (
            <span>{numeral(text).format('0,0')}</span>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("updated_at")}>{t("Updated at")}</TitleTable>}
          key="updated_at"
          dataIndex="updated_at"
          width={200}
          render={text => (
            <strong>{moment(text).format("YYYY-MM-DD HH:mm")}</strong>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("updated_by")}>{t("Updated by")}</TitleTable>}
          key="editor"
          dataIndex="editor"
          render={(text, record) => (
            <Tooltip overlay={<span>{record.updated_by + "" === "0" ? "API User" : text}</span>}>
              <div className="ellipsis_text"><strong>{record.updated_by + "" === "0" ? "API User" : text}</strong></div>
            </Tooltip>
          )}
        />
        <Table.Column
          align="right"
          width="10%"
          render={(text, record) => (
            <Button size="small" type="danger" onClick={_deleteProject(record.project_id)}>
              <Icon type="delete" />
            </Button>
          )}
        />
      </Table>
      <Row type="flex" justify="end" style={{ paddingTop: 5 }}>
        <Pagination size="small" showQuickJumper pageSize={20} total={Number(projects.totalRecord || 0)} current={Number(projects.page || 1)} onChange={_onChangePage} />
      </Row>
    </BaseLayout>
  )
}