import React, { useEffect, useState, Fragment, useContext } from 'react';
import { Table, Select, Button, notification, Icon, Row, Pagination, Modal, Tooltip, Col } from 'antd';
import { t } from 'utils/i18n';
import moment from 'moment';
import TitleTable from 'components/TitleTable';
import SelectLabel from 'components/SelectLabel';
import { getListDatas, deleteData, updateData } from 'apis/datas';
import { requestRPAData } from 'apis/rpa';
import { ROUTES, LABELS } from 'consts';
import { accessDownload } from 'apis/auth';
import Configs from 'components/ConfigProviders';
import { useHistory } from 'react-router-dom';
import SpanEditable from 'components/SpanEditable';
import { getListProjects } from "apis/projects";
import numeral from "numeral";

/**
 *
 * @param {Object} props
 * @param {String} props.label
 */
const TableDataList = (props) => {
  const history = useHistory()
  const [listData, setListData] = useState({});
  const [loading, setLoading] = useState(false)
  const [numOfScroll, setNumOfScroll] = useState(1)
  const [loadingSelect, setLoadingSelect] = useState(false)
  const [listProject, setListProject] = useState([])
  const [query, setQuery] = useState({
    sort_by: 'updated_at',
    order: "DESC"
  })
  const { team } = useContext(Configs)

  useEffect(() => {
    setListProject(props.projects)
  }, [props.projects])
  useEffect(() => {
    _getListProjects(numOfScroll)
  }, [numOfScroll])

  const _getListProjects = async (page) => {
    setLoadingSelect(true)
    const response = await getListProjects({
      page,
      sort_by: 'updated_at',
      order: 'DESC'
    });
    if (response.status === 200) {
      setLoadingSelect(false)
      setListProject([...listProject, ...response.data.data])
    } else {
      setLoadingSelect(false)
    }
  }
  useEffect(() => {
    if (props.activeKey === props.label || !props.label) {
      if (localStorage.getItem("currentTeamId")) {
        _getListData(query)
      }
    }
  }, [props.activeKey, props.projectId, query, team])
  const handleScroll = (e) => {

    const isEndOfList = e.target.scrollTop + e.target.clientHeight;

    if (isEndOfList === e.target.scrollHeight) {

      setNumOfScroll(numOfScroll + 1)
    }
  }
  const _getListData = async (_query) => {
    const query = {
      ..._query,
      label: props.label,
      project_id: props.projectId
    }
    setLoading(true)
    const response = await getListDatas(query);
    setLoading(false)
    if (response.status === 200) {
      setListData({})
      setListData(response.data);
      return
    }

    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
  }

  const onSaveDataName = (record) => async name => {
    const response = await updateData({
      data_id: record.data_id,
      project_id: record.project_id,
      params: [
        {
          field: "name",
          value: name
        }
      ]
    })
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      _getListData(query)
      return true
    } else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    return false
  }

  const onChangeLabel = (record) => async (value) => {
    const response = await updateData({
      data_id: record.data_id,
      project_id: record.project_id,
      params: [
        {
          field: "label",
          value: value
        }
      ]
    })
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      _getListData(query)
    } else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  const onChangeProject = (record) => async value => {
    const response = await updateData({
      data_id: record.data_id,
      project_id: record.project_id,
      params: [
        {
          field: "project_id",
          value: Number(value)
        }
      ]
    })
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      _getListData(query)
    } else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  const _onChangePage = (page) => {
    setQuery({
      ...query,
      page
    })
  }

  const _onDeleteData = (record) => () => {
    Modal.confirm({
      title: t("Are you sure to delete this data"),
      onCancel: () => {
      },
      onOk: async () => {
        const response = await deleteData({
          data_id: record.data_id,
          project_id: record.project_id
        })
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          _getListData(query)
        } else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
      }
    })
  }

  const _onSort = (field) => order => {
    setQuery({
      ...query,
      sort_by: field,
      order
    })
  }

  const _onRPARequest = (record) => () => {
    Modal.confirm({
      title: t("RPA confirmation"),
      onCancel: () => {
      },
      onOk: async () => {
        const response = await requestRPAData({
          file: record.data_id,
          project_id: record.project_id
        })
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          _getListData(query)
        } else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
      }
    })
  }

  const _onDownloadFile = (data) => async () => {
    const response = await accessDownload();
    if (response.status === 200) {
      window.open(`/api/v1/datas/download?file_id=${data.data_id}&project_id=${data.project_id}&team_id=${localStorage.getItem("currentTeamId")}&token=${response.data.token}`, "_blank")
      return
    }
    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
  }

  const _onCreateeModel = (data) => () => {
    history.push(ROUTES.MODEL_MANAGEMENT, { data, state: "CREATE_MODEL" })
  }

  const _onViewDetail = (record) => () => {
    history.push(ROUTES.DETAIL_DATA.replace(":id", record.data_id), { data: record })
  }

  return (
    <Fragment>
      <Table
        loading={loading}
        dataSource={listData.data}
        size="small"
        pagination={false}
        scroll={{
          x: 1500
        }}
      >
        <Table.Column
          title={<TitleTable onSort={_onSort("data_name")}>{t("Name")}</TitleTable>}
          key="data_name"
          dataIndex="data_name"
          width="260px"
          render={(text, record) => (
            <SpanEditable
              onSave={onSaveDataName(record)}
              onClick={_onViewDetail(record)}
              isNoneEllipsis={true}
              isName="data"
            >
              {text}
            </SpanEditable>
          )}
          fixed="left"
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("label")} showSearch={false}>{t("Label")}</TitleTable>}
          key="label"
          dataIndex="label"
          width="12%"
          render={(text, record) => (
            <SelectLabel
              value={t(text)}
              onChange={onChangeLabel(record)}
            />
            // <span>{t(text)}</span>
          )}
        />
        <Table.Column
          title={
            <TitleTable
              onSort={_onSort("project_id")}
            >
              {t("Project")}
            </TitleTable>
          }
          key="project_id"
          dataIndex="project_name"
          width="12%"
          render={(text, record) => (
            <Select
              value={text}
              loading={loadingSelect}
              onPopupScroll={handleScroll}
              onChange={onChangeProject(record)}
              className="fullsize_selectbox"
            >
              {
                listProject.map(p => (
                  <Select.Option value={p.project_id} key={p.project_id}>{`${p.name}`}</Select.Option>
                ))
              }
            </Select>
            // <span>{`${text}`}</span>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("item_number")}>{t("Item number")}</TitleTable>}
          key="item_number"
          dataIndex="item_number"
          width="10%"
          render={text => (
            <span>{numeral(text).format('0,0')}</span>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("number_row")}>{t("Number row")}</TitleTable>}
          key="number_row"
          dataIndex="number_row"
          width="10%"
          render={text => (
            <span>{numeral(text).format('0,0')}</span>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("updated_at")}>{t("Update at")}</TitleTable>}
          key="updated_at"
          dataIndex="updated_at"
          width="200px"
          render={(text) => (
            <strong>{moment(text).format("YYYY-MM-DD HH:mm")}</strong>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("updated_by")}>{t("Update by")}</TitleTable>}
          key="updated_by"
          dataIndex="updated_by"
          width="10%"
          render={(text, record) => (
            <Tooltip overlay={<span>{text + "" === "0" ? "API User" : record.editor}</span>}>
              <div className="ellipsis_text"><strong>{text + "" === "0" ? "API User" : record.editor}</strong></div>
            </Tooltip>
          )}
        />
        <Table.Column />
        <Table.Column
          width="250px"
          align="center"
          render={(text, record) => (
            <Row type="flex" justify="end" align="middle">
              {
                record.label === LABELS.TRAINING_DATA ?
                  <Col>
                    <Button size="small" type="primary" onClick={_onCreateeModel(record)}>
                      {t("Create Model")}
                    </Button>
                  </Col>
                  : null
              }
              {/* {
                record.label === LABELS.MASTER_DATA ?
                  <Col>
                    <Button
                      size="small"
                      type="primary"
                      onClick={_onRPARequest(record)}
                    >
                      {t("RPA")}
                    </Button>
                  </Col>
                  : null
              } */}
              <Col>
                <Button size="small" type="link" onClick={_onDownloadFile(record)}>
                  <Icon type="download" />
                </Button>
                <Button size="small" type="danger" onClick={_onDeleteData(record)}>
                  <Icon type="delete" />
                </Button>
              </Col>
            </Row>
          )}
          fixed="right"
        />
      </Table>
      <Row type="flex" justify="end" style={{ paddingTop: 5 }}>
        <Pagination size="small" showQuickJumper pageSize={20} total={listData.totalRecord || 0}
          current={Number(listData.page || 1)} onChange={_onChangePage} />
      </Row>
    </Fragment>
  )
}

export default TableDataList