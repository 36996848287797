import React, {useState, useEffect, useContext} from 'react';
import BaseLayout from 'components/BaseLayout';
import {Row, Col, Typography, Divider, List, Table, Button, Icon} from 'antd';
import numeral from 'numeral';
import {t} from 'utils/i18n';
import ModalActivities from 'components/ModalActivities';
import Timelines from 'components/Timelines';
import {getListModels} from 'apis/models';
import {getListHomeDatas} from 'apis/datas';
import {getTeamInfomation} from 'apis/teams';
import Configs from 'components/ConfigProviders';
import CanvasJSReact from '../../assets/canvasjs.react';
import {formatDataHomeChart} from "utils";
import {Line} from 'react-chartjs-2';
import {getInformation, getInformationClient} from "apis/informations";
import TitleTable from "components/TitleTable";
import moment from "moment";
import EditInformation from "../Information/EditInformation";
import {checkHttpLink} from './../../utils'
import './style.less'

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
export default (props) => {
  const [datas, setDatas] = useState({})
  const [models, setModels] = useState({})
  const {team} = useContext(Configs)
  const [teamActivities, setActivities] = useState([])
  const [dataInformation, setDataInformation] = useState([])
  const [valueSliceHome, setValueSliceHome] = useState(5)
  const data = {
    labels: [t('The past 22 to 28 days'), t('The past 15 to 21 days'), t('The past 8 to 14 days'), t('The past 7 days')],
    datasets: [
      {
        label: t('Project'),
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(109, 120, 169,0.4)',
        borderColor: 'rgba(109, 120, 169,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(109, 120, 169,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(109, 120, 169,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: (datas && datas['30_days']) ? formatDataHomeChart(datas['30_days']).project : []
      },
      {
        label: t('Data'),
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(81, 205, 156, 0.4)',
        borderColor: 'rgba(81, 205, 156, 1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(81, 205, 156, 1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(81, 205, 156, 1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: (datas && datas['30_days']) ? formatDataHomeChart(datas['30_days']).data : []
      },
      {
        label: t('Model'),
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(223, 121, 112,0.4)',
        borderColor: 'rgba(223, 121, 112,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(223, 121, 112,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(223, 121, 112,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: (datas && datas['30_days']) ? formatDataHomeChart(datas['30_days']).model : []
      },
      {
        label: t('Team'),
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: (datas && datas['30_days']) ? formatDataHomeChart(datas['30_days']).teams : []
      },
    ]
  };
  const option = {
    
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          padding: 10,
          
        }
      }],
      yAxes: [{
        gridLines: {
          drawBorder: false,
        },
        ticks: {
          padding: 10,
          
        }
      }],
      layout: {
        padding: {
          left: 50,
          right: 0,
          top: 0,
          bottom: 0
        }
      }
      
    }
  }
  useEffect(() => {
    const teamId = localStorage.getItem("currentTeamId") || team.id;
    if (teamId) {
      getListHomeDatas({teamId})
        .then(response => {
          if (response.data) {
            setDatas(response.data)
          }
        })
      getListModels({teamId})
        .then(response => {
          if (response.data) {
            setModels(response.data)
          }
        })
      getTeamInfomation(teamId)
        .then(response => {
          if (response.status === 200) {
            setActivities(response.data.activities.filter((activity) => ["created", "deleted", "uploaded", "invited"].includes(activity.action.toLowerCase())).slice(0, 20))
          }
        })
    }
  }, [team])
  useEffect(() => {
    _getDataInformation()
  }, [])
  const onClickSeeMore = () => {
    setValueSliceHome(10)
  }
  const _getDataInformation = async () => {
    const response = await getInformationClient({
      limit: 10
    })
    if (response.status === 200) {
      setDataInformation(response.data)
    }
  }
  let handelPercent = (percent) => {
    if (typeof percent === 'string')
      return <div>({percent})</div>
    if (percent > 0) {
      return <div>
        (<i className="fas fa-arrow-up" style={{color: '#5cb552'}}/> {`${percent}%`})
      </div>
    } else if (percent < 0) {
      return <div>
        (<i className="fas fa-arrow-down" style={{color: 'rgb(253, 78, 78)'}}/>
        {`${percent}%`})
      </div>
    } else {
      return <div>
        (0%)
      </div>
    }
    
    
  }
  return (
    <BaseLayout hideMenu={false}>
      <Row gutter={[30, 0]} className="home_page">
        <Col span={16}>
          <Row className="custom_row">
            <Typography.Title level={4}>
              {t("Status")}
            </Typography.Title>
            <Divider/>
            <Row type='flex' justify='space-between' style={{width: '80%', margin: '0 auto'}}>
              <Col style={{textAlign: 'center', color: 'black'}}>
                <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('Project')}</div>
                <div style={{fontSize: 27}}>{(datas && datas['status']) ? datas['status'].project : ''}</div>
                <div style={{fontSize: 14}}>{t('projects')}</div>
              </Col>
              <Col style={{textAlign: 'center', color: 'black'}}>
                <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('Data')}</div>
                <div style={{fontSize: 27}}>{(datas && datas['status']) ? datas['status'].data : ''}</div>
                <div style={{fontSize: 14}}>{t('datas')}</div>
              </Col>
              <Col style={{textAlign: 'center', color: 'black'}}>
                <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('Model')}</div>
                <div style={{fontSize: 27}}>{(datas && datas['status']) ? datas['status'].model : ''}</div>
                <div style={{fontSize: 14}}>{t('models')}</div>
              </Col>
              <Col style={{textAlign: 'center', color: 'black'}}>
                <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('Team')}</div>
                <div
                  style={{fontSize: 27}}>{(datas && datas['status']) ? datas['status'].users_teams : ''}</div>
                <div style={{fontSize: 14}}>{t('members')}</div>
              </Col>
            </Row>
          </Row>
          <Row>
            <Typography.Title level={4}>
              {t("Last 28 Days Activities")}
            </Typography.Title>
            <Divider/>
            
            <Row type='flex' justify='space-between' style={{width: '90%', margin: '0 auto'}}>
              <Line data={data} options={option}/>
              {/*<CanvasJSChart options={options}/>*/}
            </Row>
            <Row type='flex' justify='space-between' style={{width: '80%', margin: '20px auto'}}>
              <Col style={{textAlign: 'center', color: 'black'}}>
                <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('Project')}</div>
                <div
                  style={{fontSize: 27}}>{datas && datas['compare'] && datas['compare']['project']['add']}
                </div>
                <div>
                  {datas && datas['compare'] && handelPercent(datas['compare']['project']['gain'])}
                </div>
                
                <div style={{fontSize: 14}}>{t('Project')}</div>
                <div style={{fontSize: 14}}>{t('Added')}</div>
              </Col>
              <Col style={{textAlign: 'center', color: 'black'}}>
                <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('Data')}</div>
                <div
                  style={{fontSize: 27}}>{datas && datas['compare'] && datas['compare']['data']['add']}
                </div>
                {datas && datas['compare'] && handelPercent(datas['compare']['data']['gain'])}
                <div style={{fontSize: 14}}>{t('Data')}</div>
                <div style={{fontSize: 14}}>{t('Uploaded')}</div>
              </Col>
              <Col style={{textAlign: 'center', color: 'black'}}>
                <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('Model')}</div>
                <div
                  style={{fontSize: 27}}>{datas && datas['compare'] && datas['compare']['model']['add']}
                </div>
                {datas && datas['compare'] && handelPercent(datas['compare']['model']['gain'])}
                <div style={{fontSize: 14}}>{t('Model')}</div>
                <div style={{fontSize: 14}}>{t('Created')}</div>
              </Col>
              <Col style={{textAlign: 'center', color: 'black'}}>
                <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('Team')}</div>
                <div
                  style={{fontSize: 27}}>{datas && datas['compare'] && datas['compare']['teams']['add']}
                </div>
                {datas && datas['compare'] && handelPercent(datas['compare']['teams']['gain'])}
                <div style={{fontSize: 14}}>{t('Member')}</div>
                <div style={{fontSize: 14}}>{t('Joined')}</div>
              </Col>
            </Row>
          </Row>
          <Row style={{marginTop: 32}}>
            <Typography.Title level={4}>
              {t("Top Contributors")}
            </Typography.Title>
            <Divider/>
            {
              (datas && datas['contributions']) ? datas['contributions'].map((record, index) => {
                return <Row key={index} type='flex' justify='space-between'
                            style={{width: '80%', margin: '0 auto', fontSize: 14, color: 'black'}}>
                  <Col>{record.fullname}</Col>
                  <Col>{record.count} {t('activities')}</Col>
                </Row>
              }) : ''
            }
          
          </Row>
          
          <Row style={{marginTop: 32}}>
            <Typography.Title level={4}>
              {t("Information Board")}
            </Typography.Title>
            <Divider/>
            <Table
              pagination={false}
              size="small"
              bore
              dataSource={dataInformation.slice(0, valueSliceHome)}
            >
              <Table.Column
                key="date"
                dataIndex="date"
                render={(text) => (
                  <div style={{
                    width: 140,
                    fontWeight: 800,
                    fontSize: 14,
                    wordWrap: 'break-word',
                    wordBreak: 'break-word'
                  }}>{moment(text).format('YYYY-MM-DD (dd)')}</div>
                )}
              
              />
              <Table.Column
                key="information"
                dataIndex="information"
                render={(text, record) => {
                  {
                    return localStorage.getItem('lang') === 'ja' ? <div>
                      <div style={{fontSize: 16, whiteSpace: 'pre-line'}}>{record.jpDescription}</div>
                      <a target='_blank' href={checkHttpLink(record.jpLink)} style={{fontSize: 16}}>{record.jpLink}</a>
                    </div> : <div>
                      <div style={{fontSize: 16, whiteSpace: 'pre-line'}}>{record.enDescription}</div>
                      <a target='_blank' href={checkHttpLink(record.enLink)} style={{fontSize: 16}}>{record.enLink}</a>
                    </div>
                  }
                }}
              />
            </Table>
            {(valueSliceHome === 5 && dataInformation.length > 5) ?
              <div style={{textAlign: 'center', marginTop: 10}}><Button
                onClick={onClickSeeMore}>{t('See More')}</Button></div> : ''}
          </Row>
        </Col>
        <Col span={8} className="timeline min_col">
          <Typography.Title level={4}>
            {t("Activities of %1:", team && team.name)}
          </Typography.Title>
          <Divider/>
          <Timelines dataSource={teamActivities}/>
          <ModalActivities dataSource={teamActivities}/>
        </Col>
      </Row>
    </BaseLayout>
  )
}