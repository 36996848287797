import React, { Fragment, useState } from 'react';
import { Row, Button, Icon, Popover, Input } from 'antd';
import SortButton from '../SortButton';
import { onChangeInput } from 'utils/Logics';
import { t } from 'utils/i18n';
import DateRange from 'components/Datepicker';

import './style.less'

/**
 * 
 * @param {Object} props 
 * @param {Function} props.onSearch
 * @param {Function} props.onReset
 */
const ButtonFilter = (props) => (
  <Row type="flex" justify="center" align="middle" className="button_filter">
    <Button
      size="small"
      type="primary"
      onClick={props.onSearch}
    >
      {t("Search")}
    </Button>
    <span style={{ padding: 5 }} />
    <Button
      size="small"
      onClick={props.onReset}
    >
      {t("Reset")}
    </Button>
  </Row>
)

/**
 * 
 * @param {Object} props
 * @param {Function} props.onSearch
 * @param {Function} props.onReset 
 */
const FilterInput = (props) => {
  const [value, setValue] = useState("");

  const _onSearch = () => {
    if (props.onSearch) {
      props.onSearch(value)
    }
  }

  return (
    <Fragment>
      <Input
        onChange={onChangeInput(setValue)}
        value={value}
        autoFocus
        allowClear
        size="small"
      />
      <ButtonFilter onSearch={_onSearch} onReset={props.onReset} />
    </Fragment>
  )
}

/**
 * 
 * @param {Object} props
 * @param {Function} props.onSearch
 * @param {Function} props.onReset 
 */
const FitlerDateRange = (props) => {
  const [dateRange, setDateRange] = useState({});

  const _onChange = (field, value) => {
    setDateRange({ ...dateRange, [field]: value })
  }

  const _onSearch = () => {
    if (props.onSearch) {
      props.onSearch(dateRange)
    }
  }

  const _onReset = () => {
    setDateRange({ startDate: null, endDate: null })
    if (props.onReset) {
      props.onReset({})
    }
  }

  return (
    <Fragment>
      <DateRange onChange={_onChange} />
      <ButtonFilter onSearch={_onSearch} onReset={_onReset} />
    </Fragment>
  )
}

const getSearch = (Component, onSearch, onReset) => {
  switch (Component) {
    case false: return null
    case true: return <FilterInput onSearch={onSearch} onReset={onReset} />
    case "daterange": return <FitlerDateRange onSearch={onSearch} onReset={onReset} />
    default: return null
  }
}

/**
 * @param {Object} props
 * @param {Function} props.onSort
 * @param {Function} props.onSearch
 * @param {Function} props.onReset
 * @param {React.Component} props.showSearch
 */
const TitleTable = (props) => {
  const [visible, setVisible] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const _onClickSearch = () => setVisible(!visible)

  const _onSearch = (text) => {
    setVisible(false);
    if (props.onSearch) {
      props.onSearch(text)
    }
  }

  const _onReset = () => {
    setVisible(false);
    if (props.onReset) {
      props.onReset()
    }
  }

  return (
    <Row type="flex" justify="start" align="top" style={{ width: "100%", position: "relative" }}>
      <span style={{ paddingRight: 15 }}>
        <strong style={{ lineHeight: 1.8 }}>{props.children}</strong>
        {
          props.showSort ?
            <SortButton onSort={props.onSort} /> :
            null
        }
      </span>
      {
        props.showCreate ?
          <Fragment>
            <Button type="link" onClick={() => setModalVisible(!modalVisible)}>
              <Icon type="plus" />
            </Button>
            {props.modal && <props.modal visible={modalVisible} setVisible={setModalVisible} />}
          </Fragment> :
          null
      }
      {
        props.showSearch !== false ?
          <Popover
            placement="bottomRight"
            content={getSearch(props.showSearch, _onSearch, _onReset)}
            trigger="click"
            visible={visible}
          >
            <Button
              size="small"
              shape="circle"
              className="button_none_color"
              onClick={_onClickSearch}
            >
              <Icon type="search" />
            </Button>
          </Popover> :
          null
      }
    </Row >
  )
}

TitleTable.defaultProps = {
  showSearch: false,
  showSort: true
}

export default TitleTable