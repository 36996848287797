import React, { useState, Fragment } from 'react';
import { Modal, Form, Input, notification, Button } from 'antd';
import { t } from 'utils/i18n';
import { onChangeInput, onBlurInput, beforSubmit } from 'utils/Logics';
import { createProject } from 'apis/projects';

const ModalCreateProject = (props) => {
  const [visible, setVisible] = useState(false)
  const [name, setProject] = useState("");
  const [message, setMessage] = useState("");

  const _closeModal = () => {
    setVisible(false)
    setProject("")
  }

  const _onOpenModal = () => {
    setVisible(true)
  }

  const _onOk = async () => {
    const body = beforSubmit({
      name: {
        value: name,
        setMessage: setMessage,
        title: "Project name"
      }
    })
    if (!body) {
      return
    }
    const response = await createProject(body);
    if (response.status === 200) {
      notification.success({
        message: t("Success"),
      })
      if (props.onReloadProjects) {
        props.onReloadProjects()
      }
      return _closeModal()
    }
    else {
      notification.success({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  return (
    <Fragment>
      <Button type="primary" onClick={_onOpenModal} disabled={localStorage.getItem("currentTeamId") ? false : true}>
        {t("Create project")}
      </Button>
      <Modal
        title={t("Create project")}
        visible={visible}
        onCancel={_closeModal}
        onOk={_onOk}
      >
        <Form>
          <Form.Item label={t("Project name")} required help={message}>
            <Input
              value={name}
              onChange={onChangeInput(setProject, setMessage)}
              onBlur={onBlurInput(name, setMessage, "Project name")}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}

export default ModalCreateProject