import React, {useEffect, useState, Fragment, useContext} from 'react';
import {Table, InputNumber, Button, notification, Spin} from 'antd';
import Plot from 'react-plotly.js';
import {useLocation} from 'react-router-dom'
import {getDataDetail} from "apis/datas";
import {t} from "utils/i18n";
import {convertDataStatistic, convertDataMissingValue, convertDataViewString, convertDataViewStringDetail} from "utils";
// const {Column, ColumnGroup} = Table;

/**
 *
 * @param {Object} props
 * @param {String} props.label
 */
const StatisticsTabStringDetail = (props) => {
  const columnsStringDetail = [
    {
      title: "",
      dataIndex: "column",
      key: "column",
      // render: text => <a>{text}</a>,
    },
    {
      title: t("Count"),
      dataIndex: "countlv1",
      key: "countlv1"
    },
    {
      title: t("MissingValue"),
      children: [
        {
          title: t("Count"),
          dataIndex: "countlv2",
          key: "countlv2",
          
        }, {
          title: t("MissingRate"), dataIndex: "rate", key: "rate"
        }]
    },
    {
      title: t('Distinct Count'),
      dataIndex: "unique",
      key: "unique",
    },
    {
      title: `1st ${t('Rank')}`,
      children: [
        {
          title: t("Value"),
          dataIndex: "rank1value",
          key: "rank1value",
          
        }, {
          title: t("Count"), dataIndex: "rank1count", key: "rank1count"
        }]
    },
    {
      title: `2nd ${t('Rank')}`,
      children: [
        {
          title: t("Value"),
          dataIndex: "rank2value",
          key: "rank2value",
          
        }, {
          title: t("Count"), dataIndex: "rank2count", key: "rank2count"
        }]
      
    },
    {
      title: `3rd ${t('Rank')}`,
      children: [
        {
          title: t("Value"),
          dataIndex: "rank3value",
          key: "rank3value",
          
        }, {
          title: t("Count"), dataIndex: "rank3count", key: "rank3count"
        }]
    },
    {
      title: `4th ${t('Rank')}`,
      children: [
        {
          title: t("Value"),
          dataIndex: "rank4value",
          key: "rank4value",
          
        }, {
          title: t("Count"), dataIndex: "rank4count", key: "rank4count"
        }]
      
    },
    {
      title: `5th ${t('Rank')}`,
      children: [
        {
          title: t("Value"),
          dataIndex: "rank5value",
          key: "rank5value",
          
        }, {
          title: t("Count"), dataIndex: "rank5count", key: "rank5count"
        }]
    }
  ];
  
  const {state: {data}} = useLocation()
  const [bins, setBins] = useState(10)
  const [dataNumberStatistic, setDataNumberStatistic] = useState({})
  const [dataHistogram, setDataHistogram] = useState({})
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    _getDataDetail({
      data_id: data.data_id,
      column: props.title,
      bins: bins
    })
  }, [props.title])
  
  const onClickBackListData = () => {
    props.backListData(true)
  }
  
  let onClickDraw = () => {
    _getDataDetail({
      data_id: data.data_id,
      column: props.title,
      bins: bins,
      hist: 1
    })
  }
  
  let onChangeColumnNumber = (value) => {
    setBins(value)
  }
  
  const _getDataDetail = async (_query) => {
    setLoading(true)
    const response = await getDataDetail(_query);
    if (response.status === 200) {
      setLoading(false);
      
      if (response.data.Static) {
        setDataNumberStatistic(convertDataViewStringDetail(response.data.Static))
      }
      if (response.data["Histogram"]) {
        setDataHistogram(JSON.parse(response.data["Histogram"]))
      }
      return
    }
    
    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
  }
  
  
  return (
    <div className="statistic_tab">
      <Spin spinning={loading}>
        <div>
          <Button type="primary" onClick={onClickBackListData}>
            <i style={{marginRight: 5}} className="fas fa-chevron-left"/> {t('Back')}
          </Button>
          <div className="title">{props.title}
          </div>
          <div style={{textAlign: "right"}}><InputNumber onChange={onChangeColumnNumber} min={1} defaultValue={10}
                                                         max={100} style={{marginRight: '5px'}}/><Button
            onClick={onClickDraw}
            type="primary">Draw</Button>
          </div>
          <div className="big_chart">
            <Plot
              data={dataHistogram.data}
              layout={dataHistogram.layout}
            />
          </div>
          <div className="title">Statics</div>
          
          <Table dataSource={[dataNumberStatistic]} pagination={false}
                 columns={columnsStringDetail}/>
        </div>
      </Spin>
    </div>
  )
}

export default StatisticsTabStringDetail