import React, {Fragment, useState, useEffect} from 'react';
import {Button, Modal, Form, Input, notification, Icon, DatePicker} from 'antd';
import {t} from 'utils/i18n';
import {onChangeInput, onBlurInput, beforSubmit} from 'utils/Logics';
import {updateTeam} from 'apis/teams';
import moment from "moment";
import {updateInformation} from "apis/informations";

const onOpenModal = setVisible => () => setVisible(true)
const checkRequireField = (value, setValueError) => {
  if (!value) {
    setValueError('This field is require!')
  } else {
    setValueError('')
  }
}
const {TextArea} = Input;
export default (props) => {
  const [visible, setVisible] = useState(false)
  const [message, setMessage] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [date, setDate] = useState(moment(props.record.date));
  const [jpDescription, setJpDescription] = useState(props.record.jpDescription);
  const [jpLink, setJpLink] = useState(props.record.jpLink);
  const [enLink, setEnLink] = useState(props.record.enLink);
  const [enDescription, setEnDescription] = useState(props.record.enDescription);
  const [dateError, setDateError] = useState('');
  const [jpDescriptionError, setJpDescriptionError] = useState('');
  const [jpLinkError, setJpLinkError] = useState('');
  const [enLinkError, setEnLinkError] = useState('');
  const [enDescriptionError, setEnDescriptionError] = useState('');
  useEffect(() => {
    if (visible) {
      setDate(moment(props.record.date));
      setJpDescription(props.record.jpDescription);
      setJpLink(props.record.jpLink);
      setEnLink(props.record.enLink);
      setEnDescription(props.record.enDescription);
    }
  }, [visible])
  
  const onOk = async () => {
    setConfirmLoading(true)
    const body = {
      date,
      jpDescription,
      jpLink,
      enLink,
      enDescription,
    }
    checkRequireField(date, setDateError)
    checkRequireField(enDescription, setEnDescriptionError)
    checkRequireField(jpDescription, setJpDescriptionError)
  
    const response = await updateInformation(body,props.record.id)
    setConfirmLoading(false)
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      if (props.onReload) {
        props.onReload()
      }
      setVisible(false)
    } else {
      notification.error({
        message: t("Error"),
        description: response.data.message
      })
    }
    
    
    
    
  }
  
  const onCancel = () => {
    setConfirmLoading(false)
    setMessage("")
    setVisible(false)
  }
  const onChangeDate = (date, dateString) => {
    setDate(date)
    checkRequireField(date, setDateError)
  }
  const onChangeEnDescription = (e) => {
    setEnDescription(e.target.value)
    checkRequireField(e.target.value, setEnDescriptionError)
  }
  const onChangeEnLink = (e) => {
    setEnLink(e.target.value)
  }
  const onChangeJpDescription = (e) => {
    setJpDescription(e.target.value)
    checkRequireField(e.target.value, setJpDescriptionError)
  }
  const onChangeJpLink = (e) => {
    setJpLink(e.target.value)
  }
  
  return (
    <Fragment>
      {console.log(date)}
      <Button
        type="primary"
        onClick={onOpenModal(setVisible)}
        style={{marginLeft: 10}}
      >
        <Icon type="edit"/>
      </Button>
      <Modal
        cancelText={t("Cancel")}
        title={t("Update Information")}
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        confirmLoading={confirmLoading}
      >
        <Form>
          <Form.Item label={<strong>{t("Date")}</strong>} required help={dateError}>
            <DatePicker value={date} onChange={onChangeDate}/>
          </Form.Item>
          <Form.Item label={<strong>{t("Japanese")}</strong>} required help={jpDescriptionError}>
            <TextArea value={jpDescription} onChange={onChangeJpDescription} rows={4}
                      autoSize={{minRows: 3, maxRows: 6}}/>
          </Form.Item>
          <Form.Item label={<strong>{t("Link")}</strong>} help={jpLinkError}>
            <Input value={jpLink} onChange={onChangeJpLink}/>
          </Form.Item>
          
          <Form.Item label={<strong>{t("English")}</strong>} required help={enDescriptionError}>
            <TextArea value={enDescription} onChange={onChangeEnDescription} rows={4}
                      autoSize={{minRows: 3, maxRows: 6}}/>
          </Form.Item>
          
          <Form.Item label={<strong>{t("Link")}</strong>} help={enLinkError}>
            <Input onChange={onChangeEnLink} value={enLink}/>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}