import React, {useEffect, useState, Fragment, useContext} from 'react';
import moment from 'moment';
import {
  Checkbox,
  Table,
  InputNumber,
  Tabs,
  Slider,
  Tag,
  Divider,
  Steps,
  Select,
  Button,
  notification,
  Icon,
  Row,
  Typography,
  Col,
  Pagination,
  Modal,
  Tooltip, Spin, Input
} from 'antd';

import {useLocation} from 'react-router-dom'
import {checkColor, convertDataViewNumber, convertDataViewString} from 'utils';
// import moment from 'moment';
// import TitleTable from 'components/TitleTable';
// import SelectLabel from 'components/SelectLabel';
// import {getListDatas, deleteData, updateData} from 'apis/datas';
// import {ROUTES, LABELS} from 'consts';
// import {accessDownload} from 'apis/auth';
// import Configs from 'components/ConfigProviders';
// import {useHistory} from 'react-router-dom';
// import SpanEditable from 'components/SpanEditable';
import {splitData} from 'apis/datas';
import Plot from 'react-plotly.js';
import {t} from "utils/i18n"
import DataSheet from "components/DataSheet";

const {TabPane} = Tabs;
const {Option} = Select;
const {Step} = Steps;
const formatter = value => moment(value).format('YYYY/MM/DD');
const SplitTab = (props) => {
  const {state: {data}} = useLocation()
  const [valueRatioRandom, setValueRatioRandom] = useState(0);
  const [num0, setNum0] = useState(0);
  const [num1, setNum1] = useState(0);
  const [loadingStep1, setLoadingStep1] = useState(false);
  const [valueSliderTimeSeries, setValueSliderTimeSeries] = useState(0);
  const [valueColumnCondition, setValueColumnCondition] = useState();
  const [valueLearning, setValueLearning] = useState(`${data.data_name}_L`);
  const [valueLearningTimeSeries, setValueLearningTimeSeries] = useState(`${data.data_name}_L`);
  const [valueLearningCondition, setValueLearningCondition] = useState(`${data.data_name}_L`);
  const [valueForeCasting, setValueForeCasting] = useState(`${data.data_name}_F`);
  const [valueForeCastingTimeSeries, setValueForeCastingTimeSeries] = useState(`${data.data_name}_F`);
  const [valueForeCastingCondition, setValueForeCastingCondition] = useState(`${data.data_name}_F`);
  const [valueMath, setValueMath] = useState(`>=`);
  const [currentStep, setCurrentStep] = useState(0);
  const [valueCondition, setValueCondition] = useState(``);
  const [valueRatio0l, setValueRatio0l] = useState(``);
  const [valueRatio1l, setValueRatio1l] = useState(1);
  const [valueVolume, setValueVolume] = useState(``);
  const [valueRatio0f, setValueRatio0f] = useState(``);
  const [valueRatio1f, setValueRatio1f] = useState(1);
  const [listColumn, setListColumn] = useState([]);
  const [valueForeCastingBinary, setValueForeCastingBinary] = useState(`${data.data_name}_binary_F`);
  const [valueLearningBinary, setValueLearningBinary] = useState(`${data.data_name}_binary_L`);
  const [isHideBinaryTab, setIsHideBinaryTab] = useState(false);
  const [isHideTimeSeriesTab, setIsHideTimeSeriesTab] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadingRandom, setLoadingRandom] = useState(false);
  const [loadingCondition, setLoadingCondition] = useState(false);
  const [loadingTime, setLoadingTime] = useState(false);
  const [loadingBinary, setLoadingBinary] = useState(false);
  const marksRandom = {
    0: '0%',
    100: {
      style: {
        color: '#f50',
      },
      label: <strong>100%</strong>,
    },
  };
  const marksTimeSeries = {
    [Number(startDate)]: moment(startDate).format('YYYY/MM/DD'),
    [Number(endDate)]: {
      style: {
        color: '#f50',
      },
      label: <strong>{moment(endDate).format('YYYY/MM/DD')}</strong>,
    },
  };
  useEffect(() => {
    if (props.dataTabView.start_time && props.dataTabView.end_time) {
      setIsHideTimeSeriesTab(false)
      setStartDate(moment(props.dataTabView.start_time).valueOf())
      setEndDate(moment(props.dataTabView.end_time).valueOf())
    } else {
      setIsHideTimeSeriesTab(true)
    }
    if (props.dataTabView.Number && handleListColumn(props.dataTabView.Number).length > 0) {
      setListColumn(handleListColumn(props.dataTabView.Number))
    }
    getDataTabBinary();
  }, [props.dataTabView])
  let handleListColumn = (data) => {
    let column = data.map((value, index) => {
      return value.column
    })
    return column;
  }
  let onChangeLearning = (e) => {
    setValueLearning(e.target.value)
  }
  let onChangeCurrentStep = (value) => {
    setCurrentStep(value)
  }
  let onChangeLearningCondition = (e) => {
    setValueLearningCondition(e.target.value)
  }
  let onChangeForeCastingBinary = (e) => {
    setValueForeCastingBinary(e.target.value)
  }
  let onChangeLearningBinary = (e) => {
    setValueLearningBinary(e.target.value)
  }
  
  let onChangeLearningTimeSeries = (e) => {
    setValueLearningTimeSeries(e.target.value)
  }
  let onChangeMath = (value) => {
    setValueMath(value)
  }
  let onChangeValueColumnCondition = (value) => {
    setValueColumnCondition(value)
  }
  let onChangeValueRatio0l = (value) => {
    setValueRatio0l(value)
  }
  let onChangeValueRatio1l = (value) => {
    setValueRatio1l(value)
  }
  let onChangeValueVolume = (value) => {
    setValueVolume(value)
  }
  let onChangeValueRatio0f = (value) => {
    setValueRatio0f(value)
  }
  let onChangeValueRatio1f = (value) => {
    setValueRatio1f(value)
  }
  let onChangeValueCondition = (value) => {
    setValueCondition(value)
  }
  let onChangeForeCasting = (e) => {
    setValueForeCasting(e.target.value)
    
  }
  let onChangeForeCastingTimeSeries = (e) => {
    setValueForeCastingTimeSeries(e.target.value)
    
  }
  let onChangeForeCastingCondition = (e) => {
    setValueForeCastingCondition(e.target.value)
    
  }
  let onChangeSliderRandom = (value) => {
    setValueRatioRandom(Number(value) / 100);
  }
  let onChangeSliderTimeSeries = (value) => {
    setValueSliderTimeSeries(Number(value));
  }
  let onClickSplitRandom = async () => {
    setLoadingRandom(true);
    let body = {
      data_id: data.data_id,
      type_split: 'random',
      ratio: valueRatioRandom,
      learning_name: valueLearning,
      forecast_name: valueForeCasting
    }
    
    let response = await splitData(body);
    if (response.status === 200) {
      setLoadingRandom(false);
      notification.success({
        message: t("Success"),
        description: (
          <span>
            {t(response.data.message)}
          </span>
        )
      })
    } else {
      setLoadingRandom(false);
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    
  }
  let onClickSplitCondition = async () => {
    setLoadingCondition(true);
    let body = {
      data_id: data.data_id,
      type_split: 'condition',
      learning_name: valueLearningCondition,
      forecast_name: valueForeCastingCondition,
      condition: valueMath,
      value: valueCondition,
      column: valueColumnCondition
    }
    
    let response = await splitData(body);
    if (response.status === 200) {
      setLoadingCondition(false);
      notification.success({
        message: t("Success"),
        description: (
          <span>
            {t(response.data.message)}
          </span>
        )
      })
    } else {
      setLoadingCondition(false);
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    
  }
  let onClickSplitTimeSeries = async () => {
    setLoadingTime(true);
    let body = {
      data_id: data.data_id,
      type_split: 'timeseries',
      learning_name: valueLearningTimeSeries,
      forecast_name: valueForeCastingTimeSeries,
      timestamp: moment(valueSliderTimeSeries).format('YYYY/MM/DD'),
    }
    
    let response = await splitData(body);
    if (response.status === 200) {
      setLoadingTime(false);
      notification.success({
        message: t("Success"),
        description: (
          <span>
            {t(response.data.message)}
          </span>
        )
      })
    } else {
      setLoadingTime(false);
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    
  }
  let onClickSplitBinary = async () => {
    setLoadingBinary(true);
    if ((Number(valueVolume) > num1) || (Number(valueRatio0f / valueRatio1f) * valueVolume) > num0) {
      setLoadingBinary(false);
      return notification.error({
        message: t("Error"),
        description: t("Sorry, this is didn't work.")
      })
    }
    
    let body = {
      data_id: data.data_id,
      type_split: 'binary',
      learning_name: valueLearningBinary,
      forecast_name: valueForeCastingBinary,
      ratio_0_l: valueRatio0l,
      ratio_1_l: valueRatio1l,
      ratio_0_f: valueRatio0f,
      ratio_1_f: valueRatio1f,
      volumn_f: valueVolume
    }
    
    let response = await splitData(body);
    if (response.status === 200) {
      setLoadingBinary(false);
      notification.success({
        message: t("Success"),
        description: (
          <span>
            {t(response.data.message)}
          </span>
        )
      })
    } else {
      setLoadingBinary(false);
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    
  }
  let getDataTabBinary = async () => {
    setLoadingStep1(true)
    let body = {
      data_id: data.data_id,
      type_split: 'binary',
      learning_name: 'xxx',
      forecast_name: 'xxx',
    }
    
    let response = await splitData(body);
    if (response.status === 200) {
      setLoadingStep1(false)
      setNum0(response.data.num_0)
      setNum1(response.data.num_1)
    } else {
      setLoadingStep1(false)
      setIsHideBinaryTab(true)
    }
    
  }
  
  return (
    <div className="split_tab">
      <Tabs defaultActiveKey="1" tabPosition="left">
        
        <TabPane tab={t('Random')} key='Random'>
          <Spin style={{width: '50%'}} spinning={loadingRandom}>
            
            <Row style={{width: '50%'}}>
              <Slider marks={marksRandom} defaultValue={0} onAfterChange={onChangeSliderRandom}/>
              <Row style={{marginTop: 10}}>
                <Col span={8}>
                  <div>{t('File Name of Learning Data')}</div>
                  <Input value={valueLearning} onChange={onChangeLearning}></Input>
                </Col>
                <Col span={8} offset={8}>
                  <div>{t('File Name of Forecasting Data')}</div>
                  <Input value={valueForeCasting} onChange={onChangeForeCasting}></Input>
                  <div style={{textAlign: 'right', marginTop: '10px'}}><Button type="primary"
                                                                               onClick={onClickSplitRandom}>{t('Split')}</Button>
                  </div>
                </Col>
              </Row>
            </Row>
          </Spin>
        </TabPane>
        <TabPane tab={t('Condition')} key={'Condition'}>
          <Spin style={{width: '50%'}} spinning={loadingCondition}>
            <Row style={{width: '50%'}}>
              <Row style={{marginTop: 10}}>
                <Col span={8}>
                  <div>{t('Column Name')}:</div>
                  <Select style={{width: "60%"}} value={valueColumnCondition} onChange={onChangeValueColumnCondition}>
                    {listColumn && listColumn.map((data, index) => {
                      return <Option value={data}>{data}</Option>
                    })}
                  </Select>
                </Col>
                <Col span={8}>
                  <div style={{height: 22}}>{t('Condition')}:</div>
                  <Select value={valueMath} onChange={onChangeMath}>
                    <Option value=">">{`>`}</Option>
                    <Option value=">=">{`>=`}</Option>
                    <Option value="<"> {`<`} </Option>
                    <Option value="<=">{`<=`}</Option>
                  
                  </Select>
                </Col>
                <Col span={8}>
                  <div>{t('ValueSplit')}:</div>
                  <InputNumber min={1} value={valueCondition} onChange={onChangeValueCondition}/>
                </Col>
              </Row>
            </Row>
            <Row style={{width: '50%'}}>
              <Row style={{marginTop: 10}}>
                <Col span={8}>
                  <div>{t('File Name of Learning Data')}</div>
                  <Input value={valueLearningCondition} onChange={onChangeLearningCondition}/>
                </Col>
                <Col span={8} offset={8}>
                  <div>{t('File Name of Forecasting Data')}</div>
                  <Input value={valueForeCastingCondition} onChange={onChangeForeCastingCondition}/>
                  <div style={{textAlign: 'right', marginTop: '10px'}}><Button type="primary"
                                                                               onClick={onClickSplitCondition}>{t('Split')}</Button>
                  </div>
                </Col>
              </Row>
            </Row>
          </Spin>
        </TabPane>
        <TabPane tab={t('Time Series')} key='Time Series'>
          <Spin style={{width: '50%'}} spinning={loadingTime}>
            {isHideTimeSeriesTab ?
              <div style={{
                fontSize: 28,
                marginTop: 150,
                textAlign: 'center',
              }}>{t('This data isn’t time series data.')}</div> :
              <Row style={{width: '50%'}}>
                <Slider defaultValue={startDate} tipFormatter={formatter} marks={marksTimeSeries} step={86400000}
                        min={startDate} max={endDate} onAfterChange={onChangeSliderTimeSeries}/>
                <Row style={{marginTop: 10}}>
                  <Col span={8}>
                    <div>{t('File Name of Learning Data')}</div>
                    <Input value={valueLearningTimeSeries} onChange={onChangeLearningTimeSeries}/>
                  </Col>
                  <Col span={8} offset={8}>
                    <div>{t('File Name of Forecasting Data')}</div>
                    <Input value={valueForeCastingTimeSeries} onChange={onChangeForeCastingTimeSeries}/>
                    <div style={{textAlign: 'right', marginTop: '10px'}}><Button type="primary"
                                                                                 onClick={onClickSplitTimeSeries}>{t('Split')}</Button>
                    </div>
                  </Col>
                </Row>
              </Row>}
          </Spin>
        </TabPane>
        <TabPane tab={t('Binary Classification')} key='Binary Classification'>
          <Spin style={{width: '50%'}} spinning={loadingBinary}>
            {isHideBinaryTab ?
              <div
                style={{fontSize: 28, marginTop: 150, textAlign: 'center',}}>{t('This data isn’t binary data.')}</div> :
              <Steps current={currentStep} onChange={onChangeCurrentStep} direction="vertical">
                <Step title="Step 1" subTitle={t("Confirm the target data ratio of 0:1.")} description={
                  <Row style={{width: '50%'}}>
                    <Spin spinning={loadingStep1}>
                      <Row style={{textAlign: 'center'}}><Typography.Title level={3}>0 : 1</Typography.Title></Row>
                      <hr style={{width: 190}}/>
                      <Row style={{textAlign: 'center'}}><Typography.Title
                        level={3}>{Math.floor(num0 / num1 * 10) / 10} :
                        1</Typography.Title></Row>
                      <Row style={{textAlign: 'center'}}><Typography.Text>{num0} : {num1}</Typography.Text></Row>
                    </Spin>
                  </Row>
                }/>
                <Step title="Step 2" subTitle={t("Set the ratio of forecasting data.")} description={
                  <Row style={{width: '50%'}}>
                    <Row style={{textAlign: 'center'}}><Typography.Title level={3}>0 : 1</Typography.Title></Row>
                    <hr style={{width: 190}}/>
                    <Row style={{textAlign: 'center'}}><InputNumber min={1} value={valueRatio0f}
                                                                    onChange={onChangeValueRatio0f}/> <span
                      style={{fontWeight: 'bold', fontSize: 25}}>:</span> <InputNumber min={1} value={valueRatio1f}
                                                                                       disabled
                                                                                       onChange={onChangeValueRatio1f}/></Row>
                  </Row>
                }/>
                <Step title="Step 3" subTitle={t('Set the volume of 1 of forecasting data.')} description={
                  <Row style={{width: '50%'}}>
                    <Row style={{textAlign: 'center'}}><InputNumber min={1} max={num1 - 1} value={valueVolume}
                                                                    onChange={onChangeValueVolume}/></Row>
                  </Row>
                }/>
                <Step title="Step 4" subTitle={t("Set distribution ratio of 0:1 of learning data.")} description={
                  <Row style={{width: '50%'}}>
                    <Row style={{textAlign: 'center'}}><Typography.Title level={3}>0 : 1</Typography.Title></Row>
                    <hr style={{width: 190}}/>
                    <Row style={{textAlign: 'center'}}><InputNumber min={1} value={valueRatio0l}
                                                                    max={(num0 - (valueRatio0f * valueVolume)) / (num1 - valueVolume)}
                                                                    onChange={onChangeValueRatio0l}/> <span
                      style={{fontWeight: 'bold', fontSize: 25}}>:</span> <InputNumber min={1} value={valueRatio1l}
                                                                                       disabled
                                                                                       onChange={onChangeValueRatio1l}/></Row>
                    <div style={{textAlign: 'right', marginTop: '10px'}}>
                    </div>
                  </Row>
                }/>
                <Step title="Step 5" subTitle={t("Define file name.")} description={
                  <Row style={{width: '50%'}}>
                    <Row style={{marginTop: 10}}>
                      <Col span={8}>
                        <div>{t('File Name of Learning Data')}</div>
                        <Input value={valueLearningBinary} onChange={onChangeLearningBinary}/>
                      </Col>
                      <Col span={8} offset={8}>
                        <div>{t('File Name of Forecasting Data')}</div>
                        <Input value={valueForeCastingBinary} onChange={onChangeForeCastingBinary}/>
                        <div style={{textAlign: 'right', marginTop: '10px'}}><Button type="primary"
                                                                                     onClick={onClickSplitBinary}>{t('Split')}</Button>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                }/>
              </Steps>}
          </Spin>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default SplitTab