import { post, get, destroy, patch } from "utils/FetchAPI";

export const createProject = (data) => post("/projects", { ...data, team_id: localStorage.getItem("currentTeamId") });

export const getListProjects = (query = {}) => get("/projects", { ...query, team_id: localStorage.getItem("currentTeamId") });
export const searchProjects = (query) => get('/projects/search', { ...query, team_id: localStorage.getItem("currentTeamId") })

export const deleteProject = (id) => destroy("/projects", {
  project_id: id,
  team_id: localStorage.getItem("currentTeamId")
})

export const updateProject = (data) => patch("/projects", { ...data, team_id: localStorage.getItem("currentTeamId") })