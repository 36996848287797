import React, {useState, useEffect, Fragment} from 'react';
import {Row, Form, Input, Select, Checkbox, Button, notification, Spin} from 'antd';
import {t} from 'utils/i18n';
import {WEATER_DATA_LABEL, PROVINCES_DISTRICTS, PROVINCE_PARAMS, DISTRIC_PARAMS, DETAIL_TABS} from 'consts'
import {useLocation} from 'react-router-dom';
import {weather} from 'apis/datas';
import moment from 'moment';

const layout = {
  labelCol: {
    span: 4,
    align: "left"
  },
  wrapperCol: {
    span: 20
  },
  labelAlign: "left"
}

export default (props) => {
  const language = localStorage.getItem("lang")
  const {state: {data}} = useLocation()
  
  const [weatherDatas, setWeaterDatas] = useState([])
  const [date_col, setDateCol] = useState("")
  const [location, setLocation] = useState({})
  const [save_as, setSaveAs] = useState(data.data_name + "_" + t(DETAIL_TABS.WEATHER) + "_" + moment().format("YYYY-MM-DD_HH:mm"))
  const [messageDateCol, setMessageDateCol] = useState("")
  const [messageLocation, setMessageLocation] = useState("")
  const [messageSaveAs, setMessageSaveAs] = useState("")
  const [loading, setLoading] = useState(false)
  const [saveAsLoading, setLoadingSaveAs] = useState(false)
  
  useEffect(() => {
    return () => {
      setMessageDateCol("")
      setMessageLocation("")
      setWeaterDatas([])
      setLocation({})
      setDateCol("")
      setSaveAs(data.data_name + "_" + t(DETAIL_TABS.WEATHER) + "_" + moment().format("YYYY-MM-DD_HH:mm"))
    }
  }, [props.activeKey])
  
  const _onChangeWeatherDatas = (label) => () => {
    const _weatherDatas = [...weatherDatas];
    if (weatherDatas.includes(label)) {
      _weatherDatas.splice(weatherDatas.indexOf(label), 1)
    } else {
      _weatherDatas.push(label)
    }
    setWeaterDatas(_weatherDatas)
  }
  
  const _onChangeLocation = (value) => {
    setLocation(JSON.parse(value))
    setWeaterDatas([])
    setMessageLocation("")
  }
  
  const _onChangeTimeData = (value) => {
    setDateCol(value)
    setMessageDateCol("")
  }
  
  const _onChangeSaveAs = ({target: {value}}) => {
    setSaveAs(value || "")
    setMessageSaveAs("")
  }
  
  const _validate = (body) => {
    let flag = false
    if (!body.date_col) {
      setMessageDateCol(t("Please input %1", t("Time Data")))
      flag = true
    }
    if (!body.block_no || !body.prec_no) {
      setMessageLocation(t("Please input %1", t("Location")))
      flag = true
    }
    if (body.save_as !== null && body.save_as !== undefined && body.save_as.trim() === "") {
      setMessageSaveAs(t("Please input %1", t("Data Name")))
      flag = true
    } else {
      setMessageSaveAs("")
    }
    if (flag) {
      return false
    }
    return true
  }
  
  const _onSubmit = (body, _setLoading) => async () => {
    if (!_validate(body)) {
      return
    }
    _setLoading(true)
    const response = await weather(body);
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
    } else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    _setLoading(false)
  }
  
  const _checkAll = (values) => () => {
    const _weatherDatas = [...weatherDatas];
    values.map(label => {
      if (!weatherDatas.includes(label)) {
        _weatherDatas.push(label)
      }
    })
    setWeaterDatas(_weatherDatas)
  }
  
  const _clearAll = (values) => () => {
    setWeaterDatas([])
  }
  
  return (
    <Row>
      <Spin spinning={loading||saveAsLoading}>
        <Form {...layout}>
          <Form.Item label={<strong>{t("Time Data")}</strong>} help={messageDateCol}>
            <div style={{width: 300}}>
              <Select
                showSearch
                onChange={_onChangeTimeData}
                value={date_col}
              >
                {
                  props.dataTabView.columns instanceof Array && props.dataTabView.columns.map((data, index) => (
                    <Select.Option value={data}>{data}</Select.Option>
                  ))
                }
              </Select>
            </div>
          </Form.Item>
          <Form.Item label={<strong>{t("Location")}</strong>} help={messageLocation}>
            <div style={{width: 300}}>
              <Select
                showSearch
                onChange={_onChangeLocation}
                value={Object.keys(location).length > 0 && JSON.stringify(location)}
              >
                {
                  PROVINCES_DISTRICTS.map((data, index) => (
                    <Select.Option key={index}
                                   value={JSON.stringify(data)}>{language === "en" ? data.label_en : data.label_jp}</Select.Option>
                  ))
                }
              </Select>
            </div>
          </Form.Item>
          <Form.Item label={<div style={{display: "inline-block", height: 65}}><strong>{t("Weather Data")}</strong><span
            style={{position: "absolute", left: 0, top: 20}}>(Daily Data Only)</span></div>}>
            {
              Object.keys(location).length > 0 && (location.is_main_station === true ? PROVINCE_PARAMS : DISTRIC_PARAMS).map((label, index) => (
                <Row key={index}>
                  <Checkbox onChange={_onChangeWeatherDatas(label)} checked={weatherDatas.includes(label)}>
                    {t(label)}
                  </Checkbox>
                </Row>
              ))
            }
            {
              Object.keys(location).length > 0 && <Row type="flex">
                <Button type="link" size="small" style={{padding: 0}}
                        onClick={_checkAll(location.is_main_station === true ? PROVINCE_PARAMS : DISTRIC_PARAMS)}>
                  {t("Check all")}
                </Button>
                <span style={{paddingLeft: 10, paddingRight: 10, lineHeight: 1.3}}>|</span>
                <Button type="link" size="small" style={{padding: 0}}
                        onClick={_clearAll(location.is_main_station === true ? PROVINCE_PARAMS : DISTRIC_PARAMS)}>
                  {t("Clear all")}
                </Button>
              </Row>
            }
          </Form.Item>
          
          <Row style={{textAlign: 'right', marginTop: '10px'}} type="flex" justify="end">
            <Row type='flex' justify='space-between' style={{width: "35%"}} align="center">
              <span style={{lineHeight: '31px', width: '27%', marginRight: 5}}>{t('Data name')}:</span>
              <Input style={{width: "70%"}} value={save_as} onChange={_onChangeSaveAs}/>
            </Row>
            <Button style={{marginLeft: 5}} type="primary" loading={saveAsLoading} onClick={_onSubmit({
              lang: localStorage.getItem('lang'),
              date_col,
              prec_no: location.prec_no,
              block_no: location.block_no,
              data_id: data.data_id,
              save_as,
              weather_cols: weatherDatas,
              project_id: data.project_id
            }, setLoadingSaveAs)}>
              {t('Save As')}
            </Button>
            
            <Button style={{marginLeft: 30}} type="primary" loading={loading} onClick={_onSubmit({
              lang: localStorage.getItem('lang'),
              date_col,
              prec_no: location.prec_no,
              block_no: location.block_no,
              data_id: data.data_id,
              weather_cols: weatherDatas,
              project_id: data.project_id
            }, setLoading)}>
              {t('Save')}
            </Button>
          
          </Row>
        
        
        </Form>
      </Spin>
    </Row>
  )
}
