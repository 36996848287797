import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import PapaParse from 'papaparse'

import './style.less'
import { Button, Checkbox, Input, Pagination, Row } from 'antd';
import { t } from 'utils/i18n';
import __ from "lodash";

const ITEM_PER_PAGE = 100;

const CandidateInput = ({ value, onChange, errorMessage, style, ...rest }) => {
  const [candidateValue, setCandidateValue] = useState(null)

  useEffect(() => {
    setCandidateValue(value)
  }, [value, setCandidateValue])

  const handleInputChange = (e) => {
    setCandidateValue(e.target.value)
    if (onChange) {
      onChange(e)
    }
  }
  let className = "candidate-input";
  if (errorMessage) {
    className += " has-error";
    style = Object.assign({}, style || {}, { "--border-color": "red" })
  }

  return <Input className={className}
                value={candidateValue}
                onChange={handleInputChange}
                allowClear
                style={style} {...rest} />
}

const INVALID_CHAR_REGEX = /[!"#$%&'*+, \-.\\/:;<=>?@\[\]^`{|}~]/;

const focusFirstErrorInput = () => {
  const $input = document.querySelector(".candidate-input.has-error");
  if ($input) {
    $input.scrollIntoView(true);
  }
};

/**
 *
 * @param {Object} props
 * @param ref
 * @param {String} props.csv
 */
const DataSheet = (props, ref) => {
  const editable = props.editable
  const checkbox = props.checkbox
  const [page, setPage] = useState(1)
  const [dataColumn, setDataColumn] = useState([])
  const [dataSource, setDataSource] = useState({ data: [] })
  const [errors, setErrors] = useState({})
  useEffect(() => {
    if (!props.csv) {
      setDataSource({ data: [] })
      return
    }
    const data = PapaParse.parse(props.csv)
    data.data.pop()
    setDataSource(data)
  }, [props.csv])

  useEffect(() => {
    if (checkbox && props.getListColumn && dataSource.data.length > 0) {
      onClickSelectAll();
    }
  }, [dataSource, checkbox])

  const maxX = useMemo(() => {
    if (dataSource.data.length > 1) {
      return dataSource.data.reduce((prev, cur, index) => {
        return Number(prev.length) ? prev.length > cur.length ? prev.length : cur.length : prev > cur.length ? prev : cur.length
      })
    }
    if (dataSource.data[0] instanceof Array) {
      return dataSource.data[0].length
    }
    return 0
  }, [dataSource])

  const onClickSelectAll = () => {
    const clone = __.cloneDeep(dataSource.data[0])
    setDataColumn(clone)
    props.getListColumn(clone)
  }

  const onClickClearAll = () => {
    setDataColumn([...dataSource.data[0][0]])
    props.getListColumn([...dataSource.data[0][0]])
  }

  useImperativeHandle(ref, () => {
    return {
      columns() {
        return maxX
      },
      addColumn(before = -1) {
        const data = dataSource.data.map(row => {
          if (before < 0) {
            return [...row, null]
          }
          return [...row.slice(0, before), '', ...row.slice(before)]
        })
        setDataSource({
          ...dataSource,
          data
        })
        setTimeout(() => {
          const element = document.querySelector(".data_sheet")
          element.scrollTo(element.scrollWidth, 0)
        })
      },
      removeColumn(col) {
        let row = dataSource.data[0];
        if (!row) {
          return;
        }
        if (col < 0 || col >= row.length) {
          return;
        }
        const data = dataSource.data.map(row => {
          return [...row.slice(0, col), ...row.slice(col + 1)]
        })
        setDataSource({
          ...dataSource,
          data
        })
      },
      toCSV(checkError = false, errorOut = true) {
        try {
          if (checkError) {
            const data = dataSource.data[0];
            const errors = {};
            for (let i = 0; i < data.length; i++) {
              if (!data[i] || data[i] === "" || data[i].search(INVALID_CHAR_REGEX) >= 0) {
                errors[`0-${i}`] = true;
              }
            }
            setErrors(errors)
            if (Object.keys(errors).length > 0) {
              setTimeout(focusFirstErrorInput, 200);
              throw {
                data: {
                  message: "列名は空であるか不正な記号が含まれています。例： [!\"#$%&'*+, -.\\/:;<=>?@[]^`{|}~]+"
                }
              };
            }
          } else {
            setErrors({})
          }
          return PapaParse.unparse(dataSource);
        } catch (e) {
          if (errorOut) {
            throw e;
          }
        }
      }
    }
  })

  const onChangeCheckboxTitle = (e) => {
    if (e.target.checked) {
      dataColumn.push(e.target.value)
      props.getListColumn(dataColumn)
      setDataColumn(dataColumn)
    } else {
      let i = dataColumn.indexOf(e.target.value);
      dataColumn.splice(i, 1);
      props.getListColumn(dataColumn)
      setDataColumn(dataColumn)
    }
  }

  const getLabelColumn = (index) => {
    if (index <= 25) {
      return String.fromCharCode(65 + index);
    }
    const div = Math.floor(index / 26)
    const mod = index % 26
    return getLabelColumn(div - 1) + getLabelColumn(mod)
  }

  const handleRemoveColumn = (col) => () => {
    ref.current.removeColumn(col);
  };

  const handleInputChange = (row, index) => {
    return (e) => {
      // row[index] = e.target.value
      dataSource.data[(page - 1) * ITEM_PER_PAGE + row][index] = e.target.value
      setDataSource({ data: [...dataSource.data] })
    }
  }

  const inputErrorMessage = (row, col) => {
    if (row !== 0) {
      // console.log(row, null)
      return;
    }
    return errors[`${row}-${col}`];
  }

  return (
    <Fragment>
      {props.checkbox ?
        <div style={{ marginBottom: 20 }}><Button onClick={onClickSelectAll} type='link'>{t("Select All")}</Button>
          <Button onClick={onClickClearAll} type='link'>{t("Clear All")}</Button></div> : null}
      <div className="data_sheet" style={{ maxHeight: props.maxHeight || "60vh" }}>
        <table>
          <thead>
          <tr>
            <th style={{ zIndex: 1 }}></th>
            {
              Array.from(Array(maxX).keys()).map((value, index) => (
                <th key={index} style={{ zIndex: 1 }}>
                  <strong>
                    {getLabelColumn(index)}
                  </strong>
                </th>
              ))
            }
          </tr>
          </thead>
          <tbody>
          {
            dataSource.data.slice((page - 1) * ITEM_PER_PAGE, page * ITEM_PER_PAGE).map((row, index) => (
              <tr key={`${index}_row`}>
                <td key={`${index}_first_column`} style={{ zIndex: 1 }}>
                  <strong>{(page - 1) * ITEM_PER_PAGE + index + 1}</strong>
                </td>
                {
                  Array.from(Array(maxX).keys()).map((value, indexCol) => (
                    <td key={`${index}_${indexCol}_column`}>
                      <Row type="flex" justify="space-between">
                        {editable
                          ? <CandidateInput value={row[indexCol]}
                                            required={index === 0 && page === 1}
                                            errorMessage={inputErrorMessage((page - 1) * ITEM_PER_PAGE + index, indexCol)}
                                            onChange={handleInputChange(index, indexCol)}/>
                          : row[indexCol]
                        }
                        {(index === 0 && props.checkbox && page === 1) ? (compareString(row[indexCol], 'id') ?
                          <Checkbox disabled={true} checked={true} value={row[indexCol]}/> :
                          <Checkbox checked={dataColumn.includes(row[indexCol])}
                                    onChange={onChangeCheckboxTitle}
                                    value={row[indexCol]}/>) : null}
                      </Row>
                    </td>
                  ))
                }
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
      <Row type="flex" justify="end" style={{ paddingTop: 5 }}>
        <Pagination size="small" showQuickJumper total={dataSource.data.length} pageSize={100} current={page}
                    onChange={(page) => setPage(page)}/>
      </Row>
    </Fragment>
  )
}

function compareString(str1, str2) {
  if (!str1 && !str2) {
    return true;
  }
  if (!str1 || !str2) {
    return false;
  }
  return str1.toLowerCase() === str2.toLowerCase();
}

DataSheet.defaultProps = {
  csv: `ID,Name,Label1,Label2,Label3,Label4\n1,test,1,2,3,4\n2,test,1,2,3,4\n3,test,1,2,3,4\n4,test,1,2,3,4\n5,test,1,2,3,4`
}

export default forwardRef(DataSheet)
