import React, { Suspense, useState, useEffect } from 'react';
import ant, { notification } from 'antd';

import Routers from './Routers';
import LoadingPage from 'views/LoadingPage';

import ja_JP from 'antd/es/locale/ja_JP'
import en_US from 'antd/es/locale/en_US'
import { ConfigProvider } from 'components/ConfigProviders';
import { decode } from 'utils';
import { getNewToken } from 'utils/FetchAPI';
import { setLang, t } from 'utils/i18n';
import { getList } from 'apis/teams';
import { ROLES } from 'consts';
import moment from 'moment';
import 'moment/locale/es-us'
import 'moment/locale/ja'

const AntConfig = ant.ConfigProvider;

function App() {
  const [payload, setPayload] = useState({})
  const [lang, setNewLang] = useState(localStorage.getItem("lang") === "en" ? "日本語" : "English")
  const [listTeam, setListTeam] = useState([]);
  const [team, setTeam] = useState({})

  const checkBrowser = () => {
    
    var Browser = navigator.userAgent;
    if (Browser.indexOf('MSIE') >= 0) {
      alert(t("IE is not allowed please use Chrome!"))
      throw new Error('IE is not allowed please use Chrome!');
    }
    if (/*@cc_on!@*/false || !!document.documentMode) {
      alert(t("IE is not allowed please use Chrome!"))
      throw new Error('IE is not allowed please use Chrome!');
    }
  }

  const getListTeam = async (query, token) => {
    const response = await getList(query, token);
    
    if (response.status === 200) {
      setListTeam(response.data.teams)
      if (response.data.teams && response.data.teams.length > 0) {
        
        const currentTeamId = Number(localStorage.getItem("currentTeamId"))
        if (currentTeamId && response.data.teams.map(t => t.id).includes(currentTeamId)) {
          _onSetTeam(response.data.teams.filter(t => t.id === currentTeamId).pop())
        }
        else {
          _onSetTeam(response.data.teams[0])
        }
      }
    
      else {
        
        setTimeout(() => {
          if (team !== null && decode(localStorage.getItem("accessToken")).role !== ROLES.ADMIN) {
            notification.warning({
              message: "Warning",
              description: t("You aren't join any teams, please contact to admin")
            })
            setTeam(null)
          }
        }, 1000);
      }
    }
  }

  const _onSetTeam = (team) => {
    setTeam(team)
    localStorage.setItem("currentTeamId", team.id)
  }

  const _onToogleLang = (newLang) => {
    if (localStorage.getItem("lang") === "ja") {
      setLang("en")
      moment.locale('en')
      setNewLang("日本語")
      localStorage.setItem("lang", "en")
    }
    else {
      setLang("ja")
      moment.locale('ja')
      setNewLang("English")
      localStorage.setItem("lang", "ja")
    }
  }

  const _onSetLanguageDefault = () => {
    if (localStorage.getItem("lang") === "en") {
      setLang("en")
      setNewLang("日本語")
      moment.locale('en')
      localStorage.setItem("lang", "en")
    }
    else {
      setLang("ja")
      setNewLang("English")
      moment.locale('ja')
      localStorage.setItem("lang", "ja")
    }
  }

  useEffect(() => {
    checkBrowser()
    const accessToken = decode(localStorage.getItem("accessToken"));
    if (accessToken.exp < Math.floor(Date.now() / 1000)) {
      getNewToken()
        .then(response => {
          if (response.status === 200) {
            return localStorage.setItem("accessToken", response.data.accessToken)
          }
          localStorage.removeItem("accessToken")
          localStorage.removeItem("refreshToken")
          localStorage.removeItem("fullname")
          localStorage.removeItem("currentTeamId")
          window.location.reload();
        })
        .catch(error => {
          localStorage.removeItem("accessToken")
          localStorage.removeItem("refreshToken")
          localStorage.removeItem("fullname")
          localStorage.removeItem("currentTeamId");
          window.location.reload()
        })
    }
    else if (!localStorage.getItem("currentTeamId") || team !== null) {
      if (accessToken) {
        getListTeam({limit: "unlimited"})
      }
    }

    _onSetLanguageDefault()
  }, [])

  return (
    <Suspense fallback={<LoadingPage />}>
      <AntConfig locale={lang !== "English" ? en_US : ja_JP}>
        <ConfigProvider
          value={{
            payload,
            setPayload,
            lang,
            _onToogleLang,
            team,
            _onSetTeam,
            listTeam,
            getListTeam
          }}
        >
          <Routers />
        </ConfigProvider>
      </AntConfig>
    </Suspense>
  );
}

export default App;
