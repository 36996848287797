import React, { useState, useEffect } from 'react';
import BaseLayout from 'components/BaseLayout';
import { Row, Typography, Form, Input, Button, notification } from 'antd';
import { t } from 'utils/i18n';

import './style.less'
import { onChangeInput, onBlurInput, beforSubmit } from 'utils/Logics';
import { forgotPassword } from 'apis/auth';

export default (props) => {
  const [email, setEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (emailMessage.trim() !== "") {
      setDisabled(true)
    }
    else {
      setDisabled(false)
    }
  }, [emailMessage])

  const onSubmit = (data) => async () => {
    const body = beforSubmit(data, setDisabled);
    if (!body) {
      return
    }
    setLoading(true)
    const response = await forgotPassword(body);
    setLoading(false)

    if (response.status === 200) {
      notification.success({
        message: t("Success"),
        description: (
          <span>
            {t("We have e-mailed your reset password link. ")}
            <a href="/login">{t("Click here")}</a>
            {t(" to go back log in screen")}
          </span>
        )
      })
    }
    else {
      notification.error({
        message: t("Error"),
        description: t("User not found")
      })
    }
  }

  return (
    <BaseLayout hideMenu={true} className="forgot_password_page public_page">
      <div>
        <Row type="flex" justify="center">
          <Typography.Title style={{fontSize: 28}}>
            {t("Forget Your Password")}
          </Typography.Title>
        </Row>
        <Form>
          <Form.Item help={emailMessage}>
            <Input
              autoFocus
              placeholder={t("email")}
              value={email}
              onChange={onChangeInput(setEmail, setEmailMessage)}
              onBlur={onBlurInput(email, setEmailMessage, 'Email')}
              onPressEnter={onSubmit({
                email: {
                  value: email,
                  setMessage: setEmailMessage,
                  title: "Email"
                }
              }, setDisabled)}
            />
          </Form.Item>
          <Form.Item>
            <Row type="flex" justify="center">
              <Button
                type="primary"
                disabled={disabled}
                loading={loading}
                onClick={onSubmit({
                  email: {
                    value: email,
                    setMessage: setEmailMessage
                  }
                })}
              >
                {t("Send")}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </BaseLayout>
  )
}