import React, { useEffect, useState } from 'react';
import BaseLayout from 'components/BaseLayout';
import { useLocation, useHistory } from 'react-router-dom';
import querystring from 'querystring'
import { ROUTES } from 'consts';
import { decode } from 'utils';
import { Row, Typography, Form, Input, Button, notification } from 'antd';
import { t } from 'utils/i18n';
import { onChangeInput, onBlurInput, beforSubmit } from 'utils/Logics';
import './style.less'
import { resetPassword } from 'apis/auth';

export default (props) => {
  const location = useLocation();
  const history = useHistory();
  const [token, setToken] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [message, setMessage] = useState({})
  const [disable, setDisable] = useState(false)

  const _setMessage = (field) => value => setMessage({ ...message, [field]: value })

  useEffect(() => {
    const query = querystring.parse(location.search.slice(1));
    if (!query.token) {
      return history.push(ROUTES.LOGIN)
    }
    if (decode(query.token)) {
      setToken(query.token)
    }
    else {
      setToken(null)
    }
  }, [])

  const _onSubmitResetPassword = async () => {
    const body = beforSubmit({
      token: {
        value: token
      },
      newPassword: {
        value: newPassword,
        setMessage: _setMessage("newPassword"),
        title: "New password"
      },
      confirmPassword: {
        value: confirmPassword,
        setMessage: _setMessage("confirmPassword"),
        title: "Confirm password"
      }
    }, setDisable)
    if (!body) {
      return
    }
    const response = await resetPassword(body);
    if (response.status === 200) {
      notification.success({
        message: t("Success"),
        description: t("Reset password success, wait 3s to go back home screen")
      })
      setTimeout(() => {
        history.push(ROUTES.LOGIN)
      }, 3000);
    }
    else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  return (
    <BaseLayout hideMenu={true} className="reset_password_page public_page">
      <div>
        <Row type="flex" justify="center">
          <Typography.Title style={{ fontSize: 28 }}>
            {t("Reset Your Password")}
          </Typography.Title>
        </Row>
        <Form>
          <Form.Item help={message.newPassword}>
            <Input.Password
              autoFocus
              placeholder={t("New password")}
              value={newPassword}
              onChange={onChangeInput(setNewPassword, _setMessage("newPassword"))}
              onBlur={onBlurInput(newPassword, _setMessage("newPassword"), 'New Password')}
              maxLength={100}
            />
          </Form.Item>
          <Form.Item help={message.confirmPassword}>
            <Input.Password
              placeholder={t("New password")}
              value={confirmPassword}
              onChange={onChangeInput(setConfirmPassword, _setMessage("confirmPassword"))}
              onBlur={onBlurInput(confirmPassword, _setMessage("confirmPassword"), 'Confirm password')}
              maxLength={100}
            />
          </Form.Item>
          <Form.Item>
            <Row type="flex" justify="center">
              <Button
                type="primary"
                onClick={_onSubmitResetPassword}
              >
                {t("Save new password")}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </BaseLayout>
  )
}