import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Divider, Typography, Avatar, Icon, notification, Spin } from 'antd';

import { t } from 'utils/i18n';
import BaseLayout from 'components/BaseLayout';
import { getOtherUserInfomation, uploadAvatar } from 'apis/users';

import './style.less'

export default (props) => {
  const params = useParams()
  const history = useHistory()
  const [user, setUser] = useState({})
  const [spining, setSpining] = useState(true)

  if (!params.teamId || !params.userId) {
    history.goBack()
  }

  useEffect(() => {
    getUserInfomation(params.teamId, params.userId)
  }, [])

  const getUserInfomation = async (teamId, userId) => {
    const response = await getOtherUserInfomation(teamId, userId)
    setSpining(false)
    if (response.status === 200) {
      setUser(response.data)
      return
    }
    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
    return history.goBack()
  }

  const _getAvatar = () => {
    if (!user.avatar) {
      return {
        icon: <Icon type="user" />
      }
    }
    if (user.avatar === 'loading') {
      return {
        icon: <Icon type="loading" spin />
      }
    }
    return {
      src: process.env[`REACT_APP_BACKEND_${(process.env.NODE_ENV !== 'production' ? "DEV" : "PROD")}`] + `/users/avatar/${user.avatar || ""}`
    }
  }

  return (
    <BaseLayout hideMenu={false} className="user_profile">
      <Spin tip={t("Loading")} spinning={spining}>
        <Row gutter={[30, 0]}>
          <Col className="min_col">
            <Row className="custom_row">
              <Typography.Title level={4}>
                {t("Profile")}
              </Typography.Title>
              <Divider />
              <Row style={{ marginBottom: 12 }}>
                <Col span={5}>
                  <Avatar
                    shape="square"
                    size={70}
                    {..._getAvatar()}
                  />
                </Col>
                <Col span={19}>
                  <div>{user.fullname}</div>
                  <div>{user.email} </div>
                </Col>
              </Row>
              <Row>
                <Col span={5}>
                  <span>{t("Company")}</span>
                </Col >
                <Col span={19}>
                  : <span>{user.company}</span>
                </Col>
              </Row>
              <Row>
                <Col span={5}>
                  <span>{t("Position")}</span>
                </Col>
                <Col span={19}>
                  : <span>{user.position}</span>
                </Col>
              </Row>
              <Row>
                <Col span={5}>

                </Col>
                <Col>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Spin>
    </BaseLayout>
  )
}