import React, {useEffect, useState, Fragment, useContext} from 'react';
import {Table, Tag, Select, Button, notification, Icon, Row, Pagination, Modal, Tooltip} from 'antd';
// import {t} from 'utils/i18n';
import {convertDataViewNumber, convertDataViewString} from 'utils';
// import moment from 'moment';
// import TitleTable from 'components/TitleTable';
// import SelectLabel from 'components/SelectLabel';
import {getDataDetail} from 'apis/datas';
// import {ROUTES, LABELS} from 'consts';
// import {accessDownload} from 'apis/auth';
// import Configs from 'components/ConfigProviders';
// import {useHistory} from 'react-router-dom';
// import SpanEditable from 'components/SpanEditable';
import _ from 'lodash';
import Plot from 'react-plotly.js';
import {t} from "utils/i18n"
import StatisticsTabDetail from "./StatisticsTabDetail";
import StatisticsTabStringDetail from "./StatisticsTabStringDetail";
// const {Column, ColumnGroup} = Table;

/**
 *
 * @param {Object} props
 * @param {String} props.label
 */
const StatisticsTab = (props) => {
  const [dataNumber, setDataNumber] = useState(convertDataViewNumber(props.dataTabView.Number || []));
  const dataNumberTarget = convertDataViewNumber(props.dataTabView.Number && props.dataTabView.Number.filter(record => record.column.toLowerCase() === 'target') || []);
  const dataNumberId = convertDataViewNumber(props.dataTabView.Number && props.dataTabView.Number.filter(record => record.column.toLowerCase() === 'id') || []);
  const [dataStrings, setDataStrings] = useState(convertDataViewString(props.dataTabView.Strings || []));
  
  const dataStringsTarget = convertDataViewString(props.dataTabView.Strings && props.dataTabView.Strings.filter(record => record.column.toLowerCase() === 'target') || []);
  const dataStringsId = convertDataViewString(props.dataTabView.Strings && props.dataTabView.Strings.filter(record => record.column.toLowerCase() === 'id') || []);
  const [title, setTitle] = useState('');
  const [isString, setIsString] = useState(false)
  
  
  useEffect(() => {
    if (props.isReset) {
      setTitle('')
    }
  }, [props.isReset])
  
  useEffect(() => {
    if (props.dataTabView.Number) {
      let dataNumberSubtract = convertDataViewNumber(props.dataTabView.Number)
      _.remove(dataNumberSubtract, obj => ["ID", "id", "Id", "Target", "target", "TARGET"].includes(obj.column));
      setDataNumber(dataNumberSubtract)
    }
    if (props.dataTabView.Strings) {
      let dataStringsSubtract = convertDataViewString(props.dataTabView.Strings)
      _.remove(dataStringsSubtract, obj => ["ID", "id", "Id", "Target", "target", "TARGET"].includes(obj.column));
      setDataStrings(dataStringsSubtract)
    }
  }, [props.dataTabView])
  
  
  let onClickTitleData = (text, isString) => {
    setTitle(text);
    setIsString(isString);
  }
  let backListData = (isBack) => {
    if (isBack) {
      setTitle('');
    }
  }
  const columnsNumber = [
    {
      title: "",
      dataIndex: "column",
      key: "column",
      render: text => <a onClick={() => onClickTitleData(text, false)}>{text}</a>,
    },
    {
      title: t('Count'),
      dataIndex: "countlv1",
      key: "countlv1",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: t('MissingValue'),
      children: [
        {
          title: t('Count'),
          dataIndex: "countlv2",
          key: "countlv2",
          
        }, {
          title: t('MissingRate'), dataIndex: "rate", key: "rate"
        }]
    },
    {
      title: t('Unique Count'),
      dataIndex: "unique",
      key: "unique",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: t("Histogram"),
      dataIndex: 'histogram',
      render: data => {
        return <Plot
          zoomEnabled={false}
          
          data={data.data}
          layout={Object.assign({}, data.layout, {
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)',
            showlegend: false,
            displayModeBar: false,
            yaxis: {fixedrange: true},
            xaxis: {fixedrange: true},
            width: 150, height: 90, margin: {
              l: 0,
              r: 20,
              b: 0,
              t: 0
            }
          })}
        />
      },
    },
    {
      title: t("Mean"),
      dataIndex: "mean",
      key: "mean",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: t("Median"),
      dataIndex: "median",
      key: "median",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: t("Mode"),
      dataIndex: "mode",
      key: "mode",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: t("SD"),
      dataIndex: "sd",
      key: "sd",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: "-2D",
      dataIndex: "minus2d",
      key: "minus2d",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: "+2D",
      dataIndex: "plus2d",
      key: "plus2d",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: "25%",
      dataIndex: "25",
      key: "25",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: "50%",
      dataIndex: "50",
      key: "50",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: "75%",
      dataIndex: "75",
      key: "75",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: t("MAX"),
      dataIndex: "max",
      key: "max",
      render: text => {
        return <span>{text}</span>
      }
    },
    {
      title: t("MIN"),
      dataIndex: "min",
      key: "min",
      render: text => {
        return <span>{text}</span>
      }
    }
  ];
  const columnsStrings = [
    {
      title: "",
      dataIndex: "column",
      key: "column",
      render: text => <a onClick={() => onClickTitleData(text, true)}>{text}</a>,
    },
    {
      title: t("Count"),
      dataIndex: "countlv1",
      key: "countlv1"
    },
    {
      title: t("MissingValue"),
      children: [
        {
          title: t("Count"),
          dataIndex: "countlv2",
          key: "countlv2",
          
        }, {
          title: t("MissingRate"), dataIndex: "rate", key: "rate"
        }]
    },
    {
      title: t("Histogram"),
      dataIndex: 'histogram',
      render: data => <Plot
        zoomEnabled={false}
        data={data.data}
        layout={Object.assign({}, data.layout, {
          yaxis: {fixedrange: true},
          xaxis: {fixedrange: true},
          showlegend: false,
          paper_bgcolor: 'rgba(0,0,0,0)',
          plot_bgcolor: 'rgba(0,0,0,0)',
          width: 150, height: 90, margin: {
            l: 0,
            r: 20,
            b: 0,
            t: 0
          }
        })}
      />
    },
    {
      title: t('Distinct Count'),
      dataIndex: "unique",
      key: "unique",
    },
    {
      title: `1st ${t('Rank')}`,
      children: [
        {
          title: t("Value"),
          dataIndex: "rank1value",
          key: "rank1value",
          
        }, {
          title: t("Count"), dataIndex: "rank1count", key: "rank1count"
        }]
    },
    {
      title: `2nd ${t('Rank')}`,
      children: [
        {
          title: t("Value"),
          dataIndex: "rank2value",
          key: "rank2value",
          
        }, {
          title: t("Count"), dataIndex: "rank2count", key: "rank2count"
        }]
      
    },
    {
      title: `3rd ${t('Rank')}`,
      children: [
        {
          title: t("Value"),
          dataIndex: "rank3value",
          key: "rank3value",
          
        }, {
          title: t("Count"), dataIndex: "rank3count", key: "rank3count"
        }]
    },
    {
      title: `4th ${t('Rank')}`,
      children: [
        {
          title: t("Value"),
          dataIndex: "rank4value",
          key: "rank4value",
          
        }, {
          title: t("Count"), dataIndex: "rank4count", key: "rank4count"
        }]
      
    },
    {
      title: `5th ${t('Rank')}`,
      children: [
        {
          title: t("Value"),
          dataIndex: "rank5value",
          key: "rank5value",
          
        }, {
          title: t("Count"), dataIndex: "rank5count", key: "rank5count"
        }]
    }
  ];
  return (
    <div className="statistic_tab">
      {(title) ? (isString ? <StatisticsTabStringDetail backListData={backListData} title={title}/> :
        <StatisticsTabDetail backListData={backListData} title={title}/>) : <span>
        {dataStringsTarget.length > 0 ? <div>
          <div className="title">{t('Target')}</div>
          <Table dataSource={dataStringsTarget} pagination={false} columns={columnsStrings}/>
        </div> : ''}
        {dataNumberTarget.length > 0 ? <div>
          <div className="title">{t('Target')}</div>
          <Table dataSource={dataNumberTarget} pagination={false} columns={columnsNumber}/>
        </div> : ''}
        <div>
          {dataNumber.length > 0 ? <div>
            <div className="title">{t('Number')}</div>
            <Table dataSource={dataNumber} pagination={false} columns={columnsNumber}/>
          </div> : ''}
         
        </div>
        <div>
          {dataStrings.length > 0 ? <div>
            <div className="title">{t('Strings')}</div>
            <Table dataSource={dataStrings} pagination={false} columns={columnsStrings}/>
          </div> : ''}
        </div>
        {dataNumberId.length > 0 ? <div>
          <div className="title">{t('Id')}</div>
          <Table dataSource={dataNumberId} pagination={false} columns={columnsNumber}/>
        </div> : ''}
        {dataStringsId.length > 0 ? <div>
          <div className="title">{t('Id')}</div>
          <Table dataSource={dataStringsId} pagination={false} columns={columnsStrings}/>
        </div> : ''}
      </span>
      }
    </div>
  )
}

export default StatisticsTab