import React, { useState, Fragment, useEffect } from 'react';
import { Form, Input } from 'antd';
import { t } from 'utils/i18n';

import './style.less'
import { onChangeInput, onBlurInput, beforSubmit } from 'utils/Logics';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },
};

const Register = (props) => {
  const { user, setUser } = props
  const [message, setUserMessage] = useState({})

  useEffect(() => {
    if (typeof props.message === 'object') {
      const newMessage = Object.assign({}, message, props.message);
      setUserMessage(newMessage)
    }
  }, [props.message])

  return (
    // <Form {...formItemLayout} layout="horizontal" className="form_register">
    <Fragment>
      <Form.Item label={t("Email")} help={message.email} >
        <Input
          value={user.email}
          onChange={onChangeInput((email) => setUser({ ...user, email }), (email) => setUserMessage({ ...message, email }))}
          onBlur={onBlurInput(user.email, (email) => setUserMessage({ ...message, email }), "Email", true)}
          maxLength={100}
        />
      </Form.Item>
      <Form.Item label={t("Fullname")} help={message.fullname} >
        <Input
          value={user.fullname}
          onChange={onChangeInput((fullname) => setUser({ ...user, fullname }), (fullname) => setUserMessage({ ...message, fullname }))}
          onBlur={onBlurInput(user.fullname, (fullname) => setUserMessage({ ...message, fullname }), "Fullname")}
          maxLength={100}
        />
      </Form.Item>
      <Form.Item label={t("Password")} help={message.password} >
        <Input.Password
          value={user.password}
          onChange={onChangeInput((password) => setUser({ ...user, password }), (password) => setUserMessage({ ...message, password }))}
          onBlur={onBlurInput(user.password, (password) => setUserMessage({ ...message, password }), "Password", true)}
          maxLength={100}
        />
      </Form.Item>
      <Form.Item label={t("Confirm password")} help={message.confirmPassword}>
        <Input.Password
          value={user.confirmPassword}
          onChange={onChangeInput((confirmPassword) => setUser({ ...user, confirmPassword }), (confirmPassword) => setUserMessage({ ...message, confirmPassword }))}
          onBlur={onBlurInput(user.confirmPassword, (confirmPassword) => setUserMessage({ ...message, confirmPassword }), "Confirm password", true)}
          maxLength={100}
        />
      </Form.Item>
    </Fragment>
    // </Form>
  )
}

export default Register