import React, { useState } from 'react';
import { Button, Input, notification, Row, Spin } from 'antd';

import { useLocation } from 'react-router-dom'
import { getDataColumn, viewData } from 'apis/datas';
import { t } from "utils/i18n"
import DataSheet from "components/DataSheet";
import { DETAIL_TABS } from 'consts';
import moment from 'moment';
import { useDataLoadVersionAware } from "./hooks/useDataLoad";

const SelectColumnTab = (props) => {
  const { state: { data } } = useLocation()
  const [_localSpinning, setSpinning] = useState(false);
  const { data: dataCsv, loading: _useCSvSpinning } = useDataLoadVersionAware(viewData, [], {
    file_id: data.data_id,
    project_id: data.project_id
  });
  const spinning = _localSpinning || _useCSvSpinning;

  const [listColumn, setListColumn] = useState(["ID"])
  const [valueSaveAs, setValueSaveAs] = useState(data.data_name + "_" + t(DETAIL_TABS.SELECT_COLUMN) + "_" + moment().format("YYYY-MM-DD_HH:mm"))

  const getListColumn = (listColumn) => {
    let name = `${data.data_name}_Select_Columns_${listColumn.join('_')}`
    setValueSaveAs(name);
    setListColumn(listColumn);
  }

  const onClickSave = async () => {
    setSpinning(true)
    let body = {
      data_id: data.data_id,
      columns: listColumn
    }

    const response = await getDataColumn(body);
    if (response.status === 200) {
      setSpinning(false)
      notification.success({
        message: t("Success"),
        description: (
          <span>
            {t(response.data.message)}
          </span>
        )
      })
    } else {
      setSpinning(false)
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  const onChangeSaveAs = (e) => {
    setValueSaveAs(e.target.value)
  }

  const onClickSaveAs = async () => {
    setSpinning(true)
    let body = {
      data_id: data.data_id,
      save_as: valueSaveAs,
      columns: listColumn
    }

    const response = await getDataColumn(body);
    if (response.status === 200) {
      setSpinning(false)
      notification.success({
        message: t("Success"),
        description: (
          <span>
            {t(response.data.message)}
          </span>
        )
      })
    } else {
      setSpinning(false)
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  return (
    <div className="select_column_tab">
      <Spin tip={t("Loading..")} spinning={spinning}>
        <DataSheet getListColumn={getListColumn} checkbox={true} csv={dataCsv}/>
      </Spin>
      <Row style={{ textAlign: 'right', marginTop: '10px' }} type="flex" justify="end">
        <Row type='flex' justify='space-between' style={{ width: "35%" }} align="center">
          <span style={{ lineHeight: '31px', width: '27%', marginRight: 5 }}>{t('Data name')}:</span>
          <Input style={{ width: '70%' }} value={valueSaveAs} onChange={onChangeSaveAs}/>
        </Row>
        <Button style={{ marginLeft: 5 }}
                type="primary" onClick={onClickSaveAs}>{t("Save As")}</Button>


        <Button onClick={onClickSave} style={{ marginLeft: 30 }}
                type="primary">{t("Save")}</Button></Row>
    </div>
  )
}

export default SelectColumnTab
