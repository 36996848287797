import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import BaseLayout from 'components/BaseLayout';
import { Button, Col, notification, Row, Select, Spin, Tabs, Tooltip } from 'antd';
import { t } from 'utils/i18n';
import _ from 'lodash';
import { DETAIL_TABS, ROUTES } from 'consts';
import { getListProjects } from 'apis/projects';
import { getDataDetail, updateData, updateDataFile, getDataInfo } from 'apis/datas';
import { Link, useLocation } from 'react-router-dom';
import Configs from 'components/ConfigProviders';
import SpanEditable from 'components/SpanEditable';
import SelectLabel from 'components/SelectLabel';

import './style.less'
import moment from 'moment';
import StatisticsTab from "./StatisticsTab"
import CorrelationTab from "./CorrelationTab"
import SelectColumnTab from "./SelectColumnTab";
import SplitTab from "./SplitTab";
import DataViewTab from "./DataViewTab";
import WeatherTab from './WeatherTab';
import TimeSeriesTab from './TimeSeriesTab';
import CalculationTab from "./CalcualationTab";
import MergeTab from "./MergeTab";
import WeatherForecastTab from "./WeatherForecastTab";
import { DataLoader } from "./hooks/useDataLoad";

export default (props) => {
  const [activeKey, setActiveKey] = useState(DETAIL_TABS.DATA_VIEW);
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState({})
  const [dataTabView, setDataTabView] = useState({})
  const [loading, setLoading] = useState(false)
  const [isReset, setIsReset] = useState(true)
  const { team } = useContext(Configs)
  const location = useLocation()
  const dataTabViewRef = useRef()

  useEffect(() => {
    if(location.state?.data){
      setData(location.state.data)
    }
    else {
      getDataInfo(location.pathname.split('/').pop())
        .then(response=>{
          if(response.status !== 200) throw new Error(response.data.message)
          location.state = {data: response.data}
        })
        .catch (error => {
          notification.error({
            message: t("Error"),
            description: t(error.message)
          })
        })
    }
  }, [location.state])

  useEffect(() => {

    _getListProjects();

  }, [team])

  useEffect(() => {
    let keys = ['Statistics Tab', 'Correlation Tab', 'Split', 'Calculation', 'Time Series Beta', 'Weather Beta', 'Merge', 'Weather Forecast']
    if (keys.includes(activeKey) && _.isEmpty(dataTabView))
      _getDataDetail({
        data_id: props.match.params.id
      })
  }, [activeKey])

  const _getDataDetail = async (_query) => {
    setLoading(true)
    const response = await getDataDetail(_query);
    if (response.status === 200) {
      setLoading(false);
      setDataTabView(response.data)
      return
    }

    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
    setLoading(false);

  }

  const _getListProjects = async () => {
    const response = await getListProjects({
      sort_by: 'updated_at',
      order: 'DESC'
    });
    if (response.status === 200) {
      setProjects(response.data.data)
    }
  }

  const onChangeActiveKey = (newActiveKey) => {
    setActiveKey(newActiveKey)
    if (newActiveKey === 'Statistics') {
      setIsReset(true)
    } else {
      setIsReset(false)
    }
  }

  const onChangeLabel = async (value) => {
    const response = await updateData({
      data_id: data.data_id,
      project_id: data.project_id,
      params: [
        {
          field: "label",
          value: value
        }
      ]
    })
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
    } else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  const onSaveDataName = async name => {
    const response = await updateData({
      data_id: data.data_id,
      project_id: data.project_id,
      params: [
        {
          field: "name",
          value: name
        }
      ]
    })
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      return true
    } else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    return false
  }

  const handleAddDataViewColumn = () => {
    if (dataTabViewRef.current) {
      const columns = dataTabViewRef.current.columns()
      dataTabViewRef.current.addColumn(columns - 1)
    }
  }

  const handleSaveDataView = async () => {
    if (!dataTabViewRef.current) {
      return;
    }
    setLoading(true)
    try {
      const csv = dataTabViewRef.current.toCSV(true, true)
      const blob = new Blob([csv], { type: 'application/csv' });
      const response = await updateDataFile({
        file: blob,
        project_id: data.project_id,
        data_id: data.data_id
      })
      if (response.status === 200) {
        notification.success({
          message: t("Success")
        })
        dataVersion.update(new Date().getTime());
        await _getDataDetail({
          data_id: props.match.params.id
        })
        return true
      } else {
        notification.error({
          message: t("Error"),
          description: t(response.data.message)
        })
      }
    } catch (e) {
      const message = e.data?.message ?? "Invalid CSV data";
      notification.error({
        message: t("Error"),
        description: message
      });
    } finally {
      setLoading(false)
    }
  }

  const { DataContextProvider, context: dataVersion } = DataLoader.useContext();

  return (
    location.state ?
      <BaseLayout menuKey={ROUTES.DATA_MANAGEMENT} hideMenu={false} className="detail_data_page">
        <Row>
          <Row className="padding-around" type="flex" justify="start" align="middle">
            <Col level={4} style={{transform: "translate(-10px, -2px)"}}>
              <Link to={ROUTES.DATA_MANAGEMENT} style={{fontSize: 20, fontWeight: 800}}>{t("Data Management")}</Link>
              <span className="arrow_to_dataname" style={{fontSize: 20, fontWeight: 800}}>{">"}</span>
            </Col>
            <Col style={{ width: '90%' }}>
              <SpanEditable
                style={{
                  fontSize: 20,
                  color: '#000000',
                  fontWeight: 600,
                  transform: "translateY(-3px)",
                  maxWidth: "600px"
                }}
                buttonEditStyle={{
                  fontSize: 18
                }}
                onSave={onSaveDataName}
              >
                {data.data_name}
              </SpanEditable>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" style={{ marginTop: 20 }}>
            <Row className="padding-around" style={{ width: 200 }} type="flex" justify="start" align="middle">
              <Col style={{ paddingRight: 10 }}>
                <strong>
                  {t("Label")}:
                </strong>
              </Col>
              <Col span={12} style={{ flex: 1 }}>
                <SelectLabel
                  value={data.label}
                  onChange={onChangeLabel}
                />
              </Col>
            </Row>
            <Row className="padding-around" style={{ width: 250 }} type="flex" justify="start" align="middle">
              <Col style={{ paddingRight: 10 }}>
                <strong>
                  {t("Project")}:
                </strong>
              </Col>
              <Col span={12} style={{ flex: 1 }}>
                <Select
                  value={data.project_id}
                  // onChange={onChangeProject(record)}
                  className="fullsize_selectbox"
                >
                  {
                    projects.map(p => (
                      <Select.Option value={p.project_id}
                                    key={p.project_id}>{`${p.name}`}</Select.Option>
                    ))
                  }
                </Select>
              </Col>
            </Row>
            <Row className="padding-around" type="flex" justify="start" align="middle">
                <span style={{ paddingRight: 10 }}>
                  <strong>
                    {t("Update by")}:
                </strong>
                </span>
              <Tooltip overlay={<span>{data.editor}</span>}>
                <div className="ellipsis_text"><strong>{data.editor}</strong></div>
              </Tooltip>
            </Row>
            <Row className="padding-around" type="flex" justify="start" align="middle">
                <span style={{ paddingRight: 10 }}>
                  <strong>
                    {t("Updated at")}:
                </strong>
                </span>
              <strong>{moment(data.updated_at).format("YYYY-MM-DD HH:mm")}</strong>
            </Row>
            {activeKey === DETAIL_TABS.DATA_VIEW &&
            <Row className="padding-around" type="flex" style={{ flex: 1 }} justify="end" align="middle">
              <Button type="primary" onClick={handleAddDataViewColumn} disabled={loading}>
                {t('Add columns')}
              </Button>
              <Button type="primary" style={{ marginLeft: 10 }} onClick={handleSaveDataView} disabled={loading}>
                {t('Save changed')}
              </Button>
            </Row>
            }
          </Row>
        </Row>
        <Row className={"tabDetailData"}>
          <Spin spinning={loading}>
            <DataContextProvider value={dataVersion}>
              <Tabs activeKey={activeKey} onChange={onChangeActiveKey}>
                <Tabs.TabPane tab={t(DETAIL_TABS.DATA_VIEW)} key={DETAIL_TABS.DATA_VIEW}>
                  <DataViewTab ref={dataTabViewRef} editable={true}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t(DETAIL_TABS.STATISTICS)} key={DETAIL_TABS.STATISTICS}>
                  <StatisticsTab fileId={props.match.params.id} isReset={isReset} dataTabView={dataTabView}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t(DETAIL_TABS.CORRELATION)} key={DETAIL_TABS.CORRELATION}>
                  <CorrelationTab fileId={props.match.params.id} dataTabView={dataTabView}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t(DETAIL_TABS.SPLIT)} key={DETAIL_TABS.SPLIT}>
                  <SplitTab fileId={props.match.params.id} dataTabView={dataTabView}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t(DETAIL_TABS.CALCULATION)} key={DETAIL_TABS.CALCULATION}>
                  <CalculationTab fileId={props.match.params.id} dataTabView={dataTabView}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t(DETAIL_TABS.SELECT_COLUMN)} key={DETAIL_TABS.SELECT_COLUMN}>
                  <SelectColumnTab fileId={props.match.params.id}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t(DETAIL_TABS.TIME_SERIES)} key={DETAIL_TABS.TIME_SERIES}>
                  <TimeSeriesTab dataTabView={dataTabView} activeKey={activeKey}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t(DETAIL_TABS.WEATHER)} key={DETAIL_TABS.WEATHER}>
                  <WeatherTab dataTabView={dataTabView} activeKey={activeKey}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t(DETAIL_TABS.WEATHER_FORECAST)} key={DETAIL_TABS.WEATHER_FORECAST}>
                  <WeatherForecastTab dataTabView={dataTabView} activeKey={activeKey}/>
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab={t(DETAIL_TABS.MERGE)} key={DETAIL_TABS.MERGE}>
                  <MergeTab dataTabView={dataTabView} activeKey={activeKey}/>
                </Tabs.TabPane> */}
              </Tabs>
            </DataContextProvider>
          </Spin>

        </Row>
      </BaseLayout>
      :
      <Spin style={{width: "100vw", top: "50%", position: "absolute"}}/>
  )
}
