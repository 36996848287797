import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, notification, Row, Pagination } from 'antd';
import { t } from 'utils/i18n';
import { getTeamInfomation } from 'apis/teams';
import ListUsers from 'components/ListUsers';

const onHideModal = (setVisible) => () => setVisible(false)

const onOpenModal = setVisible => () => setVisible(true)

export default (props) => {
  const [visible, setVisible] = useState(false)
  const [team, setTeam] = useState({})
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState({limit: 10})

  const _onChangePage = (page) => {
    if (Number(page)) {
      setQuery({ ...query, page: Number(page) })
    }
  }

  useEffect(() => {
    if (visible) {
      _getTeamInfomation(props.team.id, query)
    }
    else {
      setLoading(true)
    }
  }, [visible, query])

  const _getTeamInfomation = async (id, _query) => {
    setLoading(true)
    const response = await getTeamInfomation(id, _query);
    if (response.status === 200) {
      setTeam(response.data)
      setLoading(false)
    }
    else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  return (
    <Fragment>
      <Button
        type="primary"
        onClick={onOpenModal(setVisible)}
        style={{ marginLeft: 10 }}
      >
        {t("Member list")}
      </Button>
      <Modal
        cancelText={t("Cancel")}
        title={props.team.name}
        visible={visible}
        onCancel={onHideModal(setVisible)}
        onOk={onHideModal(setVisible)}
        width="800px"
        className="fit-height-modal"
        style={{ height: 'calc(100vh - 100px)' }}
        centered
      >
        <ListUsers dataSource={team.users || []} loading={loading} />
        <Row type="flex" justify="end" style={{ padding: 5 }}>
          <Pagination size="small" showQuickJumper pageSize={Number(team.limit) || 20} current={Number(team.page) || 1} total={Number(team.totalUsers) | 0} onChange={_onChangePage} />
        </Row>
      </Modal>
    </Fragment>
  )
}