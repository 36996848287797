import React, {useEffect, useState, Fragment, useContext} from 'react';
import moment from 'moment';
import {
  Checkbox,
  Table,
  InputNumber,
  Tabs,
  Slider,
  Tag,
  Divider,
  Steps,
  Select,
  Button,
  notification,
  Icon,
  Row,
  Typography,
  Col,
  Pagination,
  Modal,
  Tooltip, Spin, Input
} from 'antd';

import {useLocation} from 'react-router-dom'
import {checkColor, convertDataViewNumber, convertDataViewString} from 'utils';
// import moment from 'moment';
// import TitleTable from 'components/TitleTable';
// import SelectLabel from 'components/SelectLabel';
// import {getListDatas, deleteData, updateData} from 'apis/datas';
// import {ROUTES, LABELS} from 'consts';
// import {accessDownload} from 'apis/auth';
// import Configs from 'components/ConfigProviders';
// import {useHistory} from 'react-router-dom';
// import SpanEditable from 'components/SpanEditable';
import PapaParse from 'papaparse'

import {calculationData, splitData, viewData} from 'apis/datas';
import Plot from 'react-plotly.js';
import {t} from "utils/i18n"
import DataSheet from "components/DataSheet";

const {TabPane} = Tabs;
const {Option} = Select;

const CalculationTab = (props) => {
  
  
  const {state: {data}} = useLocation()
  const [listColumn, setListColumn] = useState([]);
  
  const [valueMathDoubleCol, setValueMathDoubleCol] = useState(`add`);
  let [valueDoubleColName1, setValueDoubleCol1] = useState('');
  let [valueDoubleColName2, setValueDoubleCol2] = useState('');
  let [valueDoubleColName3, setValueDoubleCol3] = useState('');
  
  let [valueColName1, setValueCol1] = useState('');
  let [valueInputValue, setValueInputValue] = useState('');
  let [valueColName2, setValueCol2] = useState('');
  const [valueMathValueCol, setValueMathValueCol] = useState(`add`);
  
  let [valueSpecNameCol1, setValueSpecNameCol1] = useState('');
  let [valueSpecMethod, setSpecMethod] = useState('');
  let [valueSpecNameCol2, setValueSpecNameCol2] = useState('');
  let [resultColumn, setResultColumn] = useState('');
  let [resultValue, setResultValue] = useState('');
  let [resultSpecial, setResultSpecial] = useState('');
  let [csv, setCsv] = useState('');
  let [loading, setLoading] = useState(false);
  const math = {
    "minus": "MINUS",
    "add": "PLUS",
    "multiply": "MULTIPLY",
    "divide": "DIVIDE",
  }
  useEffect(() => {
    setListColumn(handleListColumn(props.dataTabView.Number))
  }, [props.dataTabView])
  
  useEffect(() => {
    _getCsv()
  }, [])
  
  const _getCsv = async () => {
    const response = await viewData({
      file_id: data.data_id,
      project_id: data.project_id
    })
    if (response.status === 200) {
      setCsv(PapaParse.parse(response.data))
    }
  }
  let handleListColumn = (data) => {
    if (data) {
      let column = data.map((value, index) => {
        return value.column
      })
      return column;
      
    }
  }
  
  let onClickCalculationDoubleCol = async () => {
    setLoading(true);
    let body = {
      data_id: data.data_id,
      type_calculate: 'column',
      column: valueDoubleColName1,
      action: valueMathDoubleCol,
      value: valueDoubleColName2,
      name: valueDoubleColName3
    }
    
    let response = await calculationData(body);
    if (response.status === 200) {
      setLoading(false);
      notification.success({
        message: t("Success")
      })
      setResultColumn(PapaParse.parse(response.data))
    } else {
      setLoading(false);
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    
  }
  let onChangeMathDoubleCol = (value) => {
    setValueMathDoubleCol(value)
    setValueDoubleCol3([valueDoubleColName1, valueDoubleColName2, math[value]].join('_'));
  }
  let onChangeValueDoubleColName1 = (value) => {
    setValueDoubleCol1(value)
    setValueDoubleCol3([value, valueDoubleColName2, math[valueMathDoubleCol]].join('_'));
  }
  let onChangeValueDoubleColName2 = (value) => {
    setValueDoubleCol2(value)
    setValueDoubleCol3([valueDoubleColName1, value, math[valueMathDoubleCol]].join('_'));
  }
  let onChangeValueDoubleColName3 = (e) => {
    setValueDoubleCol3(e.target.value)
  }
  
  
  let onChangeMathValueCol = (value) => {
    setValueMathValueCol(value)
    setValueCol2([valueColName1, valueInputValue, math[value]].join('_'));
  }
  let onChangeValueColName1 = async (value) => {
    await setValueCol1(value)
    setValueCol2([value, valueInputValue, math[valueMathValueCol]].join('_'));
  }
  let onChangeInputValue = (value) => {
    setValueInputValue(value)
    setValueCol2([valueColName1, value, math[valueMathValueCol]].join('_'));
  }
  let onChangeColName2 = (e) => {
    setValueCol2(e.target.value)
  }
  let onClickCalculationValueCol = async () => {
    setLoading(true);
    let body = {
      data_id: data.data_id,
      type_calculate: 'value',
      column: valueColName1,
      action: valueMathValueCol,
      value: Number(valueInputValue),
      name: valueColName2
    }
    
    let response = await calculationData(body);
    if (response.status === 200) {
      setLoading(false);
      notification.success({
        message: t("Success")
      })
      setResultValue(PapaParse.parse(response.data))
    } else {
      setLoading(false);
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    
  }
  let onClickCalculationSpecCol = async () => {
    setLoading(true);
    let body = {
      data_id: data.data_id,
      type_calculate: 'special',
      column: valueSpecNameCol1,
      action: valueSpecMethod,
      name: valueSpecNameCol2
    }
    
    let response = await calculationData(body);
    if (response.status === 200) {
      setLoading(false);
      notification.success({
        message: t("Success")
      })
      setResultSpecial(PapaParse.parse(response.data))
    } else {
      setLoading(false);
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    
  }
  
  
  let onChangeSpecNameCol1 = async (value) => {
    await setValueSpecNameCol1(value)
    setValueSpecNameCol2([value, valueSpecMethod, 'PLUS'].join('_'));
  }
  let onChangeSpecMethod = async (value) => {
    await setSpecMethod(value)
    setValueSpecNameCol2([valueSpecNameCol1, value, 'PLUS'].join('_'));
  }
  let onChangeSpecNameCol2 = async (e) => {
    await setValueSpecNameCol2(e.target.value)
  }
  
  let returnIndexNameCol = (nameCol, data) => {
    for (let i = 0; i < data.data[0].length; i++) {
      if (data.data[0][i] === nameCol) {
        return i
      }
    }
  }
  
  let listDataCol = (indexCol, data) => {
    let listData = [];
    for (let i = 1; i <= Math.min(data.data.length-1, 10) ; i++) {
      listData.push(data.data[i][indexCol]);
    }
    return listData
  }
  
  return (
    <div className="split_tab">
      <Spin spinning={loading} style={{width:'70%'}}>
        <Tabs defaultActiveKey="1" tabPosition="left">
          <TabPane tab={t('Column')} key={'ColumnDouble'}>
            <Row style={{width: '70%'}}>
              <Row type="flex" justify="space-between" style={{marginTop: 10}}>
              <span style={{width: '25%'}}>
                <div>{t('Column Name')}:</div>
                <Select style={{width: "100%"}} value={valueDoubleColName1} onChange={onChangeValueDoubleColName1}>
                  {listColumn && listColumn.map((data, index) => {
                    return <Option value={data}>{data}</Option>
                  })}
                </Select>
              </span>
                <span style={{width: '8%'}}>
                <div style={{height: 22}}/>
                <Select style={{width: '100%'}} value={valueMathDoubleCol} onChange={onChangeMathDoubleCol}>
                  <Option value="add">{`+`}</Option>
                  <Option value="minus">{`-`}</Option>
                  <Option value="multiply"> {`*`} </Option>
                  <Option value="divide">{`÷`}</Option>
                </Select>
              </span>
                <span style={{width: '25%'}}>
                <div>{t('Column Name')}:</div>
                <Select style={{width: "100%"}} value={valueDoubleColName2} onChange={onChangeValueDoubleColName2}>
                  {listColumn && listColumn.map((data, index) => {
                    return <Option value={data}>{data}</Option>
                  })}
                </Select>
              </span>
                <span style={{width: '5%'}}>
                <Icon style={{fontSize: '38px', marginTop: '16px'}} type="caret-right"/>
              </span>
                <span style={{width: '25%'}}>
                <div>{t('Column Name')}:</div>
                <Input style={{width: "100%"}} value={valueDoubleColName3} onChange={onChangeValueDoubleColName3}/>
              </span>
              </Row>
              <div style={{textAlign: 'right', marginTop: '10px'}}>
                <Button onClick={onClickCalculationDoubleCol} type="primary">Calculation</Button>
              </div>
              <div>{t('Example')}:</div>
              <Row type="flex" justify="space-between"
                   style={{alignItems: 'stretch', fontSize: '18px', textAlign: 'center'}}>
              <span style={{width: '25%'}}>
                {csv && valueDoubleColName1 && (listDataCol(returnIndexNameCol(valueDoubleColName1, csv), csv)).map((data, index) => {
                  return <div>{data}</div>
                })}
              </span>
                <span style={{width: '8%'}}>
              
              </span>
                <span style={{width: '25%'}}>
                {csv && valueDoubleColName2 && (listDataCol(returnIndexNameCol(valueDoubleColName2, csv), csv)).map((data, index) => {
                  return <div>{data}</div>
                })}
              </span>
                <Row style={{width: '5%'}}>
                
                </Row>
                <span style={{width: '25%'}}>
                {resultColumn && (listDataCol(0, resultColumn)).map((data, index) => {
                  return <div>{data}</div>
                })}
              </span>
              </Row>
            
            </Row>
          </TabPane>
          <TabPane tab={t('ValueSplit')} key={'Value'}>
            <Row style={{width: '70%'}}>
              <Row type="flex" justify="space-between" style={{marginTop: 10}}>
              <span style={{width: '25%'}}>
                <div>{t('Column Name')}:</div>
                <Select style={{width: "100%"}} value={valueColName1} onChange={onChangeValueColName1}>
                  {listColumn && listColumn.map((data, index) => {
                    return <Option value={data}>{data}</Option>
                  })}
                </Select>
              </span>
                <span style={{width: '8%'}}>
                <div style={{height: 22}}></div>
                <Select style={{width: '100%'}} value={valueMathValueCol} onChange={onChangeMathValueCol}>
                  <Option value="add">{`+`}</Option>
                  <Option value="minus">{`-`}</Option>
                  <Option value="multiply"> {`x`} </Option>
                  <Option value="divide">{`÷`}</Option>
                
                </Select>
              </span>
                <span style={{width: '25%'}}>
                <div>{t('ValueSplit')}:</div>
                <InputNumber style={{width: "100%"}} value={valueInputValue} onChange={onChangeInputValue}/>
              </span>
                <span style={{width: '5%'}}>
                <Icon style={{fontSize: '38px', marginTop: '16px'}} type="caret-right"/>
              </span>
                <span style={{width: '25%'}}>
                <div>{t('Column Name')}:</div>
                <Input style={{width: "100%"}} value={valueColName2} onChange={onChangeColName2}/>
                <div style={{textAlign: 'right', marginTop: '10px'}}><Button type="primary"
                                                                             onClick={onClickCalculationValueCol}>Calculation</Button>
                </div>
              </span>
              </Row>
              <div>{t('Example')}:</div>
              <Row type="flex" justify="space-between"
                   style={{alignItems: 'stretch', fontSize: '18px', textAlign: 'center'}}>
              <span style={{width: '25%'}}>
                {csv && valueColName1 && (listDataCol(returnIndexNameCol(valueColName1, csv), csv)).map((data, index) => {
                  return <div>{data}</div>
                })}
              </span>
                <span style={{width: '8%'}}>
    
              </span>
                <span style={{width: '25%'}}>
              </span>
                <Row style={{width: '5%'}}>
                
                </Row>
                <Row style={{width: '25%'}}>
                  {resultValue && (listDataCol(0, resultValue)).map((data, index) => {
                    return <div>{data}</div>
                  })}
                </Row>
              </Row>
            </Row>
          </TabPane>
          <TabPane tab={t('Special')} key={'Special'}>
            <Row style={{width: '70%'}}>
              <Row type="flex" justify="space-between" style={{marginTop: 10}}>
              <span style={{width: '30%'}}>
                <div>{t('Column Name')}:</div>
                <Select style={{width: "100%"}} value={valueSpecNameCol1} onChange={onChangeSpecNameCol1}>
                  {listColumn && listColumn.map((data, index) => {
                    return <Option value={data}>{data}</Option>
                  })}
                </Select>
              </span>
                <span style={{width: '30%'}}>
                <div>{t('Method')}:</div>
                <Select style={{width: "100%"}} value={valueSpecMethod} onChange={onChangeSpecMethod}>
                  <Option value="MA7">{t('Moving Average (7)')}</Option>
                  <Option value="MA30">{t('Moving Average (30)')}</Option>
                </Select>
              </span>
                <span style={{width: '5%'}}>
                <Icon style={{fontSize: '38px', marginTop: '16px'}} type="caret-right"/>
              </span>
                <span style={{width: '30%'}}>
                <div>{t('Column Name')}:</div>
                <Input style={{width: "100%"}} value={valueSpecNameCol2} onChange={onChangeSpecNameCol2}/>
                <div style={{textAlign: 'right', marginTop: '10px'}}><Button type="primary"
                                                                             onClick={onClickCalculationSpecCol}>Calculation</Button>
                </div>
              </span>
              </Row>
              <div>{t('Example')}:</div>
              <Row type="flex" justify="space-between"
                   style={{alignItems: 'stretch', fontSize: '18px', textAlign: 'center'}}>
              <span style={{width: '30%'}}>
                {csv && valueSpecNameCol1 && (listDataCol(returnIndexNameCol(valueSpecNameCol1, csv), csv)).map((data, index) => {
                  return <div>{data}</div>
                })}
              </span>
                <span style={{width: '30%'}}>
    
              </span>
                <span style={{width: '5%'}}>
              </span>
                <span style={{width: '30%'}}>
                {resultSpecial && (listDataCol(0, resultSpecial)).map((data, index) => {
                  return <div>{data}</div>
                })}
              </span>
              </Row>
            </Row>
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  )
}

export default CalculationTab