import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Icon, notification } from 'antd';
import { onChangeInput, onBlurInput, beforSubmit } from 'utils/Logics'
import { t } from 'utils/i18n';
import { updateMyInfomation } from 'apis/users';

const onHideModal = (setVisible) => () => setVisible(false)

const onOpenModal = setVisible => () => setVisible(true)

export default (props) => {
  const [visible, setVisible] = useState(false)
  const [message, setMessage] = useState("")
  const [company, setCompany] = useState()

  useEffect(() => {
    setCompany(props.company)
  }, [props.company])

  const onOk = async () => {
    const body = beforSubmit({
      company: {
        value: company,
        setMessage,
        title: "Company"
      }
    })
    if (!body) {
      return false
    }
    const response = await updateMyInfomation(body)
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      props.setCompany(body.company)
      setVisible(false)
      return
    }
    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
  }

  return (
    <Fragment>
      <Button shape="circle" type="link" onClick={onOpenModal(setVisible)}>
        <Icon type="edit" />
      </Button>
      <Modal
        cancelText={t("Cancel")}
        title={t("Update company")}
        visible={visible}
        onCancel={onHideModal(setVisible)}
        onOk={onOk}
      >
        <Form>
          <Form.Item label={t("Company")} required help={message}>
            <Input
              value={company}
              onChange={onChangeInput(setCompany, setMessage)}
              onBlur={onBlurInput(company, setMessage, 'Company')}
              maxLength={100}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}