import React, {useContext, useState, useEffect} from 'react';
import {Layout, Menu, Button, Row, Icon, Badge, Modal, notification, Select} from 'antd';
import {useHistory, useRouteMatch} from 'react-router-dom';

import {MENU, ROUTES} from 'consts';
import {t} from 'utils/i18n'
import './style.less'
import Configs from '../ConfigProviders';
import Logo from 'assets/FE_logo_W.png'
import {debound} from 'utils';
import {ROLES} from 'consts';
import {getNotifications, readedNotification} from 'apis/notifications';
import {acceptInvited} from 'apis/teams';
import Roles from '../Roles';

const {Header, Content, Footer} = Layout

/**
 * @param {Object} props
 * @param {String} props.className
 * @param {Boolean} props.hideMenu
 * @param {Boolean} props.menuKey
 */
export default (props) => {
  const routeMatch = useRouteMatch()
  const history = useHistory()
  const {lang, _onToogleLang, team, _onSetTeam, listTeam} = useContext(Configs)
  const [notifications, setNotifications] = useState([])
  const [isDot, setIsDot] = useState(false)
  const [listIdNotification, setListIdNotification] = useState(false)
  
  const handleInvite = ({id, resourceName, resourceId}) => {
    Modal.confirm({
      title: t("Are you sure to join into team %1", resourceName),
      onCancel: () => {
      },
      onOk: async () => {
        const response = await acceptInvited(resourceId);
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          window.location.reload()
        } else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
      }
    })
  }
  
  const handleMoveToPage = ({id, resourceId, resourceName, resourceType}) => {
    const mappingRoutes = {
      models: ROUTES.MODEL_MANAGEMENT
    }
    history.push(mappingRoutes[resourceType] /*, { [`${resourceType.slice(0, -1)}_id`]: resourceId }*/)
  }
  const onCLickBell = () => {
    setIsDot(false)
    readedNotification([listIdNotification]);
  }
  const onMenuClick = (menu, event) => {
    if(!menu.key) return
    if (menu.key === "logout") {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("fullname")
      localStorage.removeItem("currentTeamId")
      window.location.reload()
      return
    }
    if (menu.key.includes("notification")) {
      const [, id, resourceName, resourceId, resourceType, message] = menu.key.split(".")
      //readedNotification([id]);
      onReloadNotification()
      switch (message) {
        case 'invited':
          return handleInvite({id, resourceId, resourceName})
        default:
          return handleMoveToPage({id, resourceName, resourceType, resourceId})
      }
      return
    }
    if (menu.key.includes("team") && menu.key !== ROUTES.DETAIL_TEAM) {
      _onSetTeam(listTeam.filter(t => t.id === Number(menu.key.split(".").pop())).pop())
      return
    }
    if (menu.key !== "lang") {
      if (menu.key === ROUTES.DETAIL_TEAM) {
        return history.push(menu.key.replace(":id", localStorage.getItem("currentTeamId")))
      }
      history.push(menu.key)
    }
  }
  
  const onChangeTeam = (value) => {
    _onSetTeam(listTeam.find(t => t.id === value))
  }
  
  useEffect(() => {
    let notificationWorker
    debound(onReloadNotification)()
    if (localStorage.getItem("accessToken")) {
      notificationWorker = setInterval(() => {
        debound(onReloadNotification)()
      }, 60 * 1000);
    }
    return () => {
      if (notificationWorker) {
        clearInterval(notificationWorker)
      }
    }
  }, [])
  
  const onReloadNotification = async () => {
    if (localStorage.getItem("accessToken")) {
      const response = await getNotifications();
      if (response.status === 200) {
        setNotifications(response.data)
        setListIdNotification(response.data.map((record,index)=>{
          return record.id;
        }))
      }
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].isReaded === false) {
          setIsDot(true);
          return;
        }
      }
    }
  }
  
  const getNotification = (noti) => {
    switch (noti.message) {
      case 'invited':
        return (
          <span><strong>{noti.inviter.fullname}{" " + noti.message + " " + t("you to") + " " + noti.resourceType + " " + noti.resourceName}</strong></span>)
      default:
        return (
          <span><strong>{t(`${noti.resourceType}_notifications`)+' '+noti.resourceName} {t(noti.message)}</strong></span>)
    }
  }
  
  return (
    <Layout className={`${props.className} base_layout`}>
      <Header className="base_header">
        <img onClick={() => {
          history.push(ROUTES.HOME)
        }} src={Logo} style={{width: 300, marginRight: 20, marginBottom: 5, cursor: "pointer"}}/>
        {
          props.hideMenu ?
            null :
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={[`${props.menuKey ? props.menuKey : (routeMatch.path || ROUTES.HOME)}`]}
              className="menu"
              onClick={onMenuClick}
              key="hidden_menu"
            
            >
              <Menu.Item key="logout" className="menu_right" style={{padding: "0 10px"}}>
                {t("Logout")}
              </Menu.Item>
              {
                MENU.map((menu, index) => (
                  <Roles protect={menu.protect} key={menu.key} className={menu.classname || ""}>
                    <Menu.Item>
                      <div className="ellipsis_text" style={{margin: 1, padding: "0 10px"}}>
                        {menu.title ? t(menu.title) : localStorage.getItem("fullname")}
                      </div>
                    </Menu.Item>
                  </Roles>
                ))
              }
              <Roles
                protect={{must: ROLES.USER}}
                key="choose_team"
                className="menu_right"
              >
                <span id="team-selector-container">
                  <Icon type="team" style={{fontSize: 20}}/>
                  <Select
                    className="team-selector"
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a team"
                    optionFilterProp="children"
                    onChange={onChangeTeam}
                    value={team?.id}
                    suffixIcon={
                      <Icon type="down" style={{color: "#ffffff"}}/>
                    }
                    dropdownMatchSelectWidth={false}
                    dropdownAlign={{
                      points: ['tr', 'br'],
                    }}
                  >
                    {
                      listTeam.map(t => (
                        <Select.Option key={t.id} value={t.id}>{t.name}</Select.Option>
                      ))
                    }
                  </Select>
                </span>
              </Roles>
              <Menu.SubMenu key="notification" className="menu_right" onTitleClick={onCLickBell} title={(
                <span>
                  <Badge dot={isDot}>
                    <Icon type="bell" style={{fontSize: 20}}/>
                  </Badge>
                </span>
              )}>
                {
                  notifications.map(n => (
                    <Menu.Item style={{
                      height: '100%',
                      cursor: "pointer",
                      backgroundColor: n.isReaded ? "#ffffff" : "rgba(211,244,255)",
                      color: '#000000',
                      padding: "0 20px"
                    }} key={`notification.${n.id}.${n.resourceName}.${n.resourceId}.${n.resourceType}.${n.message}`}>
                      {getNotification(n)}
                    </Menu.Item>
                  ))
                }
              </Menu.SubMenu>
            </Menu>
        }
      </Header>
      <Content className="base_content">
        <div className="content">
          {
            props.children
          }
        </div>
      </Content>
      
      <Footer className="footer" style={{textAlign: 'center', position: "relative"}}>
        <Row>
          <a href={t("link_about")} target="_blank">{t("About")}</a>&nbsp;|&nbsp;<a href={t("link_support")}
                                                                                    target="_blank">{t("Support")}</a>&nbsp;|&nbsp;
          <a href={t("link_term")} target="_blank">{t("Term of Use (Japanese)")}</a>&nbsp;|&nbsp;<a
          href={t("link_privacy")} target="_blank">{t("Privacy Policy (Japanese)")}</a>
        </Row>
        <p>&copy; 2020 Idrasys</p>
        {/* <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => _onToogleLang(key)}
            >
              <Menu.Item key="English">English</Menu.Item>
              <Menu.Item key="日本語">日本語</Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        > */}
        <Button style={{position: "absolute", left: 20, bottom: 13}} onClick={() => _onToogleLang()}>
          {lang}
        </Button>
        {/* </Dropdown> */}
      </Footer>
      {/* <Button onClick={() => history.push("/new-message-i18n")}>i18n</Button> */}
    </Layout>
  )
}