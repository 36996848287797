import React from 'react';
import {Timeline, Row, Tooltip} from 'antd';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {t} from 'utils/i18n';
import './style.less';
const actions = {
  uploaded: "をアップロードしました。",
  deleted: "を削除しました。",
  created: "を作成しました。",
  joined: "に参加しました。",
  forecast: "を予測しました。",
  invited: "を招待しました。",
}

const displayActorName = (actor, actorId) => {
  if (actor) {
    return actor.fullname
  }
  if (Number(actorId) <= 0) {
    return "API User"
  }
  return t("Deleted User")
}

/**
 *
 * @param {Object} props
 * @param {Array.<{date: Date, message: String}>} props.dataSource
 */
const Timelines = (props) => {
  const lang = localStorage.getItem("lang")
  return (
    <Timeline className="time-line">
      {
        props.dataSource instanceof Array && props.dataSource.map((timeline, index) => (
          <Timeline.Item key={index}>
            <div>
              <div><strong>{moment(timeline.createdAt).format("YYYY-MM-DD (dd) HH:mm")}</strong></div>
              <Row type="flex" justify="start" className="text-ellipsis">
              
                      <span>{displayActorName(timeline.actor, timeline.actorId)} {t(`${timeline.action || ""} ${timeline.resourceType || "teams"}`)}{timeline.resourceName && timeline.action === 'invited' ? ',' : ''}{timeline.resourceName?' ':''}{timeline.resourceName && timeline.resourceName.replace(/(\{\{)(.+)(\}\})/g, (match, g1, text) => t(text)) + ""}{
                        lang === 'en' && '.'
                      } {lang === 'ja' && actions[timeline.action]}</span>
                    
              </Row>
            </div>
          </Timeline.Item>
        ))
      }
    </Timeline>
  )
}

Timelines.defaultProps = {
  dataSource: []
}

export default Timelines