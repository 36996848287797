import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Divider, Typography, Avatar, Badge, Icon, Button, Upload, notification } from 'antd';

import { t } from 'utils/i18n';
import { ROUTES } from 'consts';
import BaseLayout from 'components/BaseLayout';
import ModalActivities from 'components/ModalActivities';
import ModalUpdatePassword from './ModalUpdatePassword';
import { getMyInfomation, uploadAvatar } from 'apis/users';

import './style.less'
import ModalEditFullname from './ModalEditFullname';
import Timelines from 'components/Timelines';
import ModalEditPosition from './ModalEditPosition';
import ModalEditCompany from './ModalEditCompany';

export default (props) => {
  const history = useHistory()
  const [user, setUser] = useState({})

  useEffect(() => {
    getUserInfomation()
  }, [])

  const getUserInfomation = async () => {
    const response = await getMyInfomation()

    if (response.status === 404) {
      return history.push(ROUTES.LOGIN)
    }

    if (response.status === 200) {
      setUser(response.data)
    }
  }

  const _getAvatar = () => {
    if (!user.avatar) {
      return {
        icon: <Icon type="user" />
      }
    }
    if (user.avatar === 'loading') {
      return {
        icon: <Icon type="loading" spin />
      }
    }
    return {
      src: process.env[`REACT_APP_BACKEND_${(process.env.NODE_ENV !== 'production' ? "DEV" : "PROD")}`] + `/users/avatar/${user.avatar || ""}`
    }
  }

  return (
    <BaseLayout hideMenu={false} className="user_setting_page">
      <Row gutter={[30, 0]}>
        <Col span={16} className="min_col">
          <Row className="custom_row">
            <Typography.Title level={4}>
              {t("Profile")}
            </Typography.Title>
            <Divider />
            <Row style={{ marginBottom: 12 }}>
              <Col span={5} className="user_setting_avatar">
                <Badge
                  count={
                    <Upload
                      accept=".png,.jpg,.jpeg,.gif"
                      fileList={[]}
                      customRequest={(option) => {
                        if (option.file.size / 1048576 /* 1MB */ > 10) {
                          notification.warning({
                            message: t("Avatar is too large. You must upload image less 100MB")
                          })
                          return
                        }
                        if(!['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(option.file.type)) {
                          notification.warning({
                            message: t("File format not allowed, please upload PNG, JPEG and GIF format.")
                          })
                          return
                        }
                        setUser({ ...user, avatar: "loading" })
                        const form = new FormData();
                        form.append("avatar", option.file);
                        uploadAvatar(form)
                          .then(response => {
                            if (response.status === 200) {
                              getUserInfomation();
                            }
                            else {
                              notification.error({
                                message: t("Error"),
                                description: t(response.data.message)
                              })
                              getUserInfomation();
                            }
                          })
                      }}
                    >
                      <Button size="small" shape="circle" type="primary"><Icon type="edit" /></Button>
                    </Upload>
                  }
                >
                  <Avatar
                    shape="square"
                    size={70}
                    {..._getAvatar()}
                  />
                </Badge>
              </Col>
              <Col span={19}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <div className="ellipsis_text">{user.fullname}</div>
                  <ModalEditFullname fullname={user.fullname} setFullname={(fullname) => setUser({ ...user, fullname })} />
                </div>
                <div>{user.email} </div>
                <ModalUpdatePassword />
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <span>{t("Company")}</span>
              </Col >
              <Col span={19}>
                : <span>{user.company} <ModalEditCompany company={user.company} setCompany={(company) => setUser({ ...user, company })} /></span>
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <span>{t("Position")}</span>
              </Col>
              <Col span={19}>
                : <span>{user.position} <ModalEditPosition position={user.position} setPosition={(position) => setUser({ ...user, position })} /> </span>
              </Col>
            </Row>
            <Row>
              <Col span={5}>

              </Col>
              <Col>
              </Col>
            </Row>
          </Row>
        </Col>
        <Col span={8} className="timeline min_col">
          <Typography.Title level={4}>
            {t("My Activities")}
          </Typography.Title>
          <Divider />
          <Timelines dataSource={user.activities && user.activities.slice(0, 10) || []} />
          <ModalActivities dataSource={user.activities || []} />
        </Col>
      </Row>
    </BaseLayout>
  )
}