import React, { Fragment, useState } from 'react'
import { Button, Modal, Form, Input, Select, notification, Tabs } from 'antd'
import { t } from 'utils/i18n'
import { onBlurInput, onChangeInput, beforSubmit } from 'utils/Logics'
import { debound } from 'utils'
import { searchUsers } from 'apis/users'
import { createTeam } from 'apis/teams'
import Register from '../Register'

const ModalCreateTeam = (props) => {
  const [visible, setVisible] = useState(false);
  const [teamData, setTeamData] = useState({});
  const [teamDataMessage, setTeamDataMessage] = useState({});
  const [users, setUsers] = useState([])
  const [user, setUser] = useState({})
  const [key, setKey] = useState("create_new_user")

  const _onOpenModal = () => setVisible(true);
  const _onHideModal = () => {
    setVisible(false)
    setUser({})
    setTeamData({})
  };

  const _onSearch = async (value) => {
    const response = await searchUsers({ where: JSON.stringify({ fullname: value }) });
    if (response.status === 200) {
      return setUsers(response.data)
    }
    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
  }

  const _onChange = (manageBy) => {
    setTeamData({ ...teamData, manageBy })
  }

  const _onOk = async () => {
    const body = beforSubmit({
      name: {
        value: teamData.name,
        setMessage: (name) => setTeamDataMessage({ ...teamDataMessage, name }),
        title: "Team name"
      },
      manageBy: (() => {
        if (key === "create_new_user") {
          return {
            value: user,
            setMessage: (manageBy) => setTeamDataMessage({ ...teamDataMessage, manageBy }),
            validate: (data) => {
              if (!data || Object.keys(data).length == 0) {
                return false
              }
              const isValidate = ['fullname', 'email', 'password', 'confirmPassword'].filter(label => !Object.keys(data).includes(label))
              if (isValidate.length > 0) {
                return t("Please input %1", isValidate.join(", "))
              }
              if (data.password !== data.confirmPassword) {
                return t("Password and confirm password not match")
              }
              if (teamDataMessage.manageBy !== "") {
                setTeamDataMessage({ ...teamDataMessage, manageBy: "" })
              }
              return true
            },
            title: "Manager"
          }
        }
        return {
          value: teamData.manageBy,
          setMessage: (manageBy) => setTeamDataMessage({ ...teamDataMessage, manageBy }),
          title: "Manager"
        }
      })()
    })
    if (!body) {
      return
    }

    delete body.manageBy.confirmPassword

    const response = await createTeam(body);

    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      if (props.onReload) {
        props.onReload()
      }
      setTeamData({})
      setUser({})
      return _onHideModal()
    }
    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
  }

  return (
    <Fragment>
      <Button type="primary" onClick={_onOpenModal}>
        {t("Create team")}
      </Button>
      <Modal
        cancelText={t("Cancel")}
        visible={visible}
        onCancel={_onHideModal}
        onOk={_onOk}
        title={t("Create team")}
        destroyOnClose
      >
        <Form>
          <Form.Item label={<strong>{t("Team name")}</strong>} required help={teamDataMessage.name}>
            <Input
              value={teamData.name}
              onChange={onChangeInput((name) => setTeamData({ ...teamData, name }), (name) => setTeamDataMessage({ ...teamDataMessage, name }))}
              onBlur={onBlurInput(teamData.name, (name) => setTeamDataMessage({ ...teamDataMessage, name }), "Team name")}
              maxLength={100}
            />
          </Form.Item>
          <Form.Item label={<strong>{t("Manager")}</strong>} required help={teamDataMessage.manageBy}>
            <Tabs activeKey={key} onChange={(key) => setKey(key)}>
              <Tabs.TabPane key={"create_new_user"} tab={t("Create new manager")}>
                <Register user={user} setUser={setUser} />
              </Tabs.TabPane>
              <Tabs.TabPane key={"assign_exist_user"} tab={t("Select user")}>
                <Select
                  showSearch
                  placeholder={t("Search...")}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  notFoundContent={null}
                  onSearch={debound(_onSearch, 500)}
                  onChange={_onChange}
                >
                  {
                    users.map((user) => (
                      <Select.Option value={user.id} key={user.id}>{`${user.fullname}`}</Select.Option>
                    ))
                  }
                </Select>
              </Tabs.TabPane>
            </Tabs>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}

export default ModalCreateTeam