import React, {useState, useEffect} from 'react';
import BaseLayout from 'components/BaseLayout';
import {Typography, Row, Table, Button, Icon, Modal, notification, Spin} from 'antd';
import {t} from 'utils/i18n';
import ModalCreateInformation from './ModalCreateInformation';
import EditInformation from './EditInformation';
import TitleTable from 'components/TitleTable';
import moment from 'moment';
import {getInformation, deleteInformation} from "apis/informations";


export default (props) => {
  const dateFormat = 'YYYY/MM/DD';
  const [loading, setLoading] = useState()
  const [dataInformation, setDataInformation] = useState([{
    date: `${moment('2015/01/02')}`,
    information: 'Hello',
    jpDescription: 'これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。 ',
    jpLink: 'https://blog.forecastinge.com',
    enLink: 'https://blog.forecastinge.com',
    enDescription: 'これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。 \'',
  }])
  
  useEffect(() => {
    _getDataInformation()
  }, [])
  const _getDataInformation = async () => {
    setLoading(true)
    const response = await getInformation()
    setLoading(false)
    if (response.status === 200) {
      setDataInformation(response.data)
    }
  }
  
  
  const _onDeleteInformation = (record) => () => {
    Modal.confirm({
      title: t("Are you sure to delete this information?"),
      onOk: async () => {
        const response = await deleteInformation(record.id);
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          _getDataInformation()
        } else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
      },
      onCancel: () => {
      }
    })
  }
  
  return (
    <BaseLayout hideMenu={false}>
      <Spin spinning={loading}>
        <Row type="flex" justify="space-between" align="middle" className="custom_row">
          <Typography.Title level={4}>
            {t("Information")}
          </Typography.Title>
          <span>
          <ModalCreateInformation onReload={_getDataInformation}/>
        </span>
        </Row>
        <Table
          pagination={false}
          size="small"
          dataSource={dataInformation}
        >
          <Table.Column
            title={<TitleTable showSort={false}>
              <div style={{fontSize: 18}}>{t("Date")}</div>
            </TitleTable>}
            key="date"
            dataIndex="date"
            width='150px'
            render={(text) => (
              <strong style={{fontSize: 14}}>{moment(text).format('YYYY-MM-DD')}</strong>
            )}
          
          />
          <Table.Column
            title={<TitleTable showSort={false}>
              <div style={{fontSize: 18}}>{t("Information")}</div>
            </TitleTable>}
            key="information"
            dataIndex="information"
            render={(text, record) => (
              <div>
                <div style={{fontSize: 16, whiteSpace: 'pre'}}>{record.enDescription}</div>
                <a style={{fontSize: 16, whiteSpace: 'pre'}}>{record.enLink}</a>
                <br/>
                <br/>
                <div style={{fontSize: 16}}>{record.jpDescription}</div>
                <a style={{fontSize: 16}}>{record.jpLink}</a>
              </div>
            )}
          />
          <Table.Column
            align="center"
            width="400px"
            render={(text, record) => (
              <Row type="flex" justify="end">
                <EditInformation record={record} onReload={_getDataInformation}/>
                <Button type="danger" onClick={_onDeleteInformation(record)} style={{marginLeft: 10}}>
                  <Icon type="delete"/>
                </Button>
              </Row>
            )}
          />
        </Table>
      </Spin>
    </BaseLayout>
  )
}