import { get, post, destroy, patch } from "utils/FetchAPI";

export const getListModels = (query) => get("/models", { ...query, team_id: localStorage.getItem("currentTeamId") || query.teamId });
export const getBillingData = (query) => get("/models/billing", { ...query, team_id: localStorage.getItem("currentTeamId") });

export const trainModel = (data) => post('/models/train', { ...data, team_id: localStorage.getItem("currentTeamId") });
export const clusterModel = (data) => post('/models/clustering', { ...data, team_id: localStorage.getItem("currentTeamId") });
export const svmModel = (data) => post('/models/one_class_svm', { ...data, team_id: localStorage.getItem("currentTeamId") });

export const testModel = (data) => post('/models/test', { ...data, team_id: localStorage.getItem("currentTeamId") });

export const deleteModel = (data) => destroy("/models", { ...data, team_id: localStorage.getItem("currentTeamId") });

export const updateModel = (data) => patch("/models", { ...data, team_id: localStorage.getItem("currentTeamId") })