import { get, patch, post } from "utils/FetchAPI";

export const getMyInfomation = () => get("/users/me")

export const updateMyInfomation = (data) => patch("/users/me", data)

export const updateMyPassword = (data) => patch("/users/me/password", data)

export const searchUsers = (data) => get("/users/search", data)

export const register = (data) => post("/users/register", data)

export const getActivities = () => get("/users/activities")

export const uploadAvatar = (formData) => post("/users/avatar", formData, { 'Content-type': "multipart/form-data" })

export const inviteRegister = (data, token) => post("/users/invition/register", data, { 'Authorization': "Bearer " + token })

export const inviteRegisterVerify = (token) => get("/users/invition/verify", {}, { 'Authorization': "Bearer " + token })

export const getAvatar = () => get('/users/avatar')

export const getOtherUserInfomation = (teamId, userId) => get('/users/' + userId, { teamId })