/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const DetailDataContext = createContext({
  update() {
  },
  version: -1,
});

export const DataLoader = {
  useContext() {
    const [version, setVersion] = useState(-1);
    const context = useMemo(() => {
      return { version, update: setVersion }
    }, [version, setVersion]);

    return {
      DataContextProvider: DetailDataContext.Provider,
      context
    }
  }
};

export const useLoadData = (loader, dependencies, ...args) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const reload = async () => {
    setLoading(true);
    try {
      const response = await loader(...args);
      if (!response) {
        return;
      }
      if (response.status === 200) {
        setData(response.data);
      }
      return response;
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    reload();
  }, dependencies || [])

  return { data, loading, reload };
};

export const useDataLoadVersionAware = (loader, dependencies, ...args) => {
  const { version: contextVersion } = useContext(DetailDataContext);
  const [localVersion, setLocalVersion] = useState(-1);
  const context = useLoadData(loader, dependencies, ...args);

  const _reload = context.reload;
  context.reload = async () => {
    const result = await _reload();
    setLocalVersion(new Date().getTime());
    return result;
  }

  useEffect(() => {
    if (contextVersion > localVersion) {
      context.reload();
    }
  }, [localVersion, contextVersion]);

  return context;
};
