import { Col, Form, Row, Select } from "antd";
import { getListProjects } from "apis/projects";
import React, { useEffect, useState } from "react";
import { t } from "utils/i18n";

export default ({
  projectId,
  setProjectId
}) => {
  const [loadingSelect, setLoadingSelect] = useState(false)
  const [listProject, setListProject] = useState([])
  const [numOfScroll, setNumOfScroll] = useState(1)

  useEffect(() => {
    _getListProjects(numOfScroll)
  }, [numOfScroll])

  const _getListProjects = async (page) => {
    setLoadingSelect(true)
    const response = await getListProjects({
      page,
      sort_by: 'updated_at',
      order: 'DESC'
    });
    if (response.status === 200) {
      setLoadingSelect(false)
      setListProject([...listProject, ...response.data.data])
    } else {
      setLoadingSelect(false)
    }
  }

  const handleScroll = (e) => {
    const isEndOfList = e.target.scrollTop + e.target.clientHeight;
    if (isEndOfList === e.target.scrollHeight) {
      setNumOfScroll(numOfScroll + 1)
    }
  }

  return (
    <Row style={{ width: "100%" }}>
      <Form layout="inline">
        <Form.Item
          label={t("Project")}
        >
          <Select
            value={projectId}
            onChange={setProjectId}
            loading={loadingSelect}
            onPopupScroll={handleScroll}
            style={{
              width: 200
            }}
            allowClear
          >
            {
              listProject.map(p => (
                <Select.Option value={p.project_id} key={p.project_id}>{`${p.name}`}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      </Form>
    </Row>
  )
}