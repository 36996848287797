import React, { useState, useEffect, useContext } from 'react';
import BaseLayout from 'components/BaseLayout';
import { Row, Form, Input, Button, Typography, Checkbox, Icon } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { onBlurInput, onChangeInput, beforSubmit } from 'utils/Logics';
import { ROUTES } from 'consts';
import { t } from 'utils/i18n';

import './style.less'
import { login } from 'apis/auth';
import Configs from 'components/ConfigProviders';
import { getList } from 'apis/teams';

export default (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("")
  const [emailMessage, setEmailMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  const [disabled, setDisabled] = useState(false)
  const { getListTeam } = useContext(Configs)

  useEffect(() => {
    if (emailMessage.trim() === "" && passwordMessage.trim() === "") {
      setDisabled(false)
    }
    else {
      setDisabled(true)
    }
  }, [emailMessage, passwordMessage])

  useEffect(() => {
    if (email.trim() !== "" || password.trim() !== "") {
      setLoginMessage("")
    }
  }, [email, password])

  const _onSubmit = async () => {
    const body = beforSubmit({
      email: {
        value: email,
        setMessage: setEmailMessage,
        title: "Email"
      },
      password: {
        value: password,
        setMessage: setPasswordMessage,
        isValidateValue: false,
        title: "Password"
      },
    }, setDisabled)
    if (!body) {
      return
    }
    const response = await login(body)
    if (response.status === 401) {
      history.push(ROUTES.LOGIN)
    }
    if (response.status === 200) {
      localStorage.setItem("fullname", response.data.fullname)
      localStorage.setItem("accessToken", response.data.accessToken)
      localStorage.setItem("refreshToken", response.data.refreshToken)
      if (response.data.lastAccessTeamId) {
        try {
          const lastAccessTeamId = response.data.lastAccessTeamId
          const teamRes = await getList({ limit: "unlimited" }, response.data.accessToken);
      
          if (teamRes.status === 200) {
            const teamsAvailable = teamRes.data.teams
            if(teamsAvailable.map(ele => ele.id).includes(lastAccessTeamId)) 
              localStorage.setItem("currentTeamId", lastAccessTeamId)
          }
        } catch (err) {
          console.error(err)
        }
      }
      await getListTeam({limit: "unlimited"}, response.data.accessToken)
      history.push(ROUTES.HOME)
    }
    else {
      setLoginMessage(t(response.data.message))
    }
  }

  return (
    <BaseLayout hideMenu={true} className="login_page public_page">
      <Row type="flex" justify="center">
        <Typography.Title className="title">
          {t("Login")}
        </Typography.Title>
      </Row>
      <Row type="flex" justify="center">
        <Form className="form_login">
          <Form.Item help={emailMessage}>
            <Input
              autoFocus
              value={email}
              onChange={onChangeInput(setEmail, setEmailMessage)}
              onBlur={onBlurInput(email, setEmailMessage, "Email")}
              prefix={<Icon type="user" />}
              placeholder={t("email")}
              onPressEnter={_onSubmit}
              maxLength={100}
            />
          </Form.Item>
          <Form.Item help={passwordMessage}>
            <Input
              value={password}
              onChange={onChangeInput(setPassword, setPasswordMessage)}
              onBlur={onBlurInput(password, setPasswordMessage, "Password", false)}
              prefix={<Icon type="lock" />}
              type="password"
              placeholder={t("password")}
              onPressEnter={_onSubmit}
              maxLength={100}
            />
          </Form.Item>
          <Form.Item help={loginMessage}>
            <Row type="flex" justify="center" style={{ paddingBottom: 5 }}>
              <Button
                type="primary"
                disabled={disabled}
                onClick={_onSubmit}
              >
                {t("Log in")}
              </Button>
            </Row>
            <Row type="flex" justify="center">
              {/* <Checkbox>{t("Remember me")}</Checkbox> */}
              <Link to={ROUTES.FORGOT_PASSWORD}>{t("Forgot password")}</Link>
            </Row>
          </Form.Item>
        </Form>
      </Row>
    </BaseLayout>
  )
}