import locales from 'locales'

const i18n = {
  lang: localStorage.getItem("lang") || "ja",
}

const newMessage = {}

export const t = (text, ...values) => {
  if(!text) return ""
  if (text.trim() === "") {
    return text
  }
  if (locales[i18n.lang][text] && locales[i18n.lang][text].trim() !== "") {
    if (values.length > 0) {
      return locales[i18n.lang][text].replace(/%(\d+)/g, (_, n) => values[+n - 1])
    }
    return locales[i18n.lang][text]
  }
  newMessage[text] = text
  if (values.length > 0) {
    return text.replace(/%(\d+)/g, (_, n) => values[+n - 1])
  }
  return text
}

export const setLang = (lang) => {
  i18n.lang = lang;
}

export { newMessage }