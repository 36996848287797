import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Icon, notification } from 'antd';
import { onChangeInput, onBlurInput, beforSubmit } from 'utils/Logics'
import { t } from 'utils/i18n';
import { updateMyInfomation } from 'apis/users';

// const handleModalOk = (data, setVisible) => {

// }

const onHideModal = (setVisible) => () => setVisible(false)

const onOpenModal = setVisible => () => setVisible(true)

export default (props) => {
  const [visible, setVisible] = useState(false)
  const [fullnameHelp, setFullnameHelp] = useState("")
  const [fullname, setFullname] = useState()

  useEffect(() => {
    setFullname(props.fullname)
  }, [props.fullname])

  const onOk = async () => {
    const body = beforSubmit({
      fullname: {
        value: fullname,
        setMessage: setFullnameHelp,
        title: "Fullname"
      }
    })
    if (!body) {
      return false
    }
    const response = await updateMyInfomation(body)
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      localStorage.setItem('fullname', body.fullname)
      props.setFullname(body.fullname)
      setVisible(false)
      setFullname("")
      return
    }
    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
  }

  return (
    <Fragment>
      <Button shape="circle" type="link" onClick={onOpenModal(setVisible)}>
        <Icon type="edit" />
      </Button>
      <Modal
        cancelText={t("Cancel")}
        title={t("Update User Name")}
        visible={visible}
        onCancel={onHideModal(setVisible)}
        onOk={onOk}
      >
        <Form>
          <Form.Item label={t("Full name")} required help={fullnameHelp}>
            <Input
              value={fullname}
              onChange={onChangeInput(setFullname, setFullnameHelp)}
              onBlur={onBlurInput(fullname, setFullnameHelp, 'Fullname')}
              maxLength={100}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}