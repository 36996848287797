import React, {useState, useEffect, useCallback} from 'react';
import {Button, Icon, Row, Input, notification, Modal, Form} from 'antd';
import './style.less'
import {limmitInput, getLimitInput} from 'utils';
import {t} from "utils/i18n";

/**
 * @param {Object} props
 * @param {Function} props.onSave
 * @param {Function} props.onClick
 * @param {Object} props.style
 */
export default (props) => {
  const [edit, setEdit] = useState(false)
  const [oldValue, setOldValue] = useState(props.children)
  const [value, setValue] = useState(props.children)
  
  const getTitle = useCallback((isData) => {
    switch(isData){
      case "data":
        return t("Data name")
      case "project":
        return t("Project name")
      case "model":
        return t("Model name")
      default:
        return t("Edit")
    }
  }, [t])
  
  useEffect(() => {
    setOldValue(props.children)
    setValue(props.children)
  }, [props.children, edit])

  useEffect(()=>{
    if(!edit) return
    setOldValue(props.children)
    setValue(props.children)
  }, [edit])
  
  const _onClick = () => {
    if (!value.trim() && props.isName && edit === true) {
      if (props.isName === 'data') {
        return notification.error({
          message: t("Error"),
          description: t("Please input the file name.")
        })
      } else if (props.isName === 'model') {
        return notification.error({
          message: t("Error"),
          description: t("Please input the model name.")
        })
      } else if (props.isName === 'project') {
        return notification.error({
          message: t("Error"),
          description: t("Please input the project name.")
        })
      }
    }
    
    if (edit && props.onSave && value !== oldValue) {
      const isSaveSuccess = props.onSave(value)
      if (isSaveSuccess instanceof Promise) {
        isSaveSuccess.then(value => {
          if (value === false) {
            setValue(oldValue)
          }
        })
      } else if (isSaveSuccess === false) {
        setValue(oldValue)
      }
    }
    setEdit(!edit)
  }

  const _onCancel = ()=>{
    setEdit(false);
  }
  
  const _onChange = (event) => {
    setValue(getLimitInput(event.target.value))
  }

  return (
    <Row className="span_editable" type="flex" justify="start" align="middle">
      <div
        className={props.isNoneEllipsis ? "button_none_ellipsis" : "button_ellipsis"}
        style={{
          ...props.style,
          display: "inline-block"
        }}
        onClick={props.onClick}
      >
        {props.children}
      </div>
      <span className="space"/>
      <Button
        shape="circle"
        className="button_edit"
        onClick={_onClick}
        size="small"
      >
        <Icon type="edit" style={props.buttonEditStyle}/>
      </Button>
      <Modal visible={edit} title={getTitle()} onCancel={_onCancel} onOk={_onClick}>
        <Form>
          <Form.Item
            label={getTitle(props.isName)}
            required
          >
            <Input.TextArea
              value={value}
              // style={{width:'100%'}}
              onChange={_onChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  )
  
  return (
    <Row className="span_editable" type="flex" justify="start" align="middle">
      {
        edit ?
          <span style={{paddingLeft: 15, width: '95%'}}>
            <Input.TextArea
              value={value}
              // style={{width:'100%'}}
              onChange={_onChange}
            />
          </span> :
          <div
            className={props.isNoneEllipsis ? "button_none_ellipsis" : "button_ellipsis"}
            style={props.style}
            onClick={props.onClick}
          >
            {value}
          </div>
      }
      <span className="space"/>
      <Button
        shape="circle"
        className="button_edit"
        onClick={_onClick}
      >
        <Icon type={edit ? "check-circle" : "edit"} style={props.buttonEditStyle}/>
      </Button>
    </Row>
  )
}