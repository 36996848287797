import {ROLE_LEVEL} from "consts";
import {Base64} from 'js-base64';
import numeral from "numeral";

export const decode = (token) => {
  if (typeof token !== 'string') {
    return false
  }
  if (token.split(".").length !== 3) {
    return false;
  }
  try {
    return JSON.parse(Base64.decode(token.split(".")[1]))
  } catch (error) {
    return false
  }
}
export const checkColor = (value, isTarget) => {
  if (value >= 0.7 && value <= 1) {
    return 'rgb(51, 204, 51)'
  } else if (value >= 0.4 && value < 0.7) {
    return 'rgb(152, 230, 152)'
  } else if (value >= 0.2 && value < 0.4) {
    return 'rgb(214, 245, 214)'
  } else if (value >= -1 && value < -0.7) {
    return 'rgb(255, 153, 51)'
  } else if (value >= -0.7 && value < -0.4) {
    return 'rgb(255, 191, 128)'
  } else if (value >= -0.4 && value <= -0.2) {
    return 'rgb(255, 230, 204)'
  } else if (isTarget) {
    return '#bfbfbf'
  } else return '#f2f2f2';
  
  
}

export const checkRole = (token, protect) => {
  if (!protect) {
    return true
  }
  
  const payload = decode(token);
  
  if (!payload) {
    return false;
  }
  
  if (protect.must) {
    if (payload.role === protect.must) {
      return true
    }
    return false
  }
  
  if (protect.role) {
    if (ROLE_LEVEL[payload.role] >= ROLE_LEVEL[protect.role]) {
      return true
    }
  }
  
  return false
}

export const validate = (field, data) => {
  if (field && field.toLowerCase().includes("email")) {
    return typeof data === 'string' ? data.match(/[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}/igm) : false
  }
  if (field && field.toLowerCase().includes("password")) {
    return typeof data === 'string' ? data.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/g) : false
  }
  return true
}

export const debound = (func, timeout = 500) => {
  let fnTimeout;
  return (...rest) => {
    if (fnTimeout) {
      clearTimeout(fnTimeout);
    }
    fnTimeout = setTimeout(() => {
      func(...rest)
    }, timeout);
  }
}

/**
 *
 * @param {String} string
 */
export const limmitInput = (string = "") => {
  if (!string || typeof string !== 'string') {
    return true;
  }
  const reg = /[0-9a-zA-Z -/:-@]/g
  const lengthLatinString = string.match(reg) && string.match(reg).length || 0
  const lengthUTF8String = string.replace(reg, "").length * 2
  return (lengthLatinString + lengthUTF8String) <= 100
}

export const getLimitInput = (string = "") => {
  if (!string || typeof string !== 'string') {
    return string;
  }
  const reg = /[0-9a-zA-Z -/:-@]/g
  let tokenCount = 0;
  return string
    .split("")
    .filter((token) => {
      const count = token.match(reg) ? 1 : 2;
      tokenCount += count;
      if (tokenCount <= 100) {
        return true
      }
      return false
    })
    .join("")
  
}

export const convertDataViewNumber = (data) => {
  return data.map((data, index) => {
    let newData = {};
    
    newData["countlv1"] = numeral(data["Count"]).format('0,0');
    newData["countlv2"] = numeral(data["Missing Value"]["Count"]).format('0,0');
    newData["rate"] = data["Missing Value"]["Rate(%)"];
    newData["unique"] = numeral(data["Unique Count"]).format('0,0');
    newData["max"] = numeral(data["MAX"]).format('0,0');
    newData["min"] = numeral(data["MIN"]).format('0,0');
    newData["mean"] = numeral(data["Mean"]).format('0,0');
    newData["median"] = numeral(data["Median"]).format('0,0');
    newData["mode"] = numeral(data["Mode"]).format('0,0');
    newData["plus2d"] = numeral(data["+2D"]).format('0,0');
    newData["sd"] = numeral(data["SD"]).format('0,0');
    newData["minus2d"] = numeral(data["-2D"]).format('0,0');
    newData["25"] = numeral(data["25%"]).format('0,0');
    newData["50"] = numeral(data["50%"]).format('0,0');
    newData["75"] = numeral(data["75%"]).format('0,0');
    newData["column"] = data["column"];
    newData["histogram"] = JSON.parse(data["Histogram"]);
    
    return newData;
  })
}
export const convertDataViewString = (data) => {
  return data.map((data, index) => {
    let newData = {};
    newData["countlv1"] = numeral(data["Count"]).format('0,0');
    newData["countlv2"] = numeral(data["Missing Value"]["Count"]).format('0,0');
    newData["rate"] = data["Missing Value"]["Rate(%)"];
    newData["histogram"] = JSON.parse(data["Histogram"]);
    newData["unique"] = numeral(data["Unique Count"]).format('0,0');
    newData["rank1value"] = data["1st Rank"]["Value"];
    newData["rank1count"] = numeral(data["1st Rank"]["Count"]).format('0,0');
    newData["rank2value"] = data["2nd Rank"]["Value"];
    newData["rank2count"] = numeral(data["2nd Rank"]["Count"]).format('0,0');
    newData["rank3value"] = data["3rd Rank"]["Value"];
    newData["rank3count"] = numeral(data["3rd Rank"]["Count"]).format('0,0');
    newData["rank4value"] = data["4th Rank"]["Value"];
    newData["rank4count"] = numeral(data["4th Rank"]["Count"]).format('0,0');
    newData["rank5value"] = data["5th Rank"]["Value"];
    newData["rank5count"] = numeral(data["5th Rank"]["Count"]).format('0,0');
    
    newData["column"] = data["column"];
    
    return newData;
  })
}
export const convertDataViewStringDetail = (data) => {
  let newData = {};
  newData["countlv1"] = numeral(data["Count"]).format('0,0');
  newData["countlv2"] = numeral(data["Missing Value"]["Count"]).format('0,0');
  newData["rate"] = data["Missing Value"]["Rate(%)"];
  newData["unique"] = numeral(data["Unique Count"]).format('0,0');
  
  newData["rank1value"] = data["1st Rank"]["Value"];
  newData["rank1count"] = numeral(data["1st Rank"]["Count"]).format('0,0');
  newData["rank2value"] = data["2nd Rank"]["Value"];
  newData["rank2count"] = numeral(data["2nd Rank"]["Count"]).format('0,0');
  newData["rank3value"] = data["3rd Rank"]["Value"];
  newData["rank3count"] = numeral(data["3rd Rank"]["Count"]).format('0,0');
  newData["rank4value"] = data["4th Rank"]["Value"];
  newData["rank4count"] = numeral(data["4th Rank"]["Count"]).format('0,0');
  newData["rank5value"] = data["5th Rank"]["Value"];
  newData["rank5count"] = numeral(data["5th Rank"]["Count"]).format('0,0');
  
  newData["column"] = data["column"];
  
  return newData;
}
export const convertDataStatistic = (data) => {
  let newData = {};
  newData["countlv1"] = numeral(data["Count"]).format('0,0');
  newData["countlv2"] = numeral(data["Missing Value"]["Count"]).format('0,0');
  newData["ratelv2"] = data["Missing Value"]["Rate(%)"];
  newData["ratelv1"] = data["Rate(%)"];
  newData["unique"] = numeral(data["Unique Count"]).format('0,0');
  newData["max"] = numeral(data["MAX"]).format('0,0');
  newData["min"] = numeral(data["MIN"]).format('0,0');
  newData["mean"] = numeral(data["Mean"]).format('0,0');
  newData["median"] = numeral(data["Median"]).format('0,0');
  newData["mode"] = numeral(data["Mode"]).format('0,0');
  newData["plus2d"] = numeral(data["+2D"]).format('0,0');
  newData["sd"] = numeral(data["SD"]).format('0,0');
  newData["minus2d"] = numeral(data["-2D"]).format('0,0');
  newData["25"] = numeral(data["25%"]).format('0,0');
  newData["50"] = numeral(data["50%"]).format('0,0');
  newData["75"] = numeral(data["75%"]).format('0,0');
  newData["column"] = data["column"];
  return newData;
}
export const convertDataMissingValue = (data) => {
  let newData = {};
  newData["countlv1"] = numeral(data["Count"]).format('0,0');
  newData["countlv2"] = numeral(data["Missing Value"]["Count"]).format('0,0');
  newData["rate"] = data["Missing Value"]["Rate(%)"];
  newData["count0"] = numeral(data['"0" count']).format('0,0');
  newData["count1"] = numeral(data['"1" count']).format('0,0');
  newData["minuscount"] = numeral(data['minus count']).format('0,0');
  newData["check999"] = numeral(data['999.... check']).format('0,0');
  newData["rank1value"] = data["1st Rank"]["Value"];
  newData["rank1count"] = numeral(data["1st Rank"]["Count"]).format('0,0');
  newData["rank2value"] = data["2nd Rank"]["Value"];
  newData["rank2count"] = numeral(data["2nd Rank"]["Count"]).format('0,0');
  newData["rank3value"] = data["3rd Rank"]["Value"];
  newData["rank3count"] = numeral(data["3rd Rank"]["Count"]).format('0,0');
  newData["rank4value"] = data["4th Rank"]["Value"];
  newData["rank4count"] = numeral(data["4th Rank"]["Count"]).format('0,0');
  newData["rank5value"] = data["5th Rank"]["Value"];
  newData["rank5count"] = numeral(data["5th Rank"]["Count"]).format('0,0');
  
  newData["column"] = data["column"];
  
  return newData;
}
export const convertDataCorrelation = (column, data) => {
  return column.map((data1, index) => {
    return [column[index]].concat(data[index])
  });
}
export const checkValidate = (test, object) => {
  
  let b = test.filter(function (record) {
    return record.project_id === object.project_id
  })
  return (b.length > 0)
}
export const checkHttpLink = (link) => {
  if (/^htt(p|ps)\:\/\//.test(link)) {
    return link
  }
  return `http://${link}`
}

export const formatDataHomeChart = (dataApi) => {
  let data = [];
  let model = [];
  let project = [];
  let teams = [];
  for (let record in dataApi) {
    switch (record) {
      case 'three_week_ago': {
        data[0] = {x: 1, y: dataApi[record].data}
        model[0] = {x: 1, y: dataApi[record].model}
        project[0] = {x: 1, y: dataApi[record].project}
        teams[0] = {x: 1, y: dataApi[record].teams}
      }
        break;
      case 'two_week_ago': {
        data[1] = {x: 2, y: dataApi[record].data}
        model[1] = {x: 2, y: dataApi[record].model}
        project[1] = {x: 2, y: dataApi[record].project}
        teams[1] = {x: 2, y: dataApi[record].teams}
      }
        break;
      case 'last_week': {
        data[2] = {x: 3, y: dataApi[record].data}
        model[2] = {x: 3, y: dataApi[record].model}
        project[2] = {x: 3, y: dataApi[record].project}
        teams[2] = {x: 3, y: dataApi[record].teams}
      }
        break;
      case 'this_week': {
        data[3] = {x: 4, y: dataApi[record].data}
        model[3] = {x: 4, y: dataApi[record].model}
        project[3] = {x: 4, y: dataApi[record].project}
        teams[3] = {x: 4, y: dataApi[record].teams}
      }
        break;
      
    }
  }
  return {
    data,
    model,
    project,
    teams
  }
}

