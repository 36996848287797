import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Table, Tag, Button, Select, Modal, notification, Pagination, Row, Icon, Tooltip } from 'antd';
import TitleTable from 'components/TitleTable';
import { t } from 'utils/i18n';
import moment from 'moment';
import { getListModels, deleteModel, updateModel } from 'apis/models';
import { getListProjects } from 'apis/projects';
import ModalPredict from './ModalPredict';
import { MODEL_STATUS } from 'consts';
import Configs from 'components/ConfigProviders';
import SpanEditable from 'components/SpanEditable';
import numeral from "numeral";
import './style.less'

const initialQuery = {
  sort_by: 'updated_at',
  order: "DESC"
}

const TableModel = (props) => {
  const [listModels, setListModels] = useState({});
  const [projects, setProjects] = useState([]);
  const [query, setQuery] = useState(initialQuery)
  const { team } = useContext(Configs)
  const [numOfScroll, setNumOfScroll] = useState(1)
  const [loadingSelect, setLoadingSelect] = useState(false)

  useEffect(() => {
    _getListProjects()
  }, [team])

  useEffect(() => {
    if (props.data_id) {
      query.data_id = props.data_id
    }
    if (props.model_id) {
      query.model_id = props.model_id
    }

    if (localStorage.getItem("currentTeamId")) {
      _getListModels(query).then(() => {
        props.setReloadData(0)
      })

    }

  }, [props.data_id, props.projectId, props.reloadData, query, team, props.model_id])
  useEffect(() => {
    _getListProjectsPage(numOfScroll)
  }, [numOfScroll])

  const _getListProjects = async () => {
    const response = await getListProjects({
      sort_by: 'updated_at',
      order: 'DESC'
    });
    if (response.status === 200) {
      setProjects(response.data.data)
    }
  }


  const _getListProjectsPage = async (page) => {
    setLoadingSelect(true)
    const response = await getListProjects({
      sort_by: 'updated_at',
      order: 'DESC',
      page
    });
    if (response.status === 200) {
      setLoadingSelect(false)
      setProjects([...projects, ...response.data.data])
    } else {
      setLoadingSelect(false)
    }
  }

  const _getListModels = async (_query) => {
    const query = {
      ..._query,
      project_id: props.projectId
    };
    const response = await getListModels(query);
    if (response.status === 200) {
      setListModels(response.data);
    } else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  const _onDeleteModel = (record) => () => {
    Modal.confirm({
      title: t("Are you sure to delete this model"),
      onCancel: () => {
      },
      onOk: async () => {
        const response = await deleteModel({
          model_id: record.model_id,
          project_id: record.project_id
        })
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          _getListModels(query)
        } else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
      }
    })
  }

  const _onSort = (field) => order => {
    setQuery({
      ...query,
      sort_by: field,
      order
    })
  }

  const _onChangePage = (page) => {
    setQuery({
      ...query,
      page
    })
  }
  const handleScroll = (e) => {

    const isEndOfList = e.target.scrollTop + e.target.clientHeight;

    if (isEndOfList === e.target.scrollHeight) {

      setNumOfScroll(numOfScroll + 1)
    }
  }
  const onChangeModel = (record, field) => async (value) => {
    const response = await updateModel({
      model_id: record.model_id,
      params: [
        {
          field,
          value
        }
      ]
    })
    if (response.status === 200) {
      notification.success({
        message: t("Success"),
      })
      _getListModels(query)
      return true
    } else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
      return false
    }
  }

  return (
    <Fragment>
      <Table
        className="table-model"
        size="small"
        dataSource={listModels.data}
        pagination={false}
        scroll={{
          x: 1500
        }}
      >
        <Table.Column
          title={<TitleTable onSort={_onSort("name")}>{t("Model_Name")}</TitleTable>}
          key="name"
          dataIndex="name"
          width="260px"
          fixed="left"
          ellipsis
          render={(text, record) => (
            <SpanEditable
              onSave={onChangeModel(record, 'name')}
              isNoneEllipsis={true}
              isName="model"
              style={{
                color: "rgba(0, 0, 0, 0.85)"
              }}
            >
              {text}
            </SpanEditable>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("type")}>{t("Type")}</TitleTable>}
          key="type"
          dataIndex="type"
          width="10%"
          ellipsis
          render={(text, record) => {
            let splitText = text.split('_')
            if (splitText[0] === 'One Class SVM' && splitText[1]) {
              return <span>{`${t(splitText[0])} (${Number(splitText[1] * 100)}%)`}</span>
            } else if (splitText[0] === 'Clustering' && splitText[1]) {
              return <span>{`${t(splitText[0])} (${Number(splitText[1])})`}</span>
            } else return <span>{t(text)}</span>
          }}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("accuracy")}>{t("Accuracy")}</TitleTable>}
          key="accuracy"
          dataIndex="accuracy"
          width="10%"
          ellipsis
          render={(text, record) => {
            if (!Number(text)) {
              return <span>-</span>
            } else {
              return <span>{record.type === "Classification" ? `${Math.ceil(Number(text) * 10000) / 100}%` : numeral(Number(text)).format('0,0.00')}</span>
            }
          }}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("project_id")}>{t("Project")}</TitleTable>}
          key="project_id"
          dataIndex="project_name"
          width="12%"
          ellipsis
          render={(text, record) => (
            <Select
              loading={loadingSelect}
              onPopupScroll={handleScroll}
              value={text}
              onChange={onChangeModel(record, 'project_id')}
              className="fullsize_selectbox"
            >
              {
                projects.map(p => (
                  <Select.Option value={p.project_id} key={p.project_id}>{`${p.name}`}</Select.Option>
                ))
              }
            </Select>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("data_name")}>{t("Training data")}</TitleTable>}
          key='data_name'
          dataIndex="data_name"
          width="12%"
          ellipsis
          render={(text, record) => (
            <div className="text-ellipsis">{record.data_id ? `${record.data_name}` : t("Deleted")}</div>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("updated_at")}>{t("Updated at")}</TitleTable>}
          key="updated_at"
          dataIndex="updated_at"
          width="10%"
          ellipsis
          render={(text) => (
            <strong>{moment(text).format("YYYY-MM-DD HH:mm")}</strong>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("updated_by")}>{t("Update by")}</TitleTable>}
          key="updated_by"
          dataIndex="updated_by"
          width="10%"
          ellipsis
          render={(text, record) => (
            <Tooltip overlay={<span>{text + "" === "0" ? "API User" : record.editor}</span>}>
              <div className="ellipsis_text"><strong>{text + "" === "0" ? "API User" : record.editor}</strong></div>
            </Tooltip>
          )}
        />
        <Table.Column
          title={<TitleTable onSort={_onSort("train_time")}>{t("Train Time")}</TitleTable>}
          key="train_time"
          dataIndex="train_time"
          ellipsis
          render={(text) => (
            <strong>{text && (text + " " + t("Mins"))}</strong>
          )}
        />
        <Table.Column
          ellipsis
          render={(text, record) => (
            <Row type="flex" justify="end" align="middle">
              {
                record.status && Number(record.status) === 2 ?
                  <Tooltip placement="bottomRight" title={record.error_message}>
                    <Tag id={record.id + "error_message"}
                      color={MODEL_STATUS[Number(record.status)].color}>{t(MODEL_STATUS[Number(record.status)].title)}</Tag>
                  </Tooltip> :
                  <Tag
                    color={MODEL_STATUS[Number(record.status)].color}>{t(MODEL_STATUS[Number(record.status)].title)}</Tag>
              }
              <Button
                size="small"
                type="danger"
                onClick={_onDeleteModel(record)}
                style={{ marginRight: 7 }}
              >
                <Icon type="delete" />
              </Button>
              <ModalPredict model={record} />
            </Row>
          )}
          align="right"
          width="200px"
          fixed="right"
        />
      </Table>
      <Row type="flex" justify="end" style={{ paddingTop: 5 }}>
        <Pagination size="small" pageSize={20} showQuickJumper total={listModels.totalRecord || 0}
          current={listModels.page || 1} onChange={_onChangePage} />
      </Row>
    </Fragment>
  )
}

export default TableModel