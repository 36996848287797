import React, { useEffect, useState } from 'react';
import BaseLayout from 'components/BaseLayout';
import { useLocation, useHistory } from 'react-router-dom';
import querystring from 'querystring'
import { ROUTES } from 'consts';
import { decode } from 'utils';
import { Row, Typography, Form, Input, Button, notification, Col } from 'antd';
import { t } from 'utils/i18n';
import { onChangeInput, onBlurInput, beforSubmit } from 'utils/Logics';
import './style.less'
import { parse } from 'querystring'
import { inviteRegister, inviteRegisterVerify } from 'apis/users';

export default (props) => {
  const location = useLocation();
  const history = useHistory()
  const { token } = parse(location.search.slice(1))
  const payload = decode(token);

  if (!payload) {
    history.push(ROUTES.LOGIN)
  }

  const [register, setRegister] = useState({})
  const [message, setUserMessage] = useState({})

  useEffect(() => {
    verify()
  }, [])

  const verify = async () => {
    const response = await inviteRegisterVerify(token);
    if (response.status !== 200) {
      history.push(ROUTES.LOGIN)
    }
  }

  const _handleSignUp = async () => {
    const body = beforSubmit({
      fullname: {
        value: register.fullname,
        setMessage: (fullname) => setUserMessage({ ...message, fullname }),
        title: "Full name"
      },
      password: {
        value: register.password,
        setMessage: (password) => setUserMessage({ ...message, password }),
        title: "Password"
      },
      confirmPassword: {
        value: register.confirmPassword,
        setMessage: (confirmPassword) => setUserMessage({ ...message, confirmPassword }),
        title: "Confirm password"
      },
    })
    if (!body) {
      return false
    }

    if (body.password !== body.confirmPassword) {
      setUserMessage({ ...message, confirmPassword: t("Password and confirm password not match") })
      return false
    }

    delete body.confirmPassword

    const response = await inviteRegister(body, token);

    if (response.status === 200) {
      notification.success({
        message: t('Success'),
        description: t("Go back to login")
      })
      setTimeout(() => {
        history.push(ROUTES.LOGIN)
      }, 2000);
    }
    else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }

  return (
    <BaseLayout hideMenu={true} className="reset_password_page public_page">
      <div>
        <Row type="flex" justify="center">
          <Typography.Title style={{ fontSize: 28 }}>
            {t("Register")}
          </Typography.Title>
        </Row>
        <Row>
          <Col span={8}>
            <span style={{ color: "black" }}>{t("Team")}</span>
          </Col>
          <Col>
            <span>: {payload.teamName}</span>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <span style={{ color: "black" }}>{t("Email")}</span>
          </Col>
          <Col>
            <span>: {payload.email}</span>
          </Col>
        </Row>
        <Form style={{ width: 400 }}>
          <Form.Item label={t("Fullname")} help={message.fullname} >
            <Input
              value={register.fullname}
              onChange={onChangeInput((fullname) => setRegister({ ...register, fullname }), (fullname) => setUserMessage({ ...message, fullname }))}
              onBlur={onBlurInput(register.fullname, (fullname) => setUserMessage({ ...message, fullname }), "Fullname")}
            />
          </Form.Item>
          <Form.Item label={t("Password")} help={message.password} >
            <Input.Password
              value={register.password}
              onChange={onChangeInput((password) => setRegister({ ...register, password }), (password) => setUserMessage({ ...message, password }))}
              onBlur={onBlurInput(register.password, (password) => setUserMessage({ ...message, password }), "Password", true)}
            />
          </Form.Item>
          <Form.Item label={t("Confirm password")} help={message.confirmPassword}>
            <Input.Password
              value={register.confirmPassword}
              onChange={onChangeInput((confirmPassword) => setRegister({ ...register, confirmPassword }), (confirmPassword) => setUserMessage({ ...message, confirmPassword }))}
              onBlur={onBlurInput(register.confirmPassword, (confirmPassword) => setUserMessage({ ...message, confirmPassword }), "Confirm password", true)}
            />
          </Form.Item>
        </Form>
        <Row type="flex" justify="center">
          <Button type="primary" onClick={_handleSignUp}>
            {t("Sign up")}
          </Button>
        </Row>
      </div>
    </BaseLayout>
  )
}