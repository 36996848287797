import { get, post, patch, destroy } from 'utils/FetchAPI'

export const getList = (query, token) => get('/teams', query, token ? { 'Authorization': "Bearer " + token } : null)

export const getTeamInfomation = (id, query) => get('/teams/' + id, query)

export const getUserAccessToken = (id) => get(`/teams/${id}/user-access-token`)

export const createTeam = data => post('/teams', data)

export const inviteUser = (id, data) => post('/teams/' + id + '/invite-users', { ...data, language: localStorage.getItem("lang") })

export const acceptInvited = (id) => patch('/teams/' + id + '/accept-invited')

export const searchTeams = (data) => get('/teams/search', data)

export const updateTeam = (data, id) => patch('/teams/' + id, data)

export const deleteTeam = (id) => destroy('/teams/' + id)

export const generateTeamToken = (id) => post('/teams/' + id + '/access-token')

export const generateUserTeamToken = (id) => post(`/teams/${id}/user-access-token`)

export const enableAPI = (id) => patch('/teams/' + id + "/enable-api")