import React, {Fragment, useState, useEffect} from 'react';
import {Button, Modal, Form, Input, Select, Row, Radio, notification, Col} from 'antd';
import {t} from 'utils/i18n';
import {onChangeInput, onBlurInput, beforSubmit} from 'utils/Logics';
import {searchDatas} from 'apis/datas';
import {checkValidate, debound} from 'utils';
import {testModel} from 'apis/models';
import DataSheet from 'components/DataSheet';
import {LABELS} from 'consts';
import {getListProjects, searchProjects} from 'apis/projects';

const ModalPredict = (props) => {
  const [visible, setVisible] = useState(false)
  const [models, setModels] = useState({
    name: props.model.name,
    result_only: false
  })
  const [numScroll, setNumScroll] = useState(1)
  const [message, setMessage] = useState({})
  const [datas, setDatas] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [csv, setCsv] = useState("")
  const [project, setProject] = useState(props.model && props.model.project_id)
  const [projects, setProjects] = useState([])
  const [label, setLabel] = useState("data_predict")
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (visible) {
      _onSearch()
      
      if (props.model) {
        _getListProjects([
          {
            name: props.model.project_name,
            project_id: props.model.project_id
          }
        ])
        setProject(props.model && props.model.project_id)
      }
    }
  }, [visible, project, label, props.model])
  
  useEffect(() => {
    if (visible) {
      _getListProjectsPage(numScroll)
    }
  }, [numScroll])
  
  
  const _getListProjectsPage = async (page) => {
    setLoading(true)
    const response = await getListProjects({
      page,
      filter:'model'
    });
    if (response.status === 200) {
      setLoading(false)
      setProjects([...projects, ...response.data.data].filter((record) => record.created_at))
    } else {
      setLoading(false)
    }
  }
  const _setMessage = (field) => (m) => setMessage({...message, [field]: m})
  const _setFieldModels = (field) => (value) => setModels({...models, [field]: value})
  const _setFieldModelsPredictName = (value) => setModels({...models, ['predictedName']: value})
  
  const _onHideModal = () => {
    setVisible(false)
    setModels({
      name: props.model.name,
      result_only: false
    })
    setMessage({})
    setCsv("")
  }
  const _onOpenModal = () => setVisible(true)
  
  const _onSearch = async (value) => {
    if (value === undefined || value.trim() !== "") {
      const response = await searchDatas({
        name: value, project_id: project, label
      });
      if (response.status === 200) {
        setDatas(response.data)
      } else notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }
  
  const _getListProjects = async (data) => {
    const response = await getListProjects({
      filter:'model'
    });
    if (response.status === 200) {
      if (checkValidate(response.data.data, data[0])) {
        setProjects(response.data.data)
      } else {
        setProjects(response.data.data.concat(data))
      }
    }
  }
  const _onSearchProject = async (value) => {
    if (value === undefined || value.trim() !== "") {
      const response = await searchProjects({name: value});
      if (response.status === 200) {
        setProjects(response.data)
      } else notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }
  
  const _onChangeDataName = (value) => {
    const data = datas.filter(d => Number(d.data_id) === Number(value)).pop();
    const dataSelect = datas.filter(d => Number(d.data_id) === Number(value));
    _setFieldModelsPredictName(dataSelect)
    const featuresData = data.features.split(",")
    const featuresModel = props.model.features.split(",")
    if (Math.abs(featuresData.length - featuresModel.length) > 1) {
      _setMessage("data_id")(t("Item count is different"))
    } else {
      let _message = ""
      for (const feature of featuresModel) {
        if (!featuresData.includes(feature)) {
          _message = (t("Item name is different"))
          break;
        }
      }
      _setMessage("data_id")(_message)
    }
    setModels({...models,predictedName:`${dataSelect[0].name}${t("_result")}`, data_id: value})
  }
  
  const _handleOk = async () => {
    if (csv.trim() !== "") {
      const element = document.createElement("a");
      const file = new Blob(['\ufeff' + csv], {type: 'text/csv'});
      element.href = URL.createObjectURL(file);
      element.download = models.predictedName + ".csv";
      document.body.appendChild(element);
      element.click();
      return _onHideModal()
    }
    const body = beforSubmit({
      model_id: {
        value: props.model.model_id,
      },
      data_id: {
        value: models.data_id,
        setMessage: _setMessage("data_id"),
        title: "Predict data",
        validate: (value) => {
          const data = datas.filter(d => Number(d.data_id) === Number(value)).pop();
          const featuresData = data.features.split(",")
          const featuresModel = props.model.features.split(",")
          if (featuresData.length - featuresModel.length > 1) {
            return t("Item count is different")
          } else {
            let _message = "";
            for (const feature of featuresModel) {
              if (!featuresData.includes(feature)) {
                _message = t("Item name is different")
              }
            }
            if (_message !== "") {
              return _message
            }
            return true
          }
        }
      },
      result_only: {
        value: models.result_only
      },
      name: {
        value: models.predictedName,
        setMessage: _setMessage("predictedName"),
        title: "Predicted results name"
      }
    })
    if (!body) {
      return
    }
    
    setConfirmLoading(true)
    const response = await testModel(body);
    setConfirmLoading(false)
    
    if (response.status === 200) {
      setCsv(response.data)
      
      return notification.success({
        message: t("Success")
      })
    }
    notification.error({
      message: t("Error"),
      description: t(response.data.message || "Server Error")
    })
  }
  const handleScroll = (e) => {
    
    const isEndOfList = e.target.scrollTop + e.target.clientHeight;
    
    if (isEndOfList === e.target.scrollHeight) {
      
      setNumScroll(numScroll + 1)
    }
  }
  return (
    <Fragment>
      <Button
        size="small"
        type="primary"
        onClick={_onOpenModal}
        disabled={Number(props.model.status) !== 1}
      >
        {t("Predict")}
      </Button>
      <Modal
        visible={visible}
        onCancel={_onHideModal}
        onOk={_handleOk}
        okText={csv.trim() === "" ? undefined : t("Download")}
        cancelText={csv.trim() === "" ? undefined : t("Hide")}
        title={csv.trim() === "" ? t("Predict") : models.predictedName}
        confirmLoading={confirmLoading}
        width={csv.trim() === "" ? "600px" : "80%"}
        style={{top: csv.trim() === "" ? undefined : "8vh"}}
      >
        {
          csv.trim() === "" ?
            <Fragment>
              <Form>
                <Form.Item label={t("Project")}>
                  <Select
                    value={project}
                    onChange={(value) => setProject(value)}
                    className="fullsize_selectbox"
                    showSearch
                    placeholder={t("Search project by name...")}
                    onSearch={debound(_onSearchProject, 500)}
                    loading={loading}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    notFoundContent={null}
                    onPopupScroll={handleScroll}
                  >
                    {
                      projects.map(p => (
                        <Select.Option value={p.project_id} key={p.project_id}>{`${p.name}`}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item label={t("Model name")} required>
                  <Input
                    value={models.name}
                    disabled
                    maxLength={100}
                  />
                </Form.Item>
              </Form>
              <strong>{t("Predict data")}</strong>
              <Row gutter={[8, 0]}>
                <Col span={6}>
                  <Form>
                    <Form.Item label={t("Label")}>
                      <Select
                        value={label}
                        onChange={(value) => setLabel(value)}
                        className="fullsize_selectbox"
                      >
                        {
                          Object.values(LABELS).map(label => (
                            <Select.Option value={label} key={label}>{`${t(label)}`}</Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={18}>
                  <Form>
                    <Form.Item label={t("Data name")} required help={message.data_id}>
                      <Select
                        placeholder={t("Search by name...")}
                        showSearch
                        onSearch={debound(_onSearch, 500)}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        notFoundContent={null}
                        onChange={_onChangeDataName}
                        onBlur={onBlurInput(models.data_id, _setMessage("data_id"), "Predict data")}
                        value={models.data_id}
                      >
                        {
                          datas.map(d => (
                            <Select.Option value={d.data_id} key={d.data_id}>{`${d.name}`}</Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <strong>{t("Prediction result data name:")}</strong>
              <Row gutter={[8, 0]}>
                <Col span={6}>
                  <Form>
                    <Form.Item label={t("Label")}>
                      <Input value={t("predict_result")} disabled maxLength={100}/>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={18}>
                  <Form>
                    <Form.Item label={t("Data name")} required help={message.predictedName}>
                      <Input
                        value={models.predictedName}
                        onChange={onChangeInput(_setFieldModels("predictedName"), _setMessage("predictedName"))}
                        onBlur={onBlurInput(models.predictedName, _setMessage("predictedName"), "Predicted results name")}
                        maxLength={100}
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Row type="flex" justify="space-around">
                <Radio.Group
                  value={models.result_only}
                  onChange={onChangeInput(_setFieldModels("result_only"), _setMessage("result_only"))}
                >
                  <Radio value={false}>{t("Bỏ x")}</Radio>
                  <Radio value={true}>{t("Giữ x")}</Radio>
                </Radio.Group>
              </Row>
            </Fragment> :
            <DataSheet csv={csv}/>
        }
      </Modal>
    </Fragment>
  )
}

export default ModalPredict