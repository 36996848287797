import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Row, Col, Typography, Divider, Spin, notification, Pagination, Tabs } from 'antd';

import { t } from 'utils/i18n';
import ModalActivities from 'components/ModalActivities';
import Timelines from 'components/Timelines';
import ListUsers from 'components/ListUsers';
import BaseLayout from 'components/BaseLayout';
import { useHistory } from 'react-router-dom';
import { getTeamInfomation, getUserAccessToken } from 'apis/teams';
import ModalInviteUser from './ModalInviteUser';
import { decode } from 'utils';
import Configs from 'components/ConfigProviders';
import { ROUTES, TOKEN_TYPE } from 'consts';
import GenerateAccessToken from 'components/GenerateAccessToken';
import BillingTab from "./BillingTab"

const WrappingTabs = ({
  isWrap,
  children
}) => {
  if (!isWrap) {
    return children
  }
  return (
    <Tabs>
      {
        React.Children.map(children, (child, index) => (
          child === '' ? null : <Tabs.TabPane tab={child.props.tab}>
            {child}
          </Tabs.TabPane>
        ))


      }
    </Tabs>
  )
}

const DetailTeam = (props) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState({
    users: [],
    activities: []
  })
  const [userAccessToken, setUserAccessToken] = useState(null)
  const [isManager, setIsManager] = useState(false)
  const config = useContext(Configs)
  const [query, setQuery] = useState({ limit: 10 })
  const actorId = decode(localStorage.getItem("accessToken")).id;

  const _onChangePage = (page) => {
    if (Number(page)) {
      setQuery({ ...query, page: Number(page) })
    }
  }

  useEffect(() => {
    setLoading(true);
    history.push(ROUTES.DETAIL_TEAM.replace(":id", localStorage.getItem("currentTeamId")))
    Promise.all([
      _getTeamInfomation(localStorage.getItem("currentTeamId")),
      _getUserAccessToken(localStorage.getItem("currentTeamId"))
    ]).then(responses=>{
      setLoading(false);
    }).catch(err=>{
      notification.error({
        message: t("Error"),
        description: t(err.message)
      })
      history.goBack()
    })
  }, [config.team])

  useEffect(() => {
    setLoading(true);
    Promise.all([
      _getTeamInfomation(localStorage.getItem("currentTeamId")),
      _getUserAccessToken(localStorage.getItem("currentTeamId"))
    ]).then(responses=>{
      setLoading(false);
    }).catch(err=>{
      notification.error({
        message: t("Error"),
        description: t(err.message)
      })
      history.goBack()
    })
  }, [query])

  const _getTeamInfomation = async (id = localStorage.getItem("currentTeamId")) => {
    const response = await getTeamInfomation(id, query);
    if (response.status === 200) {
      const isCurrentManager = response.data.managerIds && response.data.managerIds.find(u => Number(u.id) === Number(actorId))
      setIsManager(isCurrentManager ? true : false)
      setTeam(response.data)
    } else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
      history.goBack()
    }
  }

  const _getUserAccessToken = async (id = localStorage.getItem("currentTeamId")) => {
    const response = await getUserAccessToken(id);
    if(response.status === 200) {
      setUserAccessToken(response.data)
    }
    else if(response.status === 204){
      setUserAccessToken("")
    }
    else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
      history.goBack()
    }
  }

  return (
    <BaseLayout hideMenu={false}>
      <Spin tip="Loading" size="large" spinning={loading}>
        <Row gutter={[30, 0]} className="home_page">
          <Col span={16}>
            <Row className="custom_row">
              <Row type="flex" justify="space-between" align="middle">
                <Typography.Title level={4}>
                  {team.name}
                </Typography.Title>
                {
                  isManager && <ModalInviteUser onReload={_getTeamInfomation} />
                }
              </Row>
              <Divider />
              {/*{team.isEnableAPI ? <WrappingTabs isWrap={isManager}>*/}
              {/*  <Row tab={t("Users")}>*/}
              {/*    <ListUsers*/}
              {/*      dataSource={team.users || []}*/}
              {/*      showInvite={setIsManager}*/}
              {/*      isManager={isManager}*/}
              {/*      actorId={decode(localStorage.getItem("accessToken")).id}*/}
              {/*      onReload={_getTeamInfomation}*/}
              {/*    />*/}
              {/*    <Row type="flex" justify="end" style={{padding: 5}}>*/}
              {/*      <Pagination size="small" showQuickJumper pageSize={Number(team.limit) || 20}*/}
              {/*                  current={Number(team.page) || 1} total={Number(team.totalUsers) | 0}*/}
              {/*                  onChange={_onChangePage}/>*/}
              {/*    </Row>*/}
              {/*  </Row>*/}
              {/*  <Row tab={t('Access Token')}>*/}
              {/*    {*/}
              {/*      isManager && team.isEnableAPI ?*/}
              {/*        <Fragment>*/}
              {/*          {*/}
              {/*            team.access_token_teams && team.access_token_teams.length > 0 ?*/}
              {/*              team.access_token_teams.map((accessToken, index) => (*/}
              {/*                <GenerateAccessToken accessToken={accessToken.prefix} key={index}/>*/}
              {/*              )) :*/}
              {/*              <GenerateAccessToken/>*/}
              {/*          }*/}
              {/*        </Fragment> :*/}
              {/*        null*/}
              {/*    }*/}
              {/*  </Row>*/}
              {/*  {(isManager)?<Row tab={t("Billing")}>*/}
              {/*    {*/}
              {/*      <BillingTab/>*/}
              {/*    }*/}
              {/*  </Row>:''}*/}
              {/*</WrappingTabs> : <WrappingTabs isWrap={isManager}>*/}
              {/*  <Row tab={t("Users")}>*/}
              {/*    <ListUsers*/}
              {/*      dataSource={team.users || []}*/}
              {/*      showInvite={setIsManager}*/}
              {/*      isManager={isManager}*/}
              {/*      actorId={decode(localStorage.getItem("accessToken")).id}*/}
              {/*      onReload={_getTeamInfomation}*/}
              {/*    />*/}
              {/*    <Row type="flex" justify="end" style={{padding: 5}}>*/}
              {/*      <Pagination size="small" showQuickJumper pageSize={Number(team.limit) || 20}*/}
              {/*                  current={Number(team.page) || 1} total={Number(team.totalUsers) | 0}*/}
              {/*                  onChange={_onChangePage}/>*/}
              {/*    </Row>*/}
              {/*  </Row>*/}
              {/*  {(isManager)?<Row tab={t("Billing")}>*/}
              {/*    {*/}
              {/*      <BillingTab/>*/}
              {/*    }*/}
              {/*  </Row>:''}*/}
              {/*</WrappingTabs>}*/}

              <WrappingTabs isWrap={true}>
                <Row tab={t("Users")}>
                  <ListUsers
                    dataSource={team.users || []}
                    showInvite={setIsManager}
                    isManager={isManager}
                    actorId={decode(localStorage.getItem("accessToken")).id}
                    onReload={_getTeamInfomation}
                  />
                  <Row type="flex" justify="end" style={{ padding: 5 }}>
                    <Pagination size="small" showQuickJumper pageSize={Number(team.limit) || 20}
                      current={Number(team.page) || 1} total={Number(team.totalUsers) | 0}
                      onChange={_onChangePage} />
                  </Row>
                </Row>
                <Row tab={t('Access Token')}>
                  {
                    team.isEnableAPI && isManager ?
                      <Fragment>
                        {
                          team.access_token_teams && team.access_token_teams.length > 0 ?
                            team.access_token_teams.map((accessToken, index) => (
                              <GenerateAccessToken accessToken={accessToken.prefix} key={index} type={TOKEN_TYPE.TEAM_TOKEN} />
                            )) :
                            <GenerateAccessToken />
                        }
                      </Fragment>
                    : ''
                  }
                  <Fragment>
                    <GenerateAccessToken accessToken={userAccessToken} type={TOKEN_TYPE.USER_TOKEN} />
                  </Fragment>
                </Row>

                {isManager ? <Row tab={t("Billing")}>
                  {
                    <BillingTab />
                  }
                </Row> : ''}
              </WrappingTabs>
            </Row>
          </Col>
          <Col span={8} className="timeline min_col">
            <Typography.Title level={4}>
              {t("Activities status")}
            </Typography.Title>
            <Divider />
            <Timelines dataSource={team.activities.slice(0, 20)} />
            <ModalActivities dataSource={team.activities} />
          </Col>
        </Row>
      </Spin>
    </BaseLayout>
  )
}

export default DetailTeam