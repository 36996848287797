import React, {useReducer, useEffect, useState} from 'react';
import {Row, Form, Select, Checkbox, Col, Input, Button, notification, Spin} from 'antd';
import {t} from 'utils/i18n';
import {CALENDAR_ITEM, SPECIAL_HOLIDAY_ITEM, BUSINESS_RHYTHM_ITEM, DETAIL_TABS} from 'consts';
import {useLocation} from 'react-router-dom';
import {timeSeries} from 'apis/datas';
import moment from 'moment';

const layout = {
  labelCol: {
    span: 4,
    align: "left"
  },
  wrapperCol: {
    span: 20
  },
  labelAlign: "left"
}

const initialState = {
  time_series_columns: [],
  only_include_business_day: []
}

export default (props) => {
  const {state: {data}} = useLocation();
  const [loading, setLoading] = useState(false);
  const [localState, setLocalState] = useReducer((_state, _newState) => {
    if (typeof _newState !== 'object') {
      return JSON.parse(JSON.stringify(initialState))
    }
    return {..._state, ..._newState}
  }, {
    ...JSON.parse(JSON.stringify(initialState)),
    save_as: data.data_name + "_" + t(DETAIL_TABS.TIME_SERIES) + "_" + moment().format("YYYY-MM-DD_HH:mm"),
    base_year: moment().get('year')
  })
  
  useEffect(() => {
    return () => {
      setLocalState()
      setLocalState({save_as: data.data_name + "_" + t(DETAIL_TABS.TIME_SERIES) + "_" + moment().format("YYYY-MM-DD_HH:mm")})
    }
  }, [props.activeKey])
  
  const _onChangeTimeData = (value) => {
    setLocalState({
      date_col: value,
      dateColMessage: ""
    })
  }
  
  const _onChangeCheckbox = (value, index = 0) => () => {
    const timeSeriesColumns = localState.time_series_columns;
    const only_include_business_day = localState.only_include_business_day;
    switch (index) {
      case 0:
        if (timeSeriesColumns.includes(value)) {
          timeSeriesColumns.splice(timeSeriesColumns.indexOf(value), 1);
        } else {
          timeSeriesColumns.push(value)
        }
        break
      case 1:
        if (only_include_business_day.includes(value)) {
          only_include_business_day.splice(only_include_business_day.indexOf(value), 1);
        } else {
          only_include_business_day.push(value)
        }
        break
      default:
        break;
    }
    setLocalState({
      time_series_columns: timeSeriesColumns,
      baseYearMessage: !timeSeriesColumns.includes("year") && "",
      only_include_business_day,
    })
  }
  
  const _onChangeBaseYear = ({target: {value}}) => {
    setLocalState({
      base_year: value,
      baseYearMessage: !("" + value).match(/^\d{4}$/g) && t("Invalid Data")
    })
  }
  
  const _onChangeSaveAs = ({target: {value}}) => {
    setLocalState({
      save_as: value,
      messageSaveAs: ""
    })
  }
  
  const _validate = (isSaveAs) => {
    const newState = {}
    if (!localState.date_col) {
      newState.dateColMessage = t("Please input %1", t("Time Data"))
    }
    if (isSaveAs && (!localState.save_as || localState.save_as.trim() === "")) {
      newState.messageSaveAs = t("Please input %1", t("Data Name"))
    }
    if (localState.time_series_columns.includes("year") && !("" + localState.base_year).match(/^\d{4}$/g)) {
      newState.baseYearMessage = t("Invalid Data")
    }
    if (Object.keys(newState).length > 0) {
      setLocalState(newState)
      return false
    }
    return true
  }
  
  const _onSave = (isSaveAs) => async () => {
    if (!_validate(isSaveAs)) {
      return
    }
    setLoading(true);
    setLocalState({
      [`loading${isSaveAs ? "SaveAs" : ""}`]: true
    })
    const body = {
      ...localState,
      data_id: data.data_id,
      project_id: data.project_id,
      lang: localStorage.getItem('lang')
    };
    if (!isSaveAs) {
      delete body.save_as
    }
    const response = await timeSeries(body);
    if (response.status === 200) {
      setLoading(false);
      notification.success({
        message: t("Success")
      })
    } else {
      setLoading(false);
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
    setLocalState({
      [`loading${isSaveAs ? "SaveAs" : ""}`]: false
    })
  }
  
  const _checkAll = (values, options) => () => {
    const timeSeriesColumns = localState.time_series_columns;
    
    values.map(value => {
      let _value = value instanceof Array ? value[0] : value
      if (!timeSeriesColumns.includes(_value)) {
        timeSeriesColumns.push(_value)
      }
    })
    
    if (options && options.isCalendarItem) {
      if (!timeSeriesColumns.includes("year")) {
        timeSeriesColumns.push("year")
      }
    }
    
    setLocalState({
      time_series_columns: timeSeriesColumns
    })
  }
  
  const _clearAll = (values, options) => () => {
    const timeSeriesColumns = localState.time_series_columns;
    const only_include_business_day = localState.only_include_business_day;
    
    values.map(value => {
      let _value = value instanceof Array ? value[0] : value
      if (timeSeriesColumns.includes(_value)) {
        timeSeriesColumns.splice(timeSeriesColumns.indexOf(_value), 1);
      }
      if (only_include_business_day.includes(_value)) {
        only_include_business_day.splice(only_include_business_day.indexOf(_value), 1);
      }
    })
    
    if (options && options.isCalendarItem) {
      if (timeSeriesColumns.includes("year")) {
        timeSeriesColumns.splice(timeSeriesColumns.indexOf("year"), 1);
        _onChangeBaseYear({target: {value: ""}})
      }
    }
    
    setLocalState({
      only_include_business_day,
      baseYearMessage: ""
    })
  }
  
  return (
    <Row>
      <Spin spinning={loading}>
        <Form {...layout}>
          <Form.Item label={<strong>{t("Time Data")}</strong>} help={localState.dateColMessage}>
            <div style={{width: 300}}>
              <Select
                showSearch
                onChange={_onChangeTimeData}
                value={localState.date_col}
              >
                {
                  props.dataTabView.columns instanceof Array && props.dataTabView.columns.map((data, index) => (
                    <Select.Option value={data} key={index}>{data}</Select.Option>
                  ))
                }
              </Select>
            </div>
          </Form.Item>
          <Form.Item label={<strong>{t("Calendar")}</strong>}>
            <div style={{maxHeight: 200, overflowY: "auto"}}>
              <Row>
                <Col span={12}>
                  <Checkbox onChange={_onChangeCheckbox("year")}
                            checked={localState.time_series_columns.includes("year")}>
                    {t("year")}
                  </Checkbox>
                </Col>
                <Col span={10}>
                  <Form.Item help={localState.baseYearMessage} style={{position: "absolute"}}>
                    <Row type='flex' justify='start-between'>
                      <span style={{marginRight: '5px'}}>{t('Base Year')}:</span>
                      <Input disabled={!localState.time_series_columns.includes('year')} value={localState.base_year}
                             onChange={_onChangeBaseYear} placeholder={t("Base Year")}/>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              {
                CALENDAR_ITEM.map((label, index) => (
                  <div key={index}>
                    <Checkbox onChange={_onChangeCheckbox(label)}
                              checked={localState.time_series_columns.includes(label)}>
                      {t(label)}
                    </Checkbox>
                  </div>
                ))
              }
            </div>
            <Row type="flex">
              <Button type="link" size="small" style={{padding: 0}}
                      onClick={_checkAll(CALENDAR_ITEM, {isCalendarItem: true})}>
                {t("Check all")}
              </Button>
              <span style={{paddingLeft: 10, paddingRight: 10, lineHeight: 1.3}}>|</span>
              <Button type="link" size="small" style={{padding: 0}}
                      onClick={_clearAll(CALENDAR_ITEM, {isCalendarItem: true})}>
                {t("Clear all")}
              </Button>
            </Row>
          </Form.Item>
          <Form.Item label={<strong>{t("Special Holiday")}</strong>}>
            {
              SPECIAL_HOLIDAY_ITEM.map((label, index) => (
                label instanceof Array ?
                  <Row key={index}>
                    {
                      label.map((text, labelIndex) => (
                        < Col span={Math.floor(24 / label.length)} key={labelIndex}
                              style={{marginLeft: labelIndex !== 0 ? -7 : 0}}>
                          <Checkbox
                            onChange={_onChangeCheckbox(label[0], labelIndex)}
                            disabled={labelIndex !== 0 && !localState.time_series_columns.includes(label[0])}
                            checked={labelIndex === 0 ?
                              localState.time_series_columns.includes(label[0]) :
                              localState.only_include_business_day.includes(label[0])
                            }
                          >
                            {t(text)}
                          </Checkbox> {/** @Fixme label[0] */}
                        </Col>
                      ))
                    }
                  </Row> :
                  <div key={index}>
                    <Checkbox onChange={_onChangeCheckbox(label)}
                              checked={localState.time_series_columns.includes(label)}>
                      {t(label)}
                    </Checkbox>
                  </div>
              ))
            }
            <Row type="flex">
              <Button type="link" size="small" style={{padding: 0}} onClick={_checkAll(SPECIAL_HOLIDAY_ITEM)}>
                {t("Check all")}
              </Button>
              <span style={{paddingLeft: 10, paddingRight: 10, lineHeight: 1.3}}>|</span>
              <Button type="link" size="small" style={{padding: 0}} onClick={_clearAll(SPECIAL_HOLIDAY_ITEM)}>
                {t("Clear all")}
              </Button>
            </Row>
          </Form.Item>
          <Form.Item label={<strong>{t("Business Rhythm")}</strong>}>
            <div style={{maxHeight: 200, overflowY: "auto"}}>
              {
                BUSINESS_RHYTHM_ITEM.map((label, index) => (
                  label instanceof Array ?
                    <Row key={index}>
                      {
                        label.map((text, labelIndex) => (
                          <Col span={Math.floor(24 / label.length)} key={labelIndex}>
                            <Checkbox
                              onChange={_onChangeCheckbox(label[0], labelIndex)}
                              disabled={labelIndex !== 0 && !localState.time_series_columns.includes(label[0])}
                              checked={labelIndex === 0 ?
                                localState.time_series_columns.includes(label[0]) :
                                localState.only_include_business_day.includes(label[0])
                              }
                            >
                              {t(text)}
                            </Checkbox> {/** @Fixme label[0] */}
                          </Col>
                        ))
                      }
                    </Row> :
                    <div key={index}>
                      <Checkbox onChange={_onChangeCheckbox(label)}
                                checked={localState.time_series_columns.includes(label)}>
                        {t(label)}
                      </Checkbox>
                    </div>
                ))
              }
            </div>
            <Row type="flex">
              <Button type="link" size="small" style={{padding: 0}} onClick={_checkAll(BUSINESS_RHYTHM_ITEM)}>
                {t("Check all")}
              </Button>
              <span style={{paddingLeft: 10, paddingRight: 10, lineHeight: 1.3}}>|</span>
              <Button type="link" size="small" style={{padding: 0}} onClick={_clearAll(BUSINESS_RHYTHM_ITEM)}>
                {t("Clear all")}
              </Button>
            </Row>
          </Form.Item>
          {/*<Row type="flex" justify="end">*/}
          {/*  <Form.Item help={localState.messageSaveAs} style={{marginTop: -3}}>*/}
          {/*  */}
          {/*  </Form.Item>*/}
          {/*  <span style={{padding: 5}}/>*/}
          {/*  <Button type="primary" onClick={_onSave(true)} loading={localState.loadingSaveAs}>*/}
          {/*    {t('Save As')}*/}
          {/*  </Button>*/}
          {/*  <span style={{padding: 5}} loading={localState.loading}/>*/}
          {/*  <Button type="primary" onClick={_onSave(false)}>*/}
          {/*    {t('Save')}*/}
          {/*  </Button>*/}
          {/*</Row>*/}
          
          <Row style={{textAlign: 'right', marginTop: '10px'}} type="flex" justify="end">
            <Row type='flex' justify='space-between' style={{width: "35%"}} align="center">
              <span style={{lineHeight: '31px', width: '27%', marginRight: 5}}>{t('Data name')}:</span>
              <Input style={{width: "70%"}} value={localState.save_as} onChange={_onChangeSaveAs}/>
            </Row>
            <Button style={{marginLeft: 5}} type="primary" onClick={_onSave(true)}
                    loading={localState.loadingSaveAs}>
              {t('Save As')}
            </Button>
            
            <Button type="primary" onClick={_onSave(false)} style={{marginLeft: 30}} loading={localState.loading}>
              {t('Save')}
            </Button>
          </Row>
        
        </Form>
      </Spin>
    </Row>
  )
}