import React, { useState, useEffect, useContext } from 'react';
import BaseLayout from 'components/BaseLayout';
import { getList, deleteTeam, enableAPI } from 'apis/teams';
import { Typography, Row, Table, Button, Icon, Modal, notification, Pagination } from 'antd';
import { t } from 'utils/i18n';
import ModalCreateTeam from './ModalCreateTeam';
import { ROLES } from 'consts';
import TitleTable from 'components/TitleTable';
import moment from 'moment';
import { decode } from 'utils';
import Configs from 'components/ConfigProviders';
import ModalListUsers from './ModalListUsers';
import EditTeams from './EditTeams';


export default (props) => {
  const [listTeams, setListTeam] = useState({});
  const config = useContext(Configs)
  const [query, setQuery] = useState({})

  useEffect(() => {
    getListTeam(query)
  }, [query])

  const _onChangePage = (page) => {
    setQuery({
      ...query,
      currentPage: page
    })
  }

  const getListTeam = async (query) => {
    const response = await getList(query);
    if (response.status === 200) {
      setListTeam(response.data)
    }
  }

  const _onDeleteTeam = (record) => () => {
    Modal.confirm({
      title: t("Are you sure to delete team: %1?", record.name),
      onOk: async () => {
        const response = await deleteTeam(record.id);
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          getListTeam()
        }
        else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
      },
      onCancel: () => { }
    })
  }

  const _onEnableAPI = (record) => () => {
    Modal.confirm({
      title: t("Are you sure to enable API for team: %1?", record.name),
      onOk: async () => {
        const response = await enableAPI(record.id);
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          getListTeam()
        }
        else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
      },
      onCancel: () => { }
    })
  }

  return (
    <BaseLayout hideMenu={false}>
      <Row type="flex" justify="space-between" align="middle" className="custom_row">
        <Typography.Title level={4}>
          {t("List teams")}
        </Typography.Title>
        <span>
          <ModalCreateTeam onReload={() => { getListTeam(); config.getListTeam() }} />
          {/* <span style={{ padding: 5 }} /> */}
          {/* <ModalCreateUser /> */}
        </span>
      </Row>
      <Table
        pagination={false}
        size="small"
        dataSource={listTeams.teams}
        bordered
        rowKey="id"
      >
        <Table.Column
          title={<TitleTable showSort={false}>{t("Team's name")}</TitleTable>}
          key="name"
          dataIndex="name"
        />
        <Table.Column
          title={<TitleTable showSort={false}>{t("Created at")}</TitleTable>}
          key="createdAt"
          dataIndex="createdAt"
          render={(text) => (
            <strong>{moment(text).format("YYYY-MM-DD(dd) HH:mm")}</strong>
          )}
        />
        {
          decode(localStorage.getItem("accessToken")).role === ROLES.ADMIN ?
            <Table.Column
              align="center"
              width="400px"
              render={(text, record) => (
                <Row type="flex" justify="end">
                  <Button type="primary" onClick={_onEnableAPI(record)} disabled={record.isEnableAPI} style={{ marginLeft: 10 }}>
                    {t(record.isEnableAPI ? "API Key Active" : "Active API")}
                  </Button>
                  <ModalListUsers team={record} />
                  <EditTeams team={record} onReload={getListTeam} />
                  <Button type="danger" onClick={_onDeleteTeam(record)} style={{ marginLeft: 10 }}>
                    <Icon type="delete" />
                  </Button>
                </Row>
              )}
            /> :
            null
        }
      </Table>
      <Row type="flex" justify="end" style={{ paddingTop: 5 }}>
        <Pagination size="small" showQuickJumper pageSize={20} total={Number(listTeams.totalRecord || 0)} current={Number(listTeams.currentPage || 1)} onChange={_onChangePage} />
      </Row>
    </BaseLayout>
  )
}