import React, {Fragment, useContext, useEffect, useState} from 'react';
import {List, Avatar, Row, Tag, Col, Modal, Button, Icon, notification, Spin} from 'antd';
import Configs from 'components/ConfigProviders';
import {NUMBERCONSTANT} from "consts"
import moment from 'moment';
import './style.less'
import {getListModels, getBillingData} from "apis/models"
import {t} from 'utils/i18n';
import {LeftOutlined, RightOutLined} from '@ant-design/icons';
import numeral from 'numeral';

const BillingTab = (props) => {
  const {team} = useContext(Configs)
  const [models, setModels] = useState(789)
  const [currentTime, setCurrentTime] = useState(moment().valueOf())
  const [totalTrainTime, setTotalTrainTime] = useState(moment().valueOf())
  const [loading, setLoading] = useState(false)
  const [disabledPrevious, setDisabledPrevious] = useState(false)
  const [disabledNext, setDisabledNext] = useState(true)
  
  
  useEffect(() => {
    if (localStorage.getItem("currentTeamId")) {
      getListModels()
        .then(response => {
          if (response.data) {
            setModels(response.data)
            setTotalTrainTime(response.data.totalTrainTime)
          }
        })
    }
  }, [team])
  const onClickNext = async () => {
    if (disabledPrevious) {
      setDisabledPrevious(false);
    }
    setCurrentTime(moment(currentTime).add(1, 'months').valueOf())
    setLoading(true);
    
    
    
    if (moment(currentTime).add(1, 'months').format('M') == moment().format('M') && moment(currentTime).format('YYYY') == moment().format('YYYY')) {
      setDisabledNext(true);
    }
    let response = await getBillingData({
      month: moment(currentTime).add(1, 'months').format('M'),
      year: moment(currentTime).add(1, 'months').format('YYYY')
    })
    if (response.status === 200) {
      setLoading(false);
      setTotalTrainTime(response.data.totalTrainTime)
    } else {
      setLoading(false);
    }
  }
  const onClickPrevious = async () => {
    if (disabledNext) {
      setDisabledNext(false)
    }
    setLoading(true);
    setCurrentTime(moment(currentTime).subtract(1, 'months').valueOf())
    if (moment(currentTime).subtract(1, 'months').format('M') == 5 && moment(currentTime).format('YYYY') == 2020) {
      setDisabledPrevious(true);
    }
    let response = await getBillingData({
      month: moment(currentTime).subtract(1, 'months').format('M'),
      year: moment(currentTime).subtract(1, 'months').format('YYYY')
    })
    
    if (response.status === 200) {
      setLoading(false);
      setTotalTrainTime(response.data.totalTrainTime)
    } else {
      setLoading(false);
    }
  }
  return (
    <div className="billing_tab">
      <Spin spinning={loading}>
        <Row type='flex' justify='start-between' className="time_mins">
          <div style={{
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: 600,
            fontSize: '20px',
            marginBottom: 10
          }}>{localStorage.getItem("lang") === 'en' ? moment(currentTime).format("MMM, YYYY") : `${moment(currentTime).format('YYYY')}年${moment(currentTime).format('M')}月`}</div>
        </Row>
        <Row type='flex' justify='start-between' className="cost">
          <Col span={12}>{t('Model Calculation Price (per minute)')}: </Col>
          <Col style={{textAlign: 'right'}} span={12}>{numeral(10).format('0,0')} <span
            style={{fontWeight: "bold"}}> {t('yen')}</span></Col>
        </Row>
        <Row type='flex' justify='start-between' className="time_mins" style={{marginTop: 20}}>
          <Col span={12}>{t('Model Calculation Time')}:</Col>
          <Col style={{textAlign: 'right'}} span={12}>{numeral(totalTrainTime).format('0,0')} <span
            style={{fontWeight: "bold"}}>{t('minutes')}</span></Col>
        </Row>
        <Row type='flex' justify='start-between' className="cost">
          <Col span={12}>{t('Model Calculation Cost')}: </Col>
          <Col style={{textAlign: 'right'}} span={12}>{numeral(totalTrainTime * 10).format('0,0')} <span
            style={{fontWeight: "bold"}}> {t('yen')}</span></Col>
        </Row>
        <Row type='flex' justify='start-between' className="time_mins" style={{marginTop: 20}}>
          <Col span={12}>{t('Reaming Free Time')}:</Col>
          <Col style={{textAlign: 'right'}}
               span={12}>{(NUMBERCONSTANT.FREEMINUTES - Number(totalTrainTime) < 0 ? 0 : numeral(NUMBERCONSTANT.FREEMINUTES - Number(totalTrainTime)).format('0,0'))}
            <span style={{fontWeight: "bold"}}> {t('minutes')}</span></Col>
        </Row>
        <Row type='flex' justify='start-between' className="cost">
          <Col span={12}>{t('Reaming Free Tier')}:</Col>
          <Col style={{textAlign: 'right'}}
               span={12}>{((NUMBERCONSTANT.FREEMINUTES - Number(totalTrainTime)) * 10) < 0 ? 0 : numeral((NUMBERCONSTANT.FREEMINUTES - Number(totalTrainTime)) * 10).format('0,0')}
            <span style={{fontWeight: "bold"}}> {t('yen')} </span></Col>
        </Row>
        <Row type='flex' justify='start-between' className="cost">
          <Col span={12}>{t('Machine Learning Surcharge')}:</Col>
          <Col style={{textAlign: 'right'}}
               span={12}>{Number(totalTrainTime) > NUMBERCONSTANT.FREEMINUTES ? numeral(10 * (Number(totalTrainTime) - NUMBERCONSTANT.FREEMINUTES)).format('0,0') : 0}
            <span style={{fontWeight: "bold"}}> {t('yen')}</span></Col>
        </Row>
        <Row type='flex' justify='start-between' className="cost">
          <Col span={12}></Col>
          <Col style={{textAlign: 'right'}}
               span={12}>
            <Button type="link" onClick={onClickPrevious} disabled={disabledPrevious}>
              <i style={{marginRight: 5}} className="fas fa-chevron-left"/> {t('Previous Month')}
            </Button>
            |
            <Button type="link" onClick={onClickNext} disabled={disabledNext}>
              {t('Next Month')} <i style={{marginLeft: 5}} className="fas fa-chevron-right"/>
            </Button>
          </Col>
        </Row>
      </Spin>
    </div>
  )
}

BillingTab.defaultProps = {
  dataSource: []
}

export default BillingTab