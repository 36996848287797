import React, { Fragment, useState } from 'react';
import { Button, Modal, Form, Input, notification } from 'antd';
import { t } from 'utils/i18n';
import { onChangeInput, onBlurInput, beforSubmit } from 'utils/Logics';
import { updateMyPassword } from 'apis/users'

export default (props) => {
  const [visible, setVisible] = useState(false)
  const [help, setHelp] = useState({})
  const [updatePassword, setUpdatePassword] = useState({})

  const onOpenModal = () => setVisible(true)

  const onHideModal = () => {
    setVisible(false)
    setUpdatePassword({})
  }

  const onOk = async () => {
    const body = beforSubmit({
      currentPassword: {
        value: updatePassword.currentPassword,
        setMessage: (m) => setHelp({ ...help, currentPassword: m }),
        title: "Current password",
        isValidateValue: false
      },
      newPassword: {
        value: updatePassword.newPassword,
        setMessage: (m) => setHelp({ ...help, newPassword: m }),
        title: "New password"
      },
      confirmPassword: {
        value: updatePassword.confirmPassword,
        setMessage: (m) => setHelp({ ...help, confirmPassword: m }),
        title: "Confirm password"
      },
    })
    if (help.newPassword === "" && help.confirmPassword === "" && body.newPassword !== body.confirmPassword) {
      setHelp({
        ...help,
        newPassword: t("Confirm password and current password not match")
      })
      return
    }
  
    if (!body) {
      return
    }
    const response = await updateMyPassword(body);
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      onHideModal()
      return;
    }
    notification.error({
      message: t("Error"),
      description: t(response.data.message)
    })
  }

  return (
    <Fragment>
      <Button
        type="link"
        className="button_link_custom"
        onClick={onOpenModal}
      >
        {t("Update password")}
      </Button>
      <Modal
        cancelText={t("Cancel")}
        title={t("Update password")}
        visible={visible}
        onCancel={onHideModal}
        onOk={onOk}
      >
        <Form>
          <Form.Item label={t("Current password")} required help={help.currentPassword}>
            <Input.Password
              value={updatePassword.currentPassword}
              onBlur={onBlurInput(updatePassword.currentPassword, (message) => setHelp({ ...help, currentPassword: message }), "Current password")}
              onChange={onChangeInput((value) => setUpdatePassword({ ...updatePassword, currentPassword: value }), (message) => setHelp({ ...help, currentPassword: message }))}
              maxLength={100}
            />
          </Form.Item>
          <Form.Item label={t("New password")} required help={help.newPassword}>
            <Input.Password
              value={updatePassword.newPassword}
              onBlur={onBlurInput(updatePassword.newPassword, (message) => setHelp({ ...help, newPassword: message }), "New Password")}
              onChange={onChangeInput((value) => setUpdatePassword({ ...updatePassword, newPassword: value }), (message) => setHelp({ ...help, newPassword: message }))}
              maxLength={100}
            />
          </Form.Item>
          <Form.Item label={t("Confirm password")} required help={help.confirmPassword}>
            <Input.Password
              value={updatePassword.confirmPassword}
              onBlur={onBlurInput(updatePassword.confirmPassword, (message) => setHelp({ ...help, confirmPassword: message }), "Confirm password")}
              maxLength={100}
              onChange={onChangeInput((value) => setUpdatePassword({ ...updatePassword, confirmPassword: value }), (message) => setHelp({ ...help, confirmPassword: message }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}