import {validate, limmitInput} from ".";
import _ from 'lodash';
import {t} from "./i18n";

/**
 *
 * @param {Function} setState
 * @param {Function} setMessage
 */
export const onChangeInput = (setState, setMessage) => event => {
  if (limmitInput(event.target.value)) {
    setState(event.target.value)
    if (setMessage) {
      setMessage("")
    }
  }
}

/**
 *
 * @param {Object} state
 * @param {Function} setMessage
 * @param {String} field
 * @param {Boolean} isValidate
 */
export const onBlurInput = (state, setMessage, field, isValidate = true) => () => {
  if (field === 'Project name' && (!state || (typeof state === 'string' && state.trim() === ""))) {
    setMessage(t('Please input the project name.'))
    return false
  }
  if (field === 'Model name' && (!state || (typeof state === 'string' && state.trim() === ""))) {
    setMessage(t('Please input the model name.'))
    return false
  }
  
  
  if (!state || (typeof state === 'string' && state.trim() === "")) {
    setMessage(t("Please input %1", t(field)))
    return false
  }
  
  if (isValidate && !validate(field, state)) {
    setMessage(t(`${field.toLowerCase().includes("password") ? "password" : field.toLowerCase().includes("email") ? "email" : field}_warning`))
    return false
  }
  return true
}

/**
 *
 * @param {Object} data
 * @param {String} data.value
 * @param {Function} data.setMessage
 * @param {Function} data.validate
 * @param {Function} setDisableButton
 */
export const beforSubmit = (data, setDisableButton) => {
  const _data = _.mapValues(data, object => {
    if (typeof object.value === 'string') {
      return object.value.trim()
    }
    return object.value
  });
  const fieldErrors = Object.keys(data).filter(field => {
    if (
      typeof data[field].setMessage === 'function' &&
      !onBlurInput(data[field].value, data[field].setMessage, data[field].title, data[field].isValidateValue)()
    ) {
      return field
    }
    if (
      typeof data[field].validate === 'function' &&
      typeof data[field].setMessage === 'function'
    ) {
      const message = data[field].validate(data[field].value)
      if (message === true) {
        return null
      }
      if (message === false) {
        data[field].setMessage(t("%1_validate", field))
      }
      if (typeof message === 'string') {
        data[field].setMessage(message)
      }
      return field
    }
    return null
  })
  
  if (fieldErrors.length > 0) {
    if (setDisableButton) {
      setDisableButton(true)
    }
    return false
  }
  
  return _data
}