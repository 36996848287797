import React, {Fragment, useState, useEffect} from 'react';
import {Button, Modal, Tabs, notification} from 'antd';
import {t} from 'utils/i18n';
import {beforSubmit} from 'utils/Logics';
import {checkValidate} from 'utils';
import {searchDatas} from 'apis/datas';
import {clusterModel, trainModel, svmModel} from 'apis/models';
import {getListProjects, searchProjects} from 'apis/projects';
import RegessionTab from "./RegessionTab";
import ClusteringTab from "./ClusteringTab";
import OneClassTab from "./OneClassTab";

const {TabPane} = Tabs;

const ModalRegisterModel = (props) => {
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [models, setModels] = useState({data_ids: []})
  const [message, setMessage] = useState({})
  const [datas, setDatas] = useState([])
  const [project, setProject] = useState(null)
  const [projects, setProjects] = useState([])
  const [label, setLabel] = useState("training_data")
  const [cluster, setCluster] = useState()
  const [nu, setNu] = useState(0.2)
  const [activeKey, setActiveKey] = useState("regession")
  const [csv, setCsv] = useState("")
  const [numOfScroll, setNumOfScroll] = useState(1)
  const [loading, setLoading] = useState(false)
  const [isTypingModelName, setIsTypingModelName] = useState(false)
  const [isTypingReportName, setIsTypingReportName] = useState(false)
  useEffect(() => {
    if (props.data) {
      setVisible(true)
      setModels({
        ...models,
        data_ids: [props.data.data_id],
        name: `${props.data.data_name}${t('_model')}`,
        report_name: `${props.data.data_name}${t('_result')}`
      })
      setProject(props.data.project_id)
    }
  }, [props.data])
  useEffect(() => {
    setCluster(0)
    setLabel('training_data')
    setMessage({})
  }, [activeKey])
  useEffect(() => {
    if (visible) {
      _onSearch()
      if (props.data) {
        _getListProjects([
          {
            name: props.data.project_name,
            project_id: props.data.project_id
          }
        ])
        setProject(props.data && props.data.project_id)
      } else {
        _getListProjects()
      }
      
    }
  }, [visible, props.data])
  
  useEffect(() => {
    _onSearch()
  }, [label, project])
  useEffect(() => {
    if (numOfScroll > 1) {
      _getListProjectsPage(numOfScroll)
    }
  }, [numOfScroll])
  
  const _onOpenModal = () => setVisible(true)
  const _onHideModal = () => {
    setIsTypingModelName(false)
    setIsTypingReportName(false)
    setVisible(false)
    setModels({data_ids: []})
    setMessage({})
    setCluster(undefined)
    setLabel('training_data')
    setProject(null)
  }
  const _setMessage = (field) => (m) => setMessage({...message, [field]: m})
  const _setFieldModels = (field) => (value) => {
    if (field === 'name') {
      setIsTypingModelName(true)
    }
    if (field === 'report_name') {
      setIsTypingReportName(true)
    }
    setModels({...models, [field]: value})
  }
  const _onSearch = async (value) => {
    if (value === undefined || value.trim() !== "") {
      const response = await searchDatas({name: value, project_id: project, label});
      if (response.status === 200) {
        const _datas = response.data;
        if (props.data && !_datas.map(d => d.data_id).includes(props.data.data_id)) {
          _datas.push({...props.data, name: props.data.data_name})
        }
        setDatas(_datas)
      } else notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }
  const _getListProjects = async (data) => {
    const response = await getListProjects({
      filter: 'model'
    });
    if (response.status === 200 && data) {
      
      if (checkValidate(response.data.data, data[0])) {
        
        setProjects(response.data.data)
      } else {
        setProjects(response.data.data.concat(data))
      }
      
      if (!project) {
        setProject(response.data[0] && response.data[0].project_id)
      }
    } else if (response.status === 200) {
      setProjects(response.data.data)
    }
  }
  const _getListProjectsPage = async (page) => {
    
    setLoading(true)
    const response = await getListProjects({
      filter: 'model',
      page
    });
    if (response.status === 200) {
      setLoading(false)
      setProjects([...projects, ...response.data.data].filter((record) => record.created_at))
    } else {
      setLoading(false)
    }
  }
  const _onSearchProject = async (value) => {
    if (value === undefined || value.trim() !== "") {
      const response = await searchProjects({name: value});
      if (response.status === 200) {
        setProjects(response.data)
      } else notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }
  const handleScroll = (e) => {
    
    const isEndOfList = e.target.scrollTop + e.target.clientHeight;
    
    if (isEndOfList === e.target.scrollHeight) {
      
      setNumOfScroll(numOfScroll + 1)
    }
  }
  const _onChangeDataName = (value) => {
    let dataName = datas.filter(record => record.data_id === value)
    const dataAutoFillModelName = !isTypingModelName ? {name: `${dataName[0].name}${t('_model')}`} : {}
    const dataAutoFillReportName = !isTypingReportName ? {report_name: `${dataName[0].name}${t('_result')}`} : {}
    if (dataName.length > 0 && dataName[0].name) {
      setModels({...models, ...dataAutoFillModelName, ...dataAutoFillReportName, data_ids: [value]})
    }
  }
  const onChangeTab = (key) => {
    setActiveKey(key)
  }
  
  const _handleOk = async () => {
    var body;
    if (activeKey === 'cluster') {
      body = beforSubmit({
        data_ids: {
          value: models.data_ids,
          setMessage: _setMessage("data_ids"),
          validate: (data) => data.length > 0,
          title: "Training data"
        },
        name: {
          value: models.name,
          setMessage: _setMessage("name"),
          title: "Model name"
        },
        report_name: {
          value: models.report_name,
          setMessage: _setMessage("Report Name"),
          title: "Report Name"
        }
      })
    } else if (activeKey === "oneClass") {
      body = beforSubmit({
        data_ids: {
          value: models.data_ids,
          setMessage: _setMessage("data_ids"),
          validate: (data) => data.length > 0,
          title: "Training data"
        },
        name: {
          value: models.name,
          setMessage: _setMessage("name"),
          title: "Model name"
        }
      })
      
    } else {
      body = beforSubmit({
        data_ids: {
          value: models.data_ids,
          setMessage: _setMessage("data_ids"),
          validate: (value) => {
            const data = datas.filter(d => Number(d.data_id) === Number(value)).pop();
            let _message = "";
            if (data && data.label_features && data.label_features.toLowerCase() !== 'target') {
              _message = t("There is no target column.")
            }
            if (_message !== "") {
              return _message
            }
            return true;
          },
          title: "Training data"
        },
        name: {
          value: models.name,
          setMessage: _setMessage("name"),
          title: "Model name"
        }
      })
    }
    if (!body) {
      return false
    }
    setConfirmLoading(true)
    body.project_id = datas.filter(d => body.data_ids.includes(d.data_id)).pop().project_id;
    if (activeKey === "cluster") {
      body.cluster_count = cluster;
      body.result_only = false;
      body.data_id = models.data_ids[0];
      
    } else if (activeKey === "regession") {
      body.label_features = datas.filter(d => body.data_ids.includes(d.data_id)).pop().label_features;
    } else {
      body.nu = nu
      body.data_id = models.data_ids[0];
    }
    if (activeKey === "cluster") {
      let response = await clusterModel(body);
      if (response.status === 200) {
        setConfirmLoading(false)
        setVisible(false)
        setModels({data_ids: []})
        props.setReloadData(props.reloadData + 1)
        notification.success({
          message: t("Success")
        })
      } else {
        setConfirmLoading(false)
        notification.error({
          message: t("Error"),
          description: t(response.data.message)
        })
      }
      
      
    } else if (activeKey === "regession") {
      let response = await trainModel(body);
      
      
      if (response.status === 200) {
        setConfirmLoading(false)
        setVisible(false)
        setModels({data_ids: []})
        props.setReloadData(props.reloadData + 1)
        notification.success({
          message: t("Success")
        })
      } else {
        setConfirmLoading(false)
        notification.error({
          message: t("Error"),
          description: t(response.data.message)
        })
      }
    } else {
      let response = await svmModel(body);
      if (response.status === 200) {
        props.setReloadData(props.reloadData + 1)
        setModels({data_ids: []})
        setConfirmLoading(false)
        notification.success({
          message: t("Success")
        })
        setVisible(false)
      } else {
        setConfirmLoading(false)
        notification.error({
          message: t("Error"),
          description: t(response.data.message)
        })
      }
    }
  }
  return (
    <Fragment>
      <Button
        type="primary"
        onClick={_onOpenModal}
        disabled={localStorage.getItem("currentTeamId") ? false : true}
      >
        {t("Create model")}
      </Button>
      <Modal
        style={{width: "100%"}}
        title={t("Create Model")}
        visible={visible}
        onCancel={_onHideModal}
        cancelText={t("Cancel")}
        onOk={_handleOk}
        confirmLoading={confirmLoading}>
        <Tabs defaultActiveKey="regession" onChange={onChangeTab}>
          <TabPane tab={t("Regression / Classification")} key="regession">
            <RegessionTab
              projects={projects}
              data={props.data}
              datas={datas}
              project={project}
              label={label}
              _onSearch={_onSearch}
              _onSearchProject={_onSearchProject}
              setLabel={setLabel}
              setProject={setProject}
              models={models}
              message={message}
              setModels={setModels}
              setMessage={setMessage}
              _setFieldModels={_setFieldModels}
              _setMessage={_setMessage}
              _onChangeDataName={_onChangeDataName}
              handleScroll={handleScroll}
              loading={loading}
            />
          
          </TabPane>
          <TabPane tab={t("Clustering")} key="cluster">
            <ClusteringTab
              projects={projects}
              data={props.data}
              datas={datas}
              project={project}
              label={label}
              _onSearch={_onSearch}
              _onSearchProject={_onSearchProject}
              setLabel={setLabel}
              setProject={setProject}
              models={models}
              message={message}
              setModels={setModels}
              setMessage={setMessage}
              _setFieldModels={_setFieldModels}
              _setMessage={_setMessage}
              _onChangeDataName={_onChangeDataName}
              setCluster={setCluster}
              handleScroll={handleScroll}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("One Class SVM")} key="oneClass">
            <OneClassTab
              projects={projects}
              data={props.data}
              datas={datas}
              project={project}
              label={label}
              _onSearch={_onSearch}
              _onSearchProject={_onSearchProject}
              setLabel={setLabel}
              setProject={setProject}
              models={models}
              message={message}
              setModels={setModels}
              setMessage={setMessage}
              _setFieldModels={_setFieldModels}
              _setMessage={_setMessage}
              _onChangeDataName={_onChangeDataName}
              setNu={setNu}
              handleScroll={handleScroll}
              loading={loading}
            />
          </TabPane>
        
        </Tabs>
      
      
      </Modal>
    </Fragment>
  )
}

export default ModalRegisterModel