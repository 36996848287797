import axios from 'axios'
import { stringify } from 'querystring'
import { decode, debound } from './'
import { notification } from 'antd'
import { t } from './i18n'

const deboundNotification = debound((message) => {
  notification.warn({
    message: t(message || "Session has exprised. Please login again")
  })
}, 0)

const deboundReload = debound(() => {
  window.location.reload()
}, 1000)

export const getNewToken = () => {
  if (decode(localStorage.getItem("refresToken")).exp < Math.floor(Date.now() / 1000)) {
    throw new Error("Token exprised!")
  }
  try {
    return axios({
      url: process.env[`REACT_APP_BACKEND_${(process.env.NODE_ENV !== 'production' ? "DEV" : "PROD")}`] + '/auth/login',
      headers: {
        'Content-type': 'application/json'
      },
      method: "POST",
      data: {
        refreshToken: localStorage.getItem("refreshToken")
      }
    })
  } catch (error) {
    throw new Error("Token exprised!")
  }
}

export const FetchAPI = async (path, method, headers, body) => {
  if (!headers || !headers.Authorization) {
    const payloadAccessToken = decode(localStorage.getItem("accessToken"))
    if (payloadAccessToken && payloadAccessToken.exp < Math.floor(Date.now() / 1000) + 5 * 60) {
      try {
        const response = await getNewToken();
        if (response.status === 200) {
          localStorage.setItem('accessToken', response.data.accessToken)
        }
      } catch (error) {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("fullname")
        localStorage.removeItem("currentTeamId")

        deboundNotification();
        deboundReload()

        return {
          status: 401,
          data: {
            message: ""
          }
        }
      }
    }
  }
  const defaultHeaders = {
    'Content-type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
  }
  if (typeof headers === 'object') {
    Object.assign(defaultHeaders, headers)
  }
  try {
    return await axios({
      url: process.env[`REACT_APP_BACKEND_${(process.env.NODE_ENV !== 'production' ? "DEV" : "PROD")}`] + path,
      method,
      headers: defaultHeaders,
      data: body
    })
  } catch (error) {
    if (error.response && error.response.status !== 401) {
      return error.response
    }
    if (!error.response) {
      deboundNotification("Server Error")
    }
    else {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("fullname")
      localStorage.removeItem("currentTeamId")
      deboundNotification();
      deboundReload()
    }
    return {
      status: 401,
      data: {
        message: ""
      }
    }
  }
}

export const get = (path, query = {}, headers = {}) => {
  return FetchAPI(`${path}?${stringify(query)}`, "GET", headers)
}
export const post = (path, body, headers) => FetchAPI(path, "POST", headers, body)
export const patch = (path, body, headers) => FetchAPI(path, "PATCH", headers, body)
export const destroy = (path, body, headers) => FetchAPI(path, "DELETE", headers, body)