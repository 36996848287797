import React, {useEffect, useState, Fragment, useContext} from 'react';
import {
  Checkbox,
  Table,
  InputNumber,
  Tag,
  Select,
  Button,
  notification,
  Icon,
  Row,
  Col,
  Pagination,
  Modal,
  Tooltip
} from 'antd';
import {checkColor, convertDataCorrelation} from 'utils';
// import moment from 'moment';
// import TitleTable from 'components/TitleTable';
// import SelectLabel from 'components/SelectLabel';
// import {getListDatas, deleteData, updateData} from 'apis/datas';
// import {ROUTES, LABELS} from 'consts';
// import {accessDownload} from 'apis/auth';
// import Configs from 'components/ConfigProviders';
// import {useHistory} from 'react-router-dom';
// import SpanEditable from 'components/SpanEditable';
import Plot from 'react-plotly.js';
// const {Column, ColumnGroup} = Table;

/**
 *
 * @param {Object} props
 * @param {String} props.label
 */

const TableCorrelation = (props) => {
  const [isTargetColumn,setIsTargetColumn] = useState(false);
  useEffect(()=>{
    if(props.dataTable.columns){
      let arrTarget = ['Target','target', 'TARGET'];
  
      // arrTarget.map((value,index)=>{
      //   if(props.dataTable.columns.includes(value)){
      //     setIsTargetColumn(true)
      //     return;
      //   }
      // })
      //
      for (let i = 0; i < arrTarget.length; i++) {
        if(props.dataTable.columns.includes(arrTarget[i])){
          setIsTargetColumn(true)
          break;
        }else {
          if(i===2) setIsTargetColumn(false)
        }
      }
      
      
    }
  },[props.dataTable.columns])
  let columnTable = [{
    title: "",
    dataIndex: "column",
  }].concat(props.dataTable.columns.map((data, index) => {
    return {
      title: data,
      dataIndex: data,
      render: text => {
        
        if (typeof (text) === 'object' && text[0] === true) {
          return {
            props: {
              style: {background: checkColor(text[1], text[0])},
            },
            children: <div className="object"
                           style={{padding: '10px', backgroundColor: `${checkColor(text[1], text[0])}`}}>{text[1]}</div>
            
            
          }
        } else {
          let isTarget = false;
          if (data && data.toLowerCase() === 'target') {
            isTarget = true
          }
          return {
            props: {
              style: {background: checkColor(text, isTarget)},
            },
            children: <div className="none"
                           style={{padding: '10px', backgroundColor: `${checkColor(text, isTarget)}`}}>{text}</div>
          }
        }
        
        
      }
    }
  }))
  
  let dataTable = convertDataCorrelation(props.dataTable.columns, props.dataTable.data.data).map((value, index) => {
    let newRow = {};
    newRow['column'] = value[0]
    if (index === 0 && value[0].toLowerCase()==='target') {
      for (let i = 1; i < value.length; i++) {
        newRow[props.dataTable.columns[i - 1]] = [true, value[i]]
      }
    } else {
      for (let i = 1; i < value.length; i++) {
        newRow[props.dataTable.columns[i - 1]] = value[i]
      }
    }
    return newRow
  })
  
  return (
    <div>
      <Table className={isTargetColumn?'ant-table-wrapper-custom':''} style={{overflowX: 'scroll'}} dataSource={dataTable} pagination={false} columns={columnTable}/>
    </div>
  )
}

export default TableCorrelation
