import React, { useState } from 'react'
import { Icon } from 'antd'

import './style.less'

/**
 * @param {Object} props
 * @param {Function} props.onSort
 */
export default (props) => {
  const [order, setOrder] = useState(null);

  const _onClick = () => {
    const newOrder = order === "DESC" ? "ASC" : "DESC"
    setOrder(newOrder)
    if (props.onSort) {
      props.onSort(newOrder)
    }
  }

  const _onBlur = () => {
    setOrder(null)
  }

  return (
    <button
      onClick={_onClick}
      onBlur={_onBlur}
      className={`_wrapper_sort_button ${props.className || ""}`}
    >
      <span className="_sort_button">
        <Icon type="caret-up" className="up" style={{ color: order === "ASC" ? "#0082ff" : "gray" }} />
        <Icon type="caret-down" className="down" style={{ color: order === "DESC" ? "#0082ff" : "gray" }} />
      </span>
    </button>
  )
}