import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { LABELS } from 'consts';
import { t } from 'utils/i18n';

/**
 * 
 * @param {Object} props 
 * @param {String} props.value 
 * @param {Function} props.onChange 
 */
const SelectLabel = (props) => {
  const [label, setLabel] = useState(props.value)
  useEffect(() => {
   setLabel(props.value)
  }, [props.value])
  const _onChangeLabel = (value) => {
    setLabel(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }
  return (
    <Select
      value={label}
      onChange={_onChangeLabel}
      className="fullsize_selectbox"
      disabled={props.disabled}
    >
      {
        
        Object.values(LABELS).map((label, index) => (
          <Select.Option value={label} key={label}>
            {t(label)}

          </Select.Option>
        ))
      }
    </Select>
  )
}

export default SelectLabel