import React, { useState, useEffect, useContext } from 'react';
import BaseLayout from 'components/BaseLayout';
import { Typography, Row, Tabs, Select, Input } from 'antd';
import { t } from 'utils/i18n';
import TabDataList from './TabDataList';
import ModalRegisterData from './ModalRegisterData';
import { LABELS } from 'consts';
import { getListProjects } from 'apis/projects';
import { useHistory } from 'react-router-dom';
import Configs from 'components/ConfigProviders';
import Project from 'components/Filter/Project';

export default () => {
  const [activeKey, setActiveKey] = useState("data_list");
  const [projects, setProjects] = useState([]);
  const history = useHistory();
  const { team } = useContext(Configs)
  const [projectId, setProjectId] = useState(null)

  useEffect(() => {
    _getListProjects({
      sort_by: 'updated_at',
      order: 'DESC'
    })
  }, [team])

  const _getListProjects = async () => {
    const response = await getListProjects({
      filter: 'data'
    });
    if (response.status === 200) {
      setProjects(response.data.data)
    }
  }

  const onChangeActiveKey = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  return (
    <BaseLayout hideMenu={false}>
      <Row type="flex" justify="space-between">
        <Typography.Title level={4}>
          {t("Data Management")}
        </Typography.Title>

        <span>
          <span style={{ padding: 5 }} />
          <ModalRegisterData projects={projects} reloadList={() => {
            setActiveKey("")
            onChangeActiveKey(activeKey)
          }} />
        </span>
      </Row>
      <Project
        projectId={projectId}
        setProjectId={setProjectId}
      />
      <Row>
        <Tabs activeKey={activeKey} onChange={onChangeActiveKey} >
          <Tabs.TabPane tab={t("data_list")} key={"data_list"} >
            <TabDataList
              activeKey={activeKey}
              projects={projects}
              onReloadProjects={_getListProjects}
              history={history}
              projectId={projectId}
            />
          </Tabs.TabPane>
          {
            Object.values(LABELS).map((label) => (
              <Tabs.TabPane tab={t(label)} key={label} >
                <TabDataList
                  label={label}
                  projectId={projectId}
                  activeKey={activeKey}
                  projects={projects}
                  onReloadProjects={_getListProjects}
                  history={history}
                />
              </Tabs.TabPane>
            ))
          }
        </Tabs>
      </Row>
    </BaseLayout>
  )
}