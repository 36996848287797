import React from 'react';
import { checkRole } from 'utils';

const Roles = ({ role, children, protect, ...rest }) => {
  return (
    checkRole(localStorage.getItem('accessToken'), role && typeof role === 'string' ? { role } : protect) ?
      React.cloneElement(children, { ...rest }) :
      null
  )
}

export default Roles