import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Input, Row, Icon, Button, notification, Col } from 'antd';
import { t } from 'utils/i18n';
import { generateTeamToken, generateUserTeamToken } from 'apis/teams';
import { TOKEN_TYPE } from 'consts';
import Text from 'antd/lib/typography/Text';
import { Tooltip } from 'chart.js';
import { InfoCircleOutlined } from '@ant-design/icons';

export default (props) => {
  const [visible, setVisible] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(false)
  const [secondsConfirm, setSecondsConfirm] = useState(5);

  useEffect(() => {
    if (visible && secondsConfirm > 0) {
      setTimeout(() => {
        setSecondsConfirm(secondsConfirm - 1)
      }, 1000);
    }
    if (visible === false) {
      setTimeout(() => {
        setSecondsConfirm(5)
      }, 100);
    }
  }, [visible, secondsConfirm])

  useEffect(() => {
    if (props.accessToken) {
      setAccessToken(props.accessToken + Array.from(new Array(32).keys()).map(x => "*").join(""))
    }
    else setAccessToken(t("No access token"))
  }, [props.accessToken])

  const _onGenerateNewToken = () => {
    if (props.accessToken !== "") {
      return setVisible(true);
    }
    _onOk()

  }

  const _onHideModal = () => {
    setVisible(false)
  }

  const _onOk = async () => {
    setLoading(true)
    switch(props.type){
      case TOKEN_TYPE.TEAM_TOKEN: {
        const response = await generateTeamToken(localStorage.getItem("currentTeamId"));
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          setAccessToken(response.data.accessToken);
        }
        else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
        setLoading(false)
        _onHideModal()
        break;
      }
      case TOKEN_TYPE.USER_TOKEN: {
        const response = await generateUserTeamToken(localStorage.getItem("currentTeamId"));
        if (response.status === 200) {
          notification.success({
            message: t("Success")
          })
          setAccessToken(response.data);
        }
        else {
          notification.error({
            message: t("Error"),
            description: t(response.data.message)
          })
        }
        setLoading(false)
        _onHideModal()
        break;
      }
      default:
    }
  }

  return (
    <Fragment>
      <Row type="flex" justify="space-between" align="middle" style={{marginTop: "1rem"}}>
        <Text>
          {props.type === TOKEN_TYPE.TEAM_TOKEN ?
            t("Team access token:")
          : props.type === TOKEN_TYPE.USER_TOKEN ? t("User access token:") : t("Unknow type access token:")
          }
        </Text>
      </Row>
      <Row type="flex" justify="space-between" align="middle" style={{marginTop: "1rem"}}>
        <Input
          id="_copy_accessToken_clipboard"
          disabled
          value={accessToken}
          style={{ flex: 1, cursor: "text" }}
        />
        <span style={{ padding: 5 }} />
        <Button loading={accessToken === "" && loading} type="primary" onClick={_onGenerateNewToken}>
          {t("Generate New Token")}
        </Button>
      </Row>
      <Modal
        cancelText={t("Cancel")}
        visible={visible}
        footer={null}
        closable={null}
      >
        <Row type="flex" justify="start">
          <Icon type="question-circle" theme="twoTone" twoToneColor="#FAB11F" style={{ fontSize: 20, paddingRight: 10 }} />
          <div style={{ flex: 1, color: "rgba(0,0,0,.85)", fontWeight: 600, fontSize: 16, lineHeight: 1.4 }}>
            {t("The old token will be revoke. Are you sure to generate new access token?")}
          </div>
        </Row>
        <Row type="flex" justify="end" align="middle">
          <Button onClick={_onHideModal}>{t("Cancel")}</Button>
          <span style={{ padding: 3 }} />
          <Button loading={loading} onClick={_onOk} disabled={secondsConfirm > 0} type="primary">{t("OK")}{secondsConfirm > 0 ? ` (${secondsConfirm})` : ""}</Button>
        </Row>
      </Modal>
    </Fragment>
  )
}