import React, { useState } from 'react';
import BaseLayout from 'components/BaseLayout';
import { Row, Typography } from 'antd';
import { t } from 'utils/i18n';
import ModalRegisterModel from './ModalRegisterModel';
import TableModel from './TableModel';
import { useLocation } from 'react-router-dom';
import Project from 'components/Filter/Project';

export default (props) => {
  const location = useLocation();
  const [reloadData, setReloadData] = useState(0)
  const [projectId, setProjectId] = useState(null)

  return (
    <BaseLayout hideMenu={false}>
      <Row type="flex" justify="space-between" align="middle" className="custom_row">
        <Typography.Title level={4}>
          {t("Model Management")}
        </Typography.Title>
        <ModalRegisterModel setReloadData={setReloadData} reloadData={reloadData} data={location.state && location.state.state === "CREATE_MODEL" && location.state.data} />
      </Row>
      <Project
        projectId={projectId}
        setProjectId={setProjectId}
      />
      <Row style={{ marginBottom: 16 }} />
      <TableModel
        data_id={location.state && location.state.data_id}
        model_id={location.state && location.state.model_id}
        projectId={projectId}
        reloadData={reloadData}
        setReloadData={setReloadData} />
    </BaseLayout>
  )
}