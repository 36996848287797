import React, {Fragment, useEffect, useState} from 'react';
import {Button, Modal, Form, Input, notification} from 'antd';
import {t} from 'utils/i18n';
import {onChangeInput, onBlurInput, beforSubmit} from 'utils/Logics';
import {inviteUser} from 'apis/teams';

const handleModalOk = (data, setVisible) => {

}

const onHideModal = (setVisible) => () => setVisible(false)

const onOpenModal = setVisible => () => setVisible(true)

export default (props) => {
  const [visible, setVisible] = useState(false)
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false)
  useEffect(() => {
    if (!visible) {
      setEmail('')
      setMessage('')
    }
  }, [visible])
  const onOk = async () => {
    const body = beforSubmit({
      email: {
        value: email,
        setMessage,
        title: "Email"
      }
    })
    if (!body) {
      return
    }
    setConfirmLoading(true)
    const response = await inviteUser(localStorage.getItem("currentTeamId"), body)
    setConfirmLoading(false)
    if (response.status === 200) {
      notification.success({
        message: t("Success")
      })
      if (props.onReload) {
        props.onReload()
      }
      setEmail("")
      setVisible(false)
    } else {
      notification.error({
        message: t("Error"),
        description: t(response.data.message)
      })
    }
  }
  
  return (
    <Fragment>
      <Button
        type="primary"
        onClick={onOpenModal(setVisible)}
      >
        {t("Invite user")}
      </Button>
      <Modal
        cancelText={t("Cancel")}
        title={t("Invite user")}
        visible={visible}
        onCancel={onHideModal(setVisible)}
        onOk={onOk}
        confirmLoading={confirmLoading}
        okText={t("Invite")}
      >
        <Form>
          <Form.Item required label={t("Email")} help={message}>
            <Input
              value={email}
              onChange={onChangeInput(setEmail, setMessage)}
              onBlur={onBlurInput(email, setMessage, "Email")}
              maxLength={100}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}